@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;600;700;800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800&amp;display=swap");
@import url("./assests/css/meanmenu.min.css");
@import url("./assests/css/animate.css");
@import url("./assests/css/all.css");
@import url("./assests/css/magnific-popup.css");
@import url("./assests/css/bootstrap.min.css");
@import url("./assests/css/swiper-bundle.min.css");
@import url("./assests/font/flaticon.css");
/*==========================================================================
* Common CSS
==========================================================================*/
#pageReplica .replica {
    font-family: var(--body-font);
    color: var(--body-color);
    font-size: 16px;
    line-height: 28.8px;
    font-weight: 400;
}

.replica img {
    max-width: 100%;
    height: auto;
    transition: 0.4s;
}

.replica a {
    outline: none;
    color: inherit;
    text-decoration: none;
}

.pageReplica a,
.pageReplica button,
.pageReplica i {
    text-decoration: none;
    color: inherit;
}

.pageReplica a:focus,
.pageReplica a:hover {
    text-decoration: none;
    color: inherit;
}

.section-padding {
    padding: 120px 0px;
}

@media (max-width: 575px) {
    .section-padding {
        padding: 85px 0px;
    }
}
.display-none {
    display: none;
}

.display-block {
    display: block;
}

.img__full {
    width: 100%;
}

/*==========================================================================
* Form CSS
==========================================================================*/
.pageReplica button,
.pageReplica input[type="button"],
.pageReplica input[type="reset"],
.pageReplica input[type="submit"] {
    border: 1px solid var(--border-color-2);
    border-color: transparent;
    border-radius: 6px;
    background: var(--primary-color-1);
    color: var(--text-white);
    padding: 17px 40px;
}

.pageReplica button:hover,
.pageReplica input[type="button"]:hover,
.pageReplica input[type="reset"]:hover,
.pageReplica input[type="submit"]:hover {
    border-color: transparent;
}

.pageReplica button:active,
.pageReplica button:focus,
.pageReplica input[type="button"]:active,
.pageReplica input[type="button"]:focus,
.pageReplica input[type="reset"]:active,
.pageReplica input[type="reset"]:focus,
.pageReplica input[type="submit"]:active,
.pageReplica input[type="submit"]:focus {
    border-color: transparent;
}

.pageReplica input[type="text"],
.pageReplica input[type="file"],
.pageReplica input[type="email"],
.pageReplica input[type="url"],
.pageReplica input[type="password"],
.pageReplica input[type="search"],
.pageReplica input[type="number"],
.pageReplica input[type="tel"],
.pageReplica input[type="range"],
.pageReplica input[type="date"],
.pageReplica input[type="month"],
.pageReplica input[type="week"],
.pageReplica input[type="time"],
.pageReplica input[type="datetime"],
.pageReplica input[type="datetime-local"],
.pageReplica input[type="color"],
.pageReplica textarea {
    color: var(--body-color);
    border-radius: 6px;
    width: 100%;
    height: 60px;
    border: 1px solid var(--border-color-2);
    padding: 0 15px;
    background: var(--bg-white);
}

.pageReplica input[type="text"]:focus,
.pageReplica input[type="file"]:focus,
.pageReplica input[type="email"]:focus,
.pageReplica input[type="url"]:focus,
.pageReplica input[type="password"]:focus,
.pageReplica input[type="search"]:focus,
.pageReplica input[type="number"]:focus,
.pageReplica input[type="tel"]:focus,
.pageReplica input[type="range"]:focus,
.pageReplica input[type="date"]:focus,
.pageReplica input[type="month"]:focus,
.pageReplica input[type="week"]:focus,
.pageReplica input[type="time"]:focus,
.pageReplica input[type="datetime"]:focus,
.pageReplica input[type="datetime-local"]:focus,
.pageReplica input[type="color"]:focus,
.pageReplica textarea:focus {
    color: var(--text-heading-color);
    outline: none;
    box-shadow: none;
    border-color: var(--primary-color-1);
}

.pageReplica select {
    border: 1px solid var(--border-color-2);
}

.pageReplica textarea {
    width: 100%;
    height: 150px;
    padding-top: 15px;
}

.pageReplica button,
.pageReplica button:hover,
.pageReplica button:focus {
    box-shadow: none;
    border: none;
    outline: none;
}
.pageReplica .react-international-phone-input {
    height: 38px !important;
}
/*==========================================================================
* Hedging CSS
==========================================================================*/
.pageReplica h1 {
    font-size: 80px;
    line-height: 90px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 700;
}

.pageReplica h2 {
    font-size: 50px;
    line-height: 60px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 600;
}

.pageReplica h3 {
    font-size: 30px;
    line-height: 40px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 600;
}

.pageReplica h4 {
    font-size: 24px;
    line-height: 34px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 600;
}

.pageReplica h5 {
    font-size: 20px;
    line-height: 30px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 600;
}

.pageReplica h6 {
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    margin: 0;
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 600;
}

@media (max-width: 1199px) {
    .pageReplica h2 {
        font-size: 42px;
        line-height: 52px;
    }
}
@media (max-width: 575px) {
    .pageReplica h2 {
        font-size: 38px;
        line-height: 48px;
    }
}
@media (max-width: 475px) {
    .pageReplica h2 {
        font-size: 28px;
        line-height: 38px;
    }
    .pageReplica h3 {
        font-size: 24px;
        line-height: 34px;
    }
    .pageReplica h4 {
        font-size: 21px;
        line-height: 31px;
    }
    .pageReplica h5 {
        font-size: 19px;
        line-height: 29px;
    }
}
@media (max-width: 359px) {
    .pageReplica h4 {
        font-size: 20px;
        line-height: 30px;
    }
    .pageReplica h6 {
        font-size: 16px;
        line-height: 26px;
    }
}
/*==========================================================================
* Button And Title Styles CSS
==========================================================================*/
.subtitle-one,
.subtitle-two,
.subtitle-three,
.subtitle-four {
    position: relative;
    font-family: var(--heading-font);
    color: var(--primary-color-4);
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    padding-left: 30px;
    display: inline-block;
    margin-bottom: 14px;
}
.subtitle-one::before,
.subtitle-two::before,
.subtitle-three::before,
.subtitle-four::before {
    position: absolute;
    content: "";
    top: 2px;
    left: 0;
    background-image: url("../src/assests/img/icon/subtitle-4.png");
    background-size: 19px;
    height: 22px;
    width: 20px;
    background-repeat: no-repeat;
}

.subtitle-two {
    color: var(--primary-color-2);
}
.subtitle-two::before {
    background-image: url("../src/assests/img/icon/subtitle-2.png");
}

.subtitle-three {
    color: var(--primary-color-3);
}
.subtitle-three::before {
    background-image: url("../src/assests/img/icon/subtitle-3.png");
}

.subtitle-four {
    color: var(--primary-color-1);
}
.subtitle-four::before {
    background-image: url("../src/assests/img/icon/subtitle-1.png");
}

.pageReplica .btn-one,
.pageReplica .btn-two {
    background: var(--primary-color-1);
    color: var(--text-white);
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    padding: 16px 15px;
    font-weight: 600;
    font-family: var(--body-font);
    z-index: 3;
    position: relative;
    transition: 0.4s;
    text-transform: capitalize;
    border-radius: 6px;
}
.pageReplica .btn-one::after,
.btn-one::before,
.pageReplica .btn-two::after,
.pageReplica .btn-two::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 0;
    background: var(--btn-heading-color);
    border-radius: 6px 30px 30px 6px;
    transition: all 0.4s ease-out;
    z-index: -1;
}
.pageReplica .btn-one::after,
.pageReplica .btn-two::after {
    right: 0;
    border-radius: 30px 6px 6px 30px;
    left: auto;
}
.pageReplica .btn-one:hover,
.pageReplica .btn-two:hover {
    color: var(--text-white);
}
.pageReplica .btn-one:hover::after,
.btn-one:hover::before,
.pageReplica .btn-two:hover::after,
.pageReplica .btn-two:hover::before {
    width: 52%;
    border-radius: 6px;
}
.pageReplica .btn-one:focus,
.pageReplica .btn-two:focus {
    color: var(--text-white);
}

.btn-two {
    background: var(--primary-color-2);
    border-radius: 30px;
    overflow: hidden;
    display: inline-flex;
}
.btn-two::after,
.btn-two::before {
    border-radius: 30px;
}
.btn-two:hover::before {
    border-radius: 30px 0 0 30px;
}
.btn-two:hover::after {
    border-radius: 0 30px 30px 0;
}

.simple-btn {
    color: var(--btn-heading-color);
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    font-weight: 700;
    font-family: var(--heading-font);
    transition: 0.4s;
    text-transform: uppercase;
}
.simple-btn:hover {
    color: var(--primary-color-1);
}
.simple-btn:focus {
    color: var(--primary-color-1);
}

@keyframes rotate {
    100% {
        transform: rotate(90deg);
    }
}
/*==========================================================================
* Scroll Top CSS
==========================================================================*/
.scroll-up {
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px var(--color-8);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 50px;
    width: 50px;
    transition: all 200ms linear;
}
.scroll-up::after {
    position: absolute;
    font-family: "Font Awesome 5 Pro";
    content: "\f176";
    text-align: center;
    line-height: 50px;
    font-size: 24px;
    color: var(--primary-color-1);
    left: 0;
    top: 0;
    font-size: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    z-index: 1;
    transition: all 200ms linear;
}
.scroll-up.active-scroll {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.scroll-up svg path {
    fill: none;
}
.scroll-up svg.scroll-circle path {
    stroke: var(--primary-color-1);
    stroke-width: 4;
    box-sizing: border-box;
    transition: all 200ms linear;
}

.scroll-two::after {
    color: var(--primary-color-2);
}
.scroll-two svg.scroll-circle path {
    stroke: var(--primary-color-2);
}

.scroll-three::after {
    color: var(--primary-color-3);
}
.scroll-three svg.scroll-circle path {
    stroke: var(--primary-color-3);
}

.scroll-four::after {
    color: var(--primary-color-4);
}
.scroll-four svg.scroll-circle path {
    stroke: var(--primary-color-4);
}

/*==========================================================================
* Preloader CSS
==========================================================================*/
.theme-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    z-index: 9999999999;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
}

.spinner-bounce {
    will-change: transform;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: var(--primary-color-1);
    display: inline-block;
    animation: bounces 1s ease-in-out infinite alternate;
    transform-origin: 50% 50%;
}
.spinner-bounce.one {
    margin-right: 15px;
}
.spinner-bounce.two {
    margin-right: 15px;
    animation-delay: 0.4s;
}
.spinner-bounce.three {
    animation-delay: 0.8s;
}

@keyframes bounces {
    0% {
        transform: scale(1);
        background: var(--primary-color-1);
    }
    50% {
        background: var(--primary-color-2);
    }
    100% {
        transform: scale(0.2);
        background: var(--primary-color-3);
    }
}
.swiper-button-next::after,
.swiper-button-prev::after {
    display: none;
}

/*==========================================================================
* Color & Font CSS
==========================================================================*/
:root {
    --body-font: "Kumbh Sans", sans-serif;
    --heading-font: "Outfit", sans-serif;
    --text-white: #ffffff;
    --bg-white: #ffffff;
    --btn-white: #ffffff;
    --border-white: #ffffff;
    --primary-color-1: #3f7e7c;
    --primary-color-4: #3f7e7c;
    --primary-color-2: #2974ff;
    --primary-color-3: #0d9b4d;
    --bg-heading-color: #121212;
    --text-heading-color: #121212;
    --btn-heading-color: #121212;
    --body-color: #666667;
    --dark-one: #121212;
    --dark-two: #171717;
    --color-1: #333334;
    --color-2: #999999;
    --color-3: #cccccc;
    --color-4: #f8f8f8;
    --color-5: #f6f6f6;
    --color-6: #141c21;
    --color-7: #feede8;
    --color-8: #f2f3f3;
    --color-9: #131315;
    --color-10: #1e1f21;
    --color-11: #fa714a;
    --color-12: #fdb8a5;
    --color-13: #f8e7e2;
    --color-14: #eaf1ff;
    --color-15: #0d0d0d;
    --color-16: #202123;
    --color-17: #111214;
    --color-18: #161719;
    --color-19: #2d2e30;
    --color-20: #ececec;
    --color-21: #2d2f34;
    --color-22: #424448;
    --color-23: #57595d;
    --color-24: #56b982;
    --border-color-1: #e6e6e6;
    --border-color-2: #f0f0f0;
    --border-color-3: rgba(255, 255, 255, 0.1);
    --border-color-4: rgba(19, 19, 19, 0.06);
    --box-shadow-1: 0px 30px 70px rgba(0, 0, 0, 0.08);
    --box-shadow-2: 0px 10px 70px rgba(0, 0, 0, 0.1);
}

/*==========================================================================
* Video Animation CSS
==========================================================================*/
.video {
    position: relative;
    text-align: center;
    display: inline-block;
    z-index: 4;
}
.video a {
    position: relative;
    color: var(--primary-color-1);
    font-size: 20px;
    z-index: 1;
    background: var(--bg-white);
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 50%;
    display: block;
}

.video-pulse::after,
.video-pulse::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-white);
    opacity: 0.3;
    left: 0;
    top: 0;
    border-radius: 50%;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-name: video-animation;
    animation-iteration-count: infinite;
}

.video-pulse::before {
    animation-delay: 1s;
}

@keyframes video-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}
/*==========================================================================
* Shape & Image Animation CSS
==========================================================================*/
@keyframes bounceRight {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }
    30% {
        transform: translateX(-95px);
    }
    10% {
        transform: translateX(-5px);
    }
}
@keyframes rollIn {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-359deg);
    }
}
@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
@keyframes upDown {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 30px, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
.left-right-animate {
    animation-name: left-right;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes left-right {
    0% {
        transform: translateX(20px);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(20px);
    }
}
.left-right-animate2 {
    animation-name: left-right2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes left-right2 {
    0% {
        transform: translateX(10px);
    }
    50% {
        transform: translateX(110px);
    }
    100% {
        transform: translateX(10px);
    }
}
/*==========================================================================
* Icon Animation CSS
==========================================================================*/
.icon-animation {
    animation: icon-animation 1.5s ease-in-out infinite;
}

@keyframes icon-animation {
    from {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    20%,
    32%,
    44%,
    56%,
    68% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    23%,
    35%,
    47%,
    59%,
    71% {
        transform: rotate3d(0, 0, 1, 15deg);
    }
    26%,
    38%,
    50%,
    62%,
    74% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    29%,
    41%,
    53%,
    65%,
    77% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}
/*==========================================================================
* Image Overlay Animation CSS
==========================================================================*/
.image-overlay {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: inline-block;
}
.image-overlay img {
    width: 100%;
}
.image-overlay::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.138);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.image-overlay:hover::after {
    animation: circle 1s;
}

@keyframes animation {
    100% {
        left: 125%;
    }
}
@keyframes rotateY {
    100% {
        transform: rotateY(360deg);
    }
}
/*==========================================================================
* Shape & Text Slider CSS
==========================================================================*/
.sliders {
    flex-shrink: 0;
}

.scroll {
    animation: scroll 70s linear infinite;
}

.text_scroll {
    animation: scroll 30s linear infinite;
    animation-direction: reverse;
}

.shape-slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    --gap: 0.1px;
    display: flex;
    gap: var(--gap);
    overflow: hidden;
    z-index: -1;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}
@keyframes wobble-vertical {
    16.65% {
        transform: translateY(4px);
    }
    33.3% {
        transform: translateY(-4px);
    }
    49.95% {
        transform: translateY(4px);
    }
    66.6% {
        transform: translateY(-2px);
    }
    83.25% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0);
    }
}
/*==========================================================================
* Space And Container CSS
==========================================================================*/
.custom__container {
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 12px;
}

@media (max-width: 1399px) {
    .container {
        max-width: 1220px;
    }
}
@media (max-width: 1199px) {
    .container {
        max-width: 980px;
    }
}
@media (max-width: 991px) {
    .container {
        max-width: 720px;
    }
}
@media (max-width: 767px) {
    .container {
        max-width: 560px;
    }
}
.p-relative {
    position: relative;
}

.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

.jc-center {
    justify-content: center;
}

.jc-end {
    justify-content: end;
}

.mt-0 {
    margin-top: 0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.ml-0 {
    margin-left: 0px;
}

.mr-0 {
    margin-right: 0px;
}

.pt-0 {
    padding-top: 0px;
}

.pb-0 {
    padding-bottom: 0px;
}

.pl-0 {
    padding-left: 0px;
}

.pr-0 {
    padding-right: 0px;
}

.mt-5 {
    margin-top: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.pt-5 {
    padding-top: 5px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.pr-5 {
    padding-right: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.ml-15 {
    margin-left: 15px;
}

.mr-15 {
    margin-right: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pl-15 {
    padding-left: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.ml-25 {
    margin-left: 25px;
}

.mr-25 {
    margin-right: 25px;
}

.pt-25 {
    padding-top: 25px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pl-25 {
    padding-left: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.pr-30 {
    padding-right: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mb-35 {
    margin-bottom: 35px;
}

.ml-35 {
    margin-left: 35px;
}

.mr-35 {
    margin-right: 35px;
}

.pt-35 {
    padding-top: 35px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pl-35 {
    padding-left: 35px;
}

.pr-35 {
    padding-right: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.pt-40 {
    padding-top: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.pr-40 {
    padding-right: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mb-45 {
    margin-bottom: 45px;
}

.ml-45 {
    margin-left: 45px;
}

.mr-45 {
    margin-right: 45px;
}

.pt-45 {
    padding-top: 45px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pl-45 {
    padding-left: 45px;
}

.pr-45 {
    padding-right: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-50 {
    margin-left: 50px;
}

.mr-50 {
    margin-right: 50px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pl-50 {
    padding-left: 50px;
}

.pr-50 {
    padding-right: 50px;
}

.mt-55 {
    margin-top: 55px;
}

.mb-55 {
    margin-bottom: 55px;
}

.ml-55 {
    margin-left: 55px;
}

.mr-55 {
    margin-right: 55px;
}

.pt-55 {
    padding-top: 55px;
}

.pb-55 {
    padding-bottom: 55px;
}

.pl-55 {
    padding-left: 55px;
}

.pr-55 {
    padding-right: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.ml-60 {
    margin-left: 60px;
}

.mr-60 {
    margin-right: 60px;
}

.pt-60 {
    padding-top: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pl-60 {
    padding-left: 60px;
}

.pr-60 {
    padding-right: 60px;
}

.mt-65 {
    margin-top: 65px;
}

.mb-65 {
    margin-bottom: 65px;
}

.ml-65 {
    margin-left: 65px;
}

.mr-65 {
    margin-right: 65px;
}

.pt-65 {
    padding-top: 65px;
}

.pb-65 {
    padding-bottom: 65px;
}

.pl-65 {
    padding-left: 65px;
}

.pr-65 {
    padding-right: 65px;
}

.mt-70 {
    margin-top: 70px;
}

.mb-70 {
    margin-bottom: 70px;
}

.ml-70 {
    margin-left: 70px;
}

.mr-70 {
    margin-right: 70px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pl-70 {
    padding-left: 70px;
}

.pr-70 {
    padding-right: 70px;
}

.mt-75 {
    margin-top: 75px;
}

.mb-75 {
    margin-bottom: 75px;
}

.ml-75 {
    margin-left: 75px;
}

.mr-75 {
    margin-right: 75px;
}

.pt-75 {
    padding-top: 75px;
}

.pb-75 {
    padding-bottom: 75px;
}

.pl-75 {
    padding-left: 75px;
}

.pr-75 {
    padding-right: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

.ml-80 {
    margin-left: 80px;
}

.mr-80 {
    margin-right: 80px;
}

.pt-80 {
    padding-top: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pl-80 {
    padding-left: 80px;
}

.pr-80 {
    padding-right: 80px;
}

.mt-85 {
    margin-top: 85px;
}

.mb-85 {
    margin-bottom: 85px;
}

.ml-85 {
    margin-left: 85px;
}

.mr-85 {
    margin-right: 85px;
}

.pt-85 {
    padding-top: 85px;
}

.pb-85 {
    padding-bottom: 85px;
}

.pl-85 {
    padding-left: 85px;
}

.pr-85 {
    padding-right: 85px;
}

.mt-90 {
    margin-top: 90px;
}

.mb-90 {
    margin-bottom: 90px;
}

.ml-90 {
    margin-left: 90px;
}

.mr-90 {
    margin-right: 90px;
}

.pt-90 {
    padding-top: 90px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pl-90 {
    padding-left: 90px;
}

.pr-90 {
    padding-right: 90px;
}

.mt-95 {
    margin-top: 95px;
}

.mb-95 {
    margin-bottom: 95px;
}

.ml-95 {
    margin-left: 95px;
}

.mr-95 {
    margin-right: 95px;
}

.pt-95 {
    padding-top: 95px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pl-95 {
    padding-left: 95px;
}

.pr-95 {
    padding-right: 95px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-100 {
    margin-left: 100px;
}

.mr-100 {
    margin-right: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pl-100 {
    padding-left: 100px;
}

.pr-100 {
    padding-right: 100px;
}

.mt-105 {
    margin-top: 105px;
}

.mb-105 {
    margin-bottom: 105px;
}

.ml-105 {
    margin-left: 105px;
}

.mr-105 {
    margin-right: 105px;
}

.pt-105 {
    padding-top: 105px;
}

.pb-105 {
    padding-bottom: 105px;
}

.pl-105 {
    padding-left: 105px;
}

.pr-105 {
    padding-right: 105px;
}

.mt-110 {
    margin-top: 110px;
}

.mb-110 {
    margin-bottom: 110px;
}

.ml-110 {
    margin-left: 110px;
}

.mr-110 {
    margin-right: 110px;
}

.pt-110 {
    padding-top: 110px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pl-110 {
    padding-left: 110px;
}

.pr-110 {
    padding-right: 110px;
}

.mt-115 {
    margin-top: 115px;
}

.mb-115 {
    margin-bottom: 115px;
}

.ml-115 {
    margin-left: 115px;
}

.mr-115 {
    margin-right: 115px;
}

.pt-115 {
    padding-top: 115px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pl-115 {
    padding-left: 115px;
}

.pr-115 {
    padding-right: 115px;
}

.mt-120 {
    margin-top: 120px;
}

.mb-120 {
    margin-bottom: 120px;
}

.ml-120 {
    margin-left: 120px;
}

.mr-120 {
    margin-right: 120px;
}

.pt-120 {
    padding-top: 120px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pl-120 {
    padding-left: 120px;
}

.pr-120 {
    padding-right: 120px;
}

@media (max-width: 1199px) {
    .xl-pb-0 {
        padding-bottom: 0px;
    }
    .xl-pl-0 {
        padding-left: 0px;
    }
    .xl-pr-0 {
        padding-right: 0px;
    }
    .xl-mb-0 {
        margin-bottom: 0px;
    }
    .xl-ml-0 {
        margin-left: 0px;
    }
    .xl-mr-0 {
        margin-right: 0px;
    }
    .xl-pb-5 {
        padding-bottom: 5px;
    }
    .xl-pl-5 {
        padding-left: 5px;
    }
    .xl-pr-5 {
        padding-right: 5px;
    }
    .xl-mb-5 {
        margin-bottom: 5px;
    }
    .xl-ml-5 {
        margin-left: 5px;
    }
    .xl-mr-5 {
        margin-right: 5px;
    }
    .xl-pb-10 {
        padding-bottom: 10px;
    }
    .xl-pl-10 {
        padding-left: 10px;
    }
    .xl-pr-10 {
        padding-right: 10px;
    }
    .xl-mb-10 {
        margin-bottom: 10px;
    }
    .xl-ml-10 {
        margin-left: 10px;
    }
    .xl-mr-10 {
        margin-right: 10px;
    }
    .xl-pb-15 {
        padding-bottom: 15px;
    }
    .xl-pl-15 {
        padding-left: 15px;
    }
    .xl-pr-15 {
        padding-right: 15px;
    }
    .xl-mb-15 {
        margin-bottom: 15px;
    }
    .xl-ml-15 {
        margin-left: 15px;
    }
    .xl-mr-15 {
        margin-right: 15px;
    }
    .xl-pb-20 {
        padding-bottom: 20px;
    }
    .xl-pl-20 {
        padding-left: 20px;
    }
    .xl-pr-20 {
        padding-right: 20px;
    }
    .xl-mb-20 {
        margin-bottom: 20px;
    }
    .xl-ml-20 {
        margin-left: 20px;
    }
    .xl-mr-20 {
        margin-right: 20px;
    }
    .xl-pb-25 {
        padding-bottom: 25px;
    }
    .xl-pl-25 {
        padding-left: 25px;
    }
    .xl-pr-25 {
        padding-right: 25px;
    }
    .xl-mb-25 {
        margin-bottom: 25px;
    }
    .xl-ml-25 {
        margin-left: 25px;
    }
    .xl-mr-25 {
        margin-right: 25px;
    }
    .xl-pb-30 {
        padding-bottom: 30px;
    }
    .xl-pl-30 {
        padding-left: 30px;
    }
    .xl-pr-30 {
        padding-right: 30px;
    }
    .xl-mb-30 {
        margin-bottom: 30px;
    }
    .xl-ml-30 {
        margin-left: 30px;
    }
    .xl-mr-30 {
        margin-right: 30px;
    }
    .xl-pb-35 {
        padding-bottom: 35px;
    }
    .xl-pl-35 {
        padding-left: 35px;
    }
    .xl-pr-35 {
        padding-right: 35px;
    }
    .xl-mb-35 {
        margin-bottom: 35px;
    }
    .xl-ml-35 {
        margin-left: 35px;
    }
    .xl-mr-35 {
        margin-right: 35px;
    }
    .xl-pb-40 {
        padding-bottom: 40px;
    }
    .xl-pl-40 {
        padding-left: 40px;
    }
    .xl-pr-40 {
        padding-right: 40px;
    }
    .xl-mb-40 {
        margin-bottom: 40px;
    }
    .xl-ml-40 {
        margin-left: 40px;
    }
    .xl-mr-40 {
        margin-right: 40px;
    }
    .xl-pb-45 {
        padding-bottom: 45px;
    }
    .xl-pl-45 {
        padding-left: 45px;
    }
    .xl-pr-45 {
        padding-right: 45px;
    }
    .xl-mb-45 {
        margin-bottom: 45px;
    }
    .xl-ml-45 {
        margin-left: 45px;
    }
    .xl-mr-45 {
        margin-right: 45px;
    }
    .xl-pb-50 {
        padding-bottom: 50px;
    }
    .xl-pl-50 {
        padding-left: 50px;
    }
    .xl-pr-50 {
        padding-right: 50px;
    }
    .xl-mb-50 {
        margin-bottom: 50px;
    }
    .xl-ml-50 {
        margin-left: 50px;
    }
    .xl-mr-50 {
        margin-right: 50px;
    }
    .xl-pb-55 {
        padding-bottom: 55px;
    }
    .xl-pl-55 {
        padding-left: 55px;
    }
    .xl-pr-55 {
        padding-right: 55px;
    }
    .xl-mb-55 {
        margin-bottom: 55px;
    }
    .xl-ml-55 {
        margin-left: 55px;
    }
    .xl-mr-55 {
        margin-right: 55px;
    }
    .xl-pb-60 {
        padding-bottom: 60px;
    }
    .xl-pl-60 {
        padding-left: 60px;
    }
    .xl-pr-60 {
        padding-right: 60px;
    }
    .xl-mb-60 {
        margin-bottom: 60px;
    }
    .xl-ml-60 {
        margin-left: 60px;
    }
    .xl-mr-60 {
        margin-right: 60px;
    }
    .xl-pb-65 {
        padding-bottom: 65px;
    }
    .xl-pl-65 {
        padding-left: 65px;
    }
    .xl-pr-65 {
        padding-right: 65px;
    }
    .xl-mb-65 {
        margin-bottom: 65px;
    }
    .xl-ml-65 {
        margin-left: 65px;
    }
    .xl-mr-65 {
        margin-right: 65px;
    }
    .xl-pb-70 {
        padding-bottom: 70px;
    }
    .xl-pl-70 {
        padding-left: 70px;
    }
    .xl-pr-70 {
        padding-right: 70px;
    }
    .xl-mb-70 {
        margin-bottom: 70px;
    }
    .xl-ml-70 {
        margin-left: 70px;
    }
    .xl-mr-70 {
        margin-right: 70px;
    }
    .xl-pb-75 {
        padding-bottom: 75px;
    }
    .xl-pl-75 {
        padding-left: 75px;
    }
    .xl-pr-75 {
        padding-right: 75px;
    }
    .xl-mb-75 {
        margin-bottom: 75px;
    }
    .xl-ml-75 {
        margin-left: 75px;
    }
    .xl-mr-75 {
        margin-right: 75px;
    }
    .xl-pb-80 {
        padding-bottom: 80px;
    }
    .xl-pl-80 {
        padding-left: 80px;
    }
    .xl-pr-80 {
        padding-right: 80px;
    }
    .xl-mb-80 {
        margin-bottom: 80px;
    }
    .xl-ml-80 {
        margin-left: 80px;
    }
    .xl-mr-80 {
        margin-right: 80px;
    }
    .xl-pb-85 {
        padding-bottom: 85px;
    }
    .xl-pl-85 {
        padding-left: 85px;
    }
    .xl-pr-85 {
        padding-right: 85px;
    }
    .xl-mb-85 {
        margin-bottom: 85px;
    }
    .xl-ml-85 {
        margin-left: 85px;
    }
    .xl-mr-85 {
        margin-right: 85px;
    }
    .xl-pb-90 {
        padding-bottom: 90px;
    }
    .xl-pl-90 {
        padding-left: 90px;
    }
    .xl-pr-90 {
        padding-right: 90px;
    }
    .xl-mb-90 {
        margin-bottom: 90px;
    }
    .xl-ml-90 {
        margin-left: 90px;
    }
    .xl-mr-90 {
        margin-right: 90px;
    }
    .xl-pb-95 {
        padding-bottom: 95px;
    }
    .xl-pl-95 {
        padding-left: 95px;
    }
    .xl-pr-95 {
        padding-right: 95px;
    }
    .xl-mb-95 {
        margin-bottom: 95px;
    }
    .xl-ml-95 {
        margin-left: 95px;
    }
    .xl-mr-95 {
        margin-right: 95px;
    }
    .xl-t-left {
        text-align: left !important;
    }
    .xl-t-center {
        text-align: center !important;
    }
    .xl-t-right {
        text-align: right !important;
    }
    .xl-display-n {
        display: none !important;
    }
    .xl-display-b {
        display: block !important;
    }
}
@media (max-width: 991px) {
    .lg-mb-0 {
        margin-bottom: 0px;
    }
    .lg-mt-0 {
        margin-top: 0px;
    }
    .lg-ml-0 {
        margin-left: 0px;
    }
    .lg-pt-0 {
        padding-top: 0px;
    }
    .lg-pb-0 {
        padding-bottom: 0px;
    }
    .lg-pl-0 {
        padding-left: 0px;
    }
    .lg-pr-0 {
        padding-right: 0px;
    }
    .lg-mb-5 {
        margin-bottom: 5px;
    }
    .lg-mt-5 {
        margin-top: 5px;
    }
    .lg-ml-5 {
        margin-left: 5px;
    }
    .lg-pt-5 {
        padding-top: 5px;
    }
    .lg-pb-5 {
        padding-bottom: 5px;
    }
    .lg-pl-5 {
        padding-left: 5px;
    }
    .lg-pr-5 {
        padding-right: 5px;
    }
    .lg-mb-10 {
        margin-bottom: 10px;
    }
    .lg-mt-10 {
        margin-top: 10px;
    }
    .lg-ml-10 {
        margin-left: 10px;
    }
    .lg-pt-10 {
        padding-top: 10px;
    }
    .lg-pb-10 {
        padding-bottom: 10px;
    }
    .lg-pl-10 {
        padding-left: 10px;
    }
    .lg-pr-10 {
        padding-right: 10px;
    }
    .lg-mb-15 {
        margin-bottom: 15px;
    }
    .lg-mt-15 {
        margin-top: 15px;
    }
    .lg-ml-15 {
        margin-left: 15px;
    }
    .lg-pt-15 {
        padding-top: 15px;
    }
    .lg-pb-15 {
        padding-bottom: 15px;
    }
    .lg-pl-15 {
        padding-left: 15px;
    }
    .lg-pr-15 {
        padding-right: 15px;
    }
    .lg-mb-20 {
        margin-bottom: 20px;
    }
    .lg-mt-20 {
        margin-top: 20px;
    }
    .lg-ml-20 {
        margin-left: 20px;
    }
    .lg-pt-20 {
        padding-top: 20px;
    }
    .lg-pb-20 {
        padding-bottom: 20px;
    }
    .lg-pl-20 {
        padding-left: 20px;
    }
    .lg-pr-20 {
        padding-right: 20px;
    }
    .lg-mb-25 {
        margin-bottom: 25px;
    }
    .lg-mt-25 {
        margin-top: 25px;
    }
    .lg-ml-25 {
        margin-left: 25px;
    }
    .lg-pt-25 {
        padding-top: 25px;
    }
    .lg-pb-25 {
        padding-bottom: 25px;
    }
    .lg-pl-25 {
        padding-left: 25px;
    }
    .lg-pr-25 {
        padding-right: 25px;
    }
    .lg-mb-30 {
        margin-bottom: 30px;
    }
    .lg-mt-30 {
        margin-top: 30px;
    }
    .lg-ml-30 {
        margin-left: 30px;
    }
    .lg-pt-30 {
        padding-top: 30px;
    }
    .lg-pb-30 {
        padding-bottom: 30px;
    }
    .lg-pl-30 {
        padding-left: 30px;
    }
    .lg-pr-30 {
        padding-right: 30px;
    }
    .lg-mb-35 {
        margin-bottom: 35px;
    }
    .lg-mt-35 {
        margin-top: 35px;
    }
    .lg-ml-35 {
        margin-left: 35px;
    }
    .lg-pt-35 {
        padding-top: 35px;
    }
    .lg-pb-35 {
        padding-bottom: 35px;
    }
    .lg-pl-35 {
        padding-left: 35px;
    }
    .lg-pr-35 {
        padding-right: 35px;
    }
    .lg-mb-40 {
        margin-bottom: 40px;
    }
    .lg-mt-40 {
        margin-top: 40px;
    }
    .lg-ml-40 {
        margin-left: 40px;
    }
    .lg-pt-40 {
        padding-top: 40px;
    }
    .lg-pb-40 {
        padding-bottom: 40px;
    }
    .lg-pl-40 {
        padding-left: 40px;
    }
    .lg-pr-40 {
        padding-right: 40px;
    }
    .lg-mb-45 {
        margin-bottom: 45px;
    }
    .lg-mt-45 {
        margin-top: 45px;
    }
    .lg-ml-45 {
        margin-left: 45px;
    }
    .lg-pt-45 {
        padding-top: 45px;
    }
    .lg-pb-45 {
        padding-bottom: 45px;
    }
    .lg-pl-45 {
        padding-left: 45px;
    }
    .lg-pr-45 {
        padding-right: 45px;
    }
    .lg-mb-50 {
        margin-bottom: 50px;
    }
    .lg-mt-50 {
        margin-top: 50px;
    }
    .lg-ml-50 {
        margin-left: 50px;
    }
    .lg-pt-50 {
        padding-top: 50px;
    }
    .lg-pb-50 {
        padding-bottom: 50px;
    }
    .lg-pl-50 {
        padding-left: 50px;
    }
    .lg-pr-50 {
        padding-right: 50px;
    }
    .lg-mb-55 {
        margin-bottom: 55px;
    }
    .lg-mt-55 {
        margin-top: 55px;
    }
    .lg-ml-55 {
        margin-left: 55px;
    }
    .lg-pt-55 {
        padding-top: 55px;
    }
    .lg-pb-55 {
        padding-bottom: 55px;
    }
    .lg-pl-55 {
        padding-left: 55px;
    }
    .lg-pr-55 {
        padding-right: 55px;
    }
    .lg-mb-60 {
        margin-bottom: 60px;
    }
    .lg-mt-60 {
        margin-top: 60px;
    }
    .lg-ml-60 {
        margin-left: 60px;
    }
    .lg-pt-60 {
        padding-top: 60px;
    }
    .lg-pb-60 {
        padding-bottom: 60px;
    }
    .lg-pl-60 {
        padding-left: 60px;
    }
    .lg-pr-60 {
        padding-right: 60px;
    }
    .lg-mb-65 {
        margin-bottom: 65px;
    }
    .lg-mt-65 {
        margin-top: 65px;
    }
    .lg-ml-65 {
        margin-left: 65px;
    }
    .lg-pt-65 {
        padding-top: 65px;
    }
    .lg-pb-65 {
        padding-bottom: 65px;
    }
    .lg-pl-65 {
        padding-left: 65px;
    }
    .lg-pr-65 {
        padding-right: 65px;
    }
    .lg-mb-70 {
        margin-bottom: 70px;
    }
    .lg-mt-70 {
        margin-top: 70px;
    }
    .lg-ml-70 {
        margin-left: 70px;
    }
    .lg-pt-70 {
        padding-top: 70px;
    }
    .lg-pb-70 {
        padding-bottom: 70px;
    }
    .lg-pl-70 {
        padding-left: 70px;
    }
    .lg-pr-70 {
        padding-right: 70px;
    }
    .lg-t-left {
        text-align: left !important;
    }
    .lg-t-center {
        text-align: center !important;
    }
    .lg-t-right {
        text-align: right !important;
    }
    .lg-jc-center {
        justify-content: center;
    }
    .lg-jc-end {
        justify-content: end;
    }
    .lg-display-n {
        display: none !important;
    }
    .lg-display-b {
        display: block !important;
    }
}
@media (max-width: 767px) {
    .md-mb-0 {
        margin-bottom: 0px;
    }
    .md-mt-0 {
        margin-top: 0px;
    }
    .md-pt-0 {
        padding-top: 0px;
    }
    .md-pb-0 {
        padding-bottom: 0px;
    }
    .md-pl-0 {
        padding-left: 0px;
    }
    .md-pr-0 {
        padding-right: 0px;
    }
    .md-mb-5 {
        margin-bottom: 5px;
    }
    .md-mt-5 {
        margin-top: 5px;
    }
    .md-pt-5 {
        padding-top: 5px;
    }
    .md-pb-5 {
        padding-bottom: 5px;
    }
    .md-pl-5 {
        padding-left: 5px;
    }
    .md-pr-5 {
        padding-right: 5px;
    }
    .md-mb-10 {
        margin-bottom: 10px;
    }
    .md-mt-10 {
        margin-top: 10px;
    }
    .md-pt-10 {
        padding-top: 10px;
    }
    .md-pb-10 {
        padding-bottom: 10px;
    }
    .md-pl-10 {
        padding-left: 10px;
    }
    .md-pr-10 {
        padding-right: 10px;
    }
    .md-mb-15 {
        margin-bottom: 15px;
    }
    .md-mt-15 {
        margin-top: 15px;
    }
    .md-pt-15 {
        padding-top: 15px;
    }
    .md-pb-15 {
        padding-bottom: 15px;
    }
    .md-pl-15 {
        padding-left: 15px;
    }
    .md-pr-15 {
        padding-right: 15px;
    }
    .md-mb-20 {
        margin-bottom: 20px;
    }
    .md-mt-20 {
        margin-top: 20px;
    }
    .md-pt-20 {
        padding-top: 20px;
    }
    .md-pb-20 {
        padding-bottom: 20px;
    }
    .md-pl-20 {
        padding-left: 20px;
    }
    .md-pr-20 {
        padding-right: 20px;
    }
    .md-mb-25 {
        margin-bottom: 25px;
    }
    .md-mt-25 {
        margin-top: 25px;
    }
    .md-pt-25 {
        padding-top: 25px;
    }
    .md-pb-25 {
        padding-bottom: 25px;
    }
    .md-pl-25 {
        padding-left: 25px;
    }
    .md-pr-25 {
        padding-right: 25px;
    }
    .md-mb-30 {
        margin-bottom: 30px;
    }
    .md-mt-30 {
        margin-top: 30px;
    }
    .md-pt-30 {
        padding-top: 30px;
    }
    .md-pb-30 {
        padding-bottom: 30px;
    }
    .md-pl-30 {
        padding-left: 30px;
    }
    .md-pr-30 {
        padding-right: 30px;
    }
    .md-mb-35 {
        margin-bottom: 35px;
    }
    .md-mt-35 {
        margin-top: 35px;
    }
    .md-pt-35 {
        padding-top: 35px;
    }
    .md-pb-35 {
        padding-bottom: 35px;
    }
    .md-pl-35 {
        padding-left: 35px;
    }
    .md-pr-35 {
        padding-right: 35px;
    }
    .md-mb-40 {
        margin-bottom: 40px;
    }
    .md-mt-40 {
        margin-top: 40px;
    }
    .md-pt-40 {
        padding-top: 40px;
    }
    .md-pb-40 {
        padding-bottom: 40px;
    }
    .md-pl-40 {
        padding-left: 40px;
    }
    .md-pr-40 {
        padding-right: 40px;
    }
    .md-mb-45 {
        margin-bottom: 45px;
    }
    .md-mt-45 {
        margin-top: 45px;
    }
    .md-pt-45 {
        padding-top: 45px;
    }
    .md-pb-45 {
        padding-bottom: 45px;
    }
    .md-pl-45 {
        padding-left: 45px;
    }
    .md-pr-45 {
        padding-right: 45px;
    }
    .md-t-left {
        text-align: left !important;
    }
    .md-t-center {
        text-align: center !important;
    }
    .md-t-right {
        text-align: right !important;
    }
    .md-jc-center {
        justify-content: center;
    }
    .md-jc-end {
        justify-content: end;
    }
    .md-display-n {
        display: none !important;
    }
    .md-display-b {
        display: block !important;
    }
}
@media (max-width: 575px) {
    .sm-mb-0 {
        margin-bottom: 0px;
    }
    .sm-mt-0 {
        margin-top: 0px;
    }
    .sm-ml-0 {
        margin-left: 0px;
    }
    .sm-pt-0 {
        padding-top: 0px;
    }
    .sm-pb-0 {
        padding-bottom: 0px;
    }
    .sm-pl-0 {
        padding-left: 0px;
    }
    .sm-pr-0 {
        padding-right: 0px;
    }
    .sm-mb-5 {
        margin-bottom: 5px;
    }
    .sm-mt-5 {
        margin-top: 5px;
    }
    .sm-ml-5 {
        margin-left: 5px;
    }
    .sm-pt-5 {
        padding-top: 5px;
    }
    .sm-pb-5 {
        padding-bottom: 5px;
    }
    .sm-pl-5 {
        padding-left: 5px;
    }
    .sm-pr-5 {
        padding-right: 5px;
    }
    .sm-mb-10 {
        margin-bottom: 10px;
    }
    .sm-mt-10 {
        margin-top: 10px;
    }
    .sm-ml-10 {
        margin-left: 10px;
    }
    .sm-pt-10 {
        padding-top: 10px;
    }
    .sm-pb-10 {
        padding-bottom: 10px;
    }
    .sm-pl-10 {
        padding-left: 10px;
    }
    .sm-pr-10 {
        padding-right: 10px;
    }
    .sm-mb-15 {
        margin-bottom: 15px;
    }
    .sm-mt-15 {
        margin-top: 15px;
    }
    .sm-ml-15 {
        margin-left: 15px;
    }
    .sm-pt-15 {
        padding-top: 15px;
    }
    .sm-pb-15 {
        padding-bottom: 15px;
    }
    .sm-pl-15 {
        padding-left: 15px;
    }
    .sm-pr-15 {
        padding-right: 15px;
    }
    .sm-mb-20 {
        margin-bottom: 20px;
    }
    .sm-mt-20 {
        margin-top: 20px;
    }
    .sm-ml-20 {
        margin-left: 20px;
    }
    .sm-pt-20 {
        padding-top: 20px;
    }
    .sm-pb-20 {
        padding-bottom: 20px;
    }
    .sm-pl-20 {
        padding-left: 20px;
    }
    .sm-pr-20 {
        padding-right: 20px;
    }
    .sm-mb-25 {
        margin-bottom: 25px;
    }
    .sm-mt-25 {
        margin-top: 25px;
    }
    .sm-ml-25 {
        margin-left: 25px;
    }
    .sm-pt-25 {
        padding-top: 25px;
    }
    .sm-pb-25 {
        padding-bottom: 25px;
    }
    .sm-pl-25 {
        padding-left: 25px;
    }
    .sm-pr-25 {
        padding-right: 25px;
    }
    .sm-mb-30 {
        margin-bottom: 30px;
    }
    .sm-mt-30 {
        margin-top: 30px;
    }
    .sm-ml-30 {
        margin-left: 30px;
    }
    .sm-pt-30 {
        padding-top: 30px;
    }
    .sm-pb-30 {
        padding-bottom: 30px;
    }
    .sm-pl-30 {
        padding-left: 30px;
    }
    .sm-pr-30 {
        padding-right: 30px;
    }
    .sm-mb-35 {
        margin-bottom: 35px;
    }
    .sm-mt-35 {
        margin-top: 35px;
    }
    .sm-ml-35 {
        margin-left: 35px;
    }
    .sm-pt-35 {
        padding-top: 35px;
    }
    .sm-pb-35 {
        padding-bottom: 35px;
    }
    .sm-pl-35 {
        padding-left: 35px;
    }
    .sm-pr-35 {
        padding-right: 35px;
    }
    .sm-mb-40 {
        margin-bottom: 40px;
    }
    .sm-mt-40 {
        margin-top: 40px;
    }
    .sm-ml-40 {
        margin-left: 40px;
    }
    .sm-pt-40 {
        padding-top: 40px;
    }
    .sm-pb-40 {
        padding-bottom: 40px;
    }
    .sm-pl-40 {
        padding-left: 40px;
    }
    .sm-pr-40 {
        padding-right: 40px;
    }
    .sm-mb-45 {
        margin-bottom: 45px;
    }
    .sm-mt-45 {
        margin-top: 45px;
    }
    .sm-ml-45 {
        margin-left: 45px;
    }
    .sm-pt-45 {
        padding-top: 45px;
    }
    .sm-pb-45 {
        padding-bottom: 45px;
    }
    .sm-pl-45 {
        padding-left: 45px;
    }
    .sm-pr-45 {
        padding-right: 45px;
    }
    .sm-mb-50 {
        margin-bottom: 50px;
    }
    .sm-mt-50 {
        margin-top: 50px;
    }
    .sm-ml-50 {
        margin-left: 50px;
    }
    .sm-pt-50 {
        padding-top: 50px;
    }
    .sm-pb-50 {
        padding-bottom: 50px;
    }
    .sm-pl-50 {
        padding-left: 50px;
    }
    .sm-pr-50 {
        padding-right: 50px;
    }
    .sm-mb-55 {
        margin-bottom: 55px;
    }
    .sm-mt-55 {
        margin-top: 55px;
    }
    .sm-ml-55 {
        margin-left: 55px;
    }
    .sm-pt-55 {
        padding-top: 55px;
    }
    .sm-pb-55 {
        padding-bottom: 55px;
    }
    .sm-pl-55 {
        padding-left: 55px;
    }
    .sm-pr-55 {
        padding-right: 55px;
    }
    .sm-t-left {
        text-align: left !important;
    }
    .sm-t-center {
        text-align: center !important;
    }
    .sm-t-right {
        text-align: right !important;
    }
    .sm-display-n {
        display: none !important;
    }
    .sm-display-b {
        display: block !important;
    }
}
/*==========================================================================
* Top Bar CSS
==========================================================================*/
.top__bar,
.top__bar-three,
.top__bar-four,
.top__bar-two {
    background: var(--color-16);
    padding: 9px 20px;
}
.top__bar-left ul,
.top__bar-three-left ul,
.top__bar-four-left ul,
.top__bar-two-left ul {
    padding: 0;
    margin: 0;
}
.top__bar-left ul li,
.top__bar-three-left ul li,
.top__bar-four-left ul li,
.top__bar-two-left ul li {
    display: inline-block;
    list-style: none;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid var(--body-color);
}
.top__bar-left ul li:last-child,
.top__bar-three-left ul li:last-child,
.top__bar-four-left ul li:last-child,
.top__bar-two-left ul li:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}
.top__bar-left ul li a,
.top__bar-three-left ul li a,
.top__bar-four-left ul li a,
.top__bar-two-left ul li a {
    color: var(--color-8);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    transition: 0.4s;
}
.top__bar-left ul li a:hover,
.top__bar-three-left ul li a:hover,
.top__bar-four-left ul li a:hover,
.top__bar-two-left ul li a:hover {
    color: var(--primary-color-1);
}
.top__bar-left ul li a i,
.top__bar-three-left ul li a i,
.top__bar-four-left ul li a i,
.top__bar-two-left ul li a i {
    margin-right: 10px;
    color: var(--primary-color-1);
}
.top__bar-right,
.top__bar-three-right,
.top__bar-four-right,
.top__bar-two-right {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: flex-end;
}
.top__bar-right h6,
.top__bar-three-right h6,
.top__bar-four-right h6,
.top__bar-two-right h6 {
    color: var(--text-white);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--body-font);
}
.top__bar-right-social,
.top__bar-three-right-social,
.top__bar-four-right-social,
.top__bar-two-right-social {
    overflow: hidden;
}
.top__bar-right-social ul,
.top__bar-three-right-social ul,
.top__bar-four-right-social ul,
.top__bar-two-right-social ul {
    padding: 0;
    margin: 0;
}
.top__bar-right-social ul li,
.top__bar-three-right-social ul li,
.top__bar-four-right-social ul li,
.top__bar-two-right-social ul li {
    list-style: none;
    display: inline-block;
}
.top__bar-right-social ul li a,
.top__bar-three-right-social ul li a,
.top__bar-four-right-social ul li a,
.top__bar-two-right-social ul li a {
    position: relative;
    padding-right: 10px;
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
    color: var(--text-white);
    display: flex;
    align-items: center;
    transition: 0.4s;
    font-family: var(--heading-font);
}
.top__bar-right-social ul li a span,
.top__bar-three-right-social ul li a span,
.top__bar-four-right-social ul li a span,
.top__bar-two-right-social ul li a span {
    display: inline-block;
    width: 0;
    opacity: 0;
    margin-left: 0;
    transition: all 400ms linear;
    position: relative;
}
.top__bar-right-social ul li a span::after,
.top__bar-three-right-social ul li a span::after,
.top__bar-four-right-social ul li a span::after,
.top__bar-two-right-social ul li a span::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    background: var(--body-color);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: 0.4s;
}
.top__bar-right-social ul li a:hover,
.top__bar-three-right-social ul li a:hover,
.top__bar-four-right-social ul li a:hover,
.top__bar-two-right-social ul li a:hover {
    color: var(--primary-color-1);
}
.top__bar-right-social ul li a:hover span,
.top__bar-three-right-social ul li a:hover span,
.top__bar-four-right-social ul li a:hover span,
.top__bar-two-right-social ul li a:hover span {
    padding-left: 10px;
    width: 95px;
    opacity: 1;
}
.top__bar-right-social ul li a:hover span::after,
.top__bar-three-right-social ul li a:hover span::after,
.top__bar-four-right-social ul li a:hover span::after,
.top__bar-two-right-social ul li a:hover span::after {
    background: var(--primary-color-1);
}
.top__bar-right-social ul li:nth-child(2) a:hover span,
.top__bar-three-right-social ul li:nth-child(2) a:hover span,
.top__bar-four-right-social ul li:nth-child(2) a:hover span,
.top__bar-two-right-social ul li:nth-child(2) a:hover span {
    width: 100px;
}
.top__bar-right-social ul li:nth-child(3) a:hover span,
.top__bar-three-right-social ul li:nth-child(3) a:hover span,
.top__bar-four-right-social ul li:nth-child(3) a:hover span,
.top__bar-two-right-social ul li:nth-child(3) a:hover span {
    width: 78px;
}
.top__bar-right-social ul li:nth-child(4) a:hover span,
.top__bar-three-right-social ul li:nth-child(4) a:hover span,
.top__bar-four-right-social ul li:nth-child(4) a:hover span,
.top__bar-two-right-social ul li:nth-child(4) a:hover span {
    width: 88px;
}
.top__bar-right-social ul li:last-child a,
.top__bar-three-right-social ul li:last-child a,
.top__bar-four-right-social ul li:last-child a,
.top__bar-two-right-social ul li:last-child a {
    padding: 0;
    margin: 0;
}
.top__bar-right-social ul li:last-child a::after,
.top__bar-three-right-social ul li:last-child a::after,
.top__bar-four-right-social ul li:last-child a::after,
.top__bar-two-right-social ul li:last-child a::after {
    display: none;
}

/*==========================================================================
* Top Bar Two CSS
==========================================================================*/
.top__bar-two {
    background: var(--primary-color-2);
}
.top__bar-two-left ul li {
    border-color: var(--color-20);
}
.top__bar-two-left ul li a {
    color: var(--text-white);
}
.top__bar-two-left ul li a:hover {
    color: var(--text-white);
}
.top__bar-two-left ul li a i {
    color: var(--text-white);
}
.top__bar-two-right-social ul li a {
    color: var(--text-white);
}
.top__bar-two-right-social ul li a:hover {
    color: var(--text-white);
}
.top__bar-two-right-social ul li a:hover span::after {
    background: var(--text-white);
}

/*==========================================================================
* Top Bar Three CSS
==========================================================================*/
.top__bar-three {
    background: var(--color-21);
    padding: 0;
    margin: 0;
    padding-left: 12px;
}
.top__bar-three-left ul li {
    border-color: var(--color-23);
}
.top__bar-three-left ul li a {
    color: var(--text-white);
}
.top__bar-three-left ul li a:hover {
    color: var(--primary-color-3);
}
.top__bar-three-left ul li a i {
    color: var(--primary-color-3);
}
.top__bar-three-right {
    margin-right: -12px;
}
.top__bar-three-right-social {
    background: var(--color-22);
    padding: 6px 25px;
}
.top__bar-three-right-social ul li a:hover {
    color: var(--primary-color-3);
}
.top__bar-three-right-social ul li a:hover span::after {
    background: var(--primary-color-3);
}

.top__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}
.top__header-bg {
    background: var(--bg-white);
}

/*==========================================================================
* Top Bar Four CSS
==========================================================================*/
.top__bar-four {
    background: var(--color-10);
}
.top__bar-four-left ul li a:hover {
    color: var(--primary-color-4);
}
.top__bar-four-left ul li a i {
    color: var(--primary-color-4);
}
.top__bar-four-right-social ul li a:hover {
    color: var(--primary-color-4);
}
.top__bar-four-right-social ul li a:hover span::after {
    background: var(--primary-color-4);
}

@media (max-width: 991px) {
    .top__bar-right,
    .top__bar-three-right,
    .top__bar-four-right,
    .top__bar-two-right {
        justify-content: center;
        margin-top: 4px;
    }
    .top__bar-three {
        padding: 0;
    }
    .top__bar-three-right {
        margin-right: 0;
    }
}
@media (max-width: 614px) {
    .top__bar-left ul li,
    .top__bar-three-left ul li,
    .top__bar-four-left ul li,
    .top__bar-two-left ul li {
        margin-right: 0;
        border: 0;
        padding-right: 0;
        display: block;
    }
}
@media (max-width: 614px) {
    .top__bar,
    .top__bar-three,
    .top__bar-two {
        padding: 9px 0;
    }
}
@media (max-width: 350px) {
    .top__bar-right-social ul li a,
    .top__bar-three-right-social ul li a,
    .top__bar-two-right-social ul li a {
        padding-right: 7px;
        margin-right: 7px;
    }
    .top__bar-right-social ul li a:hover span,
    .top__bar-three-right-social ul li a:hover span,
    .top__bar-two-right-social ul li a:hover span {
        padding-left: 7px;
        width: 90px;
    }
    .top__bar-right-social ul li:nth-child(2) a:hover span,
    .top__bar-three-right-social ul li:nth-child(2) a:hover span,
    .top__bar-two-right-social ul li:nth-child(2) a:hover span {
        width: 90px;
    }
    .top__bar-three-left ul li a i {
        margin-right: 7px;
    }
}
/*==========================================================================
* Menu Bar CSS
==========================================================================*/
.header__area {
    padding: 0 20px;
}
.header__area-menubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__area-menubar-left {
    position: relative;
    z-index: 99;
    display: flex;
}
.header__area-menubar-left-logo a {
    display: inline-block;
}
.header__area-menubar-left-logo a img {
    max-width: 160px;
    position: relative;
    z-index: 9999;
    padding: 26px 0;
}
.header__area-menubar-left-logo.three {
    background: var(--primary-color-3);
    padding: 34px 0 24px 25px;
    position: relative;
    z-index: 9;
    width: 248px;
}
.header__area-menubar-left-logo.three::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    background: var(--primary-color-3);
    width: 100%;
    height: 10px;
}
.header__area-menubar-center {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 36px 0;
    margin-left: 40px;
}
.header__area-menubar-center-menu ul {
    padding: 0;
    margin: 0;
}
.header__area-menubar-center-menu ul li {
    display: inline-block;
    position: relative;
    list-style: none;
    margin: 0 25px;
}
.header__area-menubar-center-menu ul li::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1);
    bottom: -38px;
    transform: skew(-35deg);
    height: 4px;
    background: var(--primary-color-1);
    z-index: 1;
}
.header__area-menubar-center-menu ul li:hover::after {
    width: 100%;
}
.header__area-menubar-center-menu ul li:hover > a {
    color: var(--primary-color-1);
}
.header__area-menubar-center-menu ul li:hover > .sub-menu {
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
}
.header__area-menubar-center-menu ul li .sub-menu {
    position: absolute;
    background: var(--bg-white);
    min-width: 240px;
    transition: all 0.3s ease-out 0s;
    top: 64px;
    opacity: 0;
    box-shadow: var(--box-shadow-1);
    visibility: hidden;
    z-index: 99;
    transform: scale(1, 0);
    transform-origin: 0 0;
    border-bottom: 4px solid var(--primary-color-1);
}
.header__area-menubar-center-menu ul li .sub-menu li {
    display: block;
    padding: 0 30px;
    margin: 0;
}
.header__area-menubar-center-menu ul li .sub-menu li::after {
    display: none;
}
.header__area-menubar-center-menu ul li .sub-menu li > a {
    border-bottom: 1px solid var(--border-color-2);
    color: var(--text-heading-color);
    padding: 13px 0;
    transition: all 0.4s ease-out 0s;
    font-size: 15px;
    line-height: 26px;
    text-transform: capitalize;
    position: relative;
}
.header__area-menubar-center-menu ul li .sub-menu li > a::after {
    position: absolute;
    content: "";
    background: var(--primary-color-1);
    width: 0;
    transition: all 0.4s ease-out 0s;
    height: 1px;
    left: 0;
    bottom: -1px;
}
.header__area-menubar-center-menu ul li .sub-menu li .sub-menu {
    left: 100%;
    top: 0;
}
.header__area-menubar-center-menu ul li .sub-menu li:hover > a {
    color: var(--primary-color-1);
}
.header__area-menubar-center-menu ul li .sub-menu li:hover > a::after {
    width: 100%;
}
.header__area-menubar-center-menu ul li .sub-menu li:last-child > a {
    border: none;
}
.header__area-menubar-center-menu ul li .sub-menu li:last-child > a::after {
    display: none;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu
    .menu-item-has-children
    > a::before {
    content: "\f105";
    right: 0;
    color: var(--text-heading-color);
}
.header__area-menubar-center-menu ul li ul .sub-menu li .sub-menu {
    color: var(--text-heading-color);
    cursor: pointer;
}
.header__area-menubar-center-menu ul li a {
    color: var(--text-heading-color);
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    transition: all 0.4s ease-out 0s;
    text-transform: capitalize;
    font-family: var(--body-font);
}
.header__area-menubar-center-menu ul li.menu-item-has-children > a {
    position: relative;
}
.header__area-menubar-center-menu ul li.menu-item-has-children > a::before {
    content: "\f107";
    position: absolute;
    top: 50%;
    transform: translateY(-48%);
    right: -15px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    color: var(--text-heading-color);
    transition: all 0.3s ease;
}
.header__area-menubar-center-menu
    ul
    li.menu-item-has-children:hover
    > a::before {
    color: var(--primary-color-1);
}
.header__area-menubar-center-menu ul li:first-child {
    margin-left: 0;
}
.header__area-menubar-center-menu ul li:last-child {
    margin-right: 0;
}
.header__area-menubar-center-menu ul li.active::after {
    width: 100%;
    bottom: -39px;
}
.header__area-menubar-center-menu.two {
    margin-left: -110px;
}
.header__area-menubar-center-menu.two ul li::after {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu.two ul li .sub-menu {
    border-bottom: 4px solid var(--primary-color-2);
}
.header__area-menubar-center-menu.two ul li .sub-menu li a::after {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu.two ul li .sub-menu li:hover > a {
    color: var(--primary-color-2);
}
.header__area-menubar-center-menu.two ul li:hover > a {
    color: var(--primary-color-2);
}
.header__area-menubar-center-menu.two
    ul
    li.menu-item-has-children:hover
    > a::before {
    color: var(--primary-color-2);
}
.header__area-menubar-center-menu.two.header__area-menubar-center-menu
    ul
    li.home
    > a::after {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu.three {
    margin-left: -27px;
}
.header__area-menubar-center-menu.three ul li::after {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu.three ul li .sub-menu {
    border-bottom: 4px solid var(--primary-color-3);
}
.header__area-menubar-center-menu.three ul li .sub-menu li a::after {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu.three ul li .sub-menu li:hover > a {
    color: var(--primary-color-3);
}
.header__area-menubar-center-menu.three ul li:hover > a {
    color: var(--primary-color-3);
}
.header__area-menubar-center-menu.three
    ul
    li.menu-item-has-children:hover
    > a::before {
    color: var(--primary-color-3);
}
.header__area-menubar-center-menu.three.header__area-menubar-center-menu
    ul
    li.home
    > a::after {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu.four ul li::after {
    background: var(--primary-color-4);
}
.header__area-menubar-center-menu.four ul li .sub-menu {
    border-bottom: 4px solid var(--primary-color-4);
}
.header__area-menubar-center-menu.four ul li .sub-menu li a::after {
    background: var(--primary-color-4);
}
.header__area-menubar-center-menu.four ul li .sub-menu li:hover > a {
    color: var(--primary-color-4);
}
.header__area-menubar-center-menu.four ul li:hover > a {
    color: var(--primary-color-4);
}
.header__area-menubar-center-menu.four
    ul
    li.menu-item-has-children:hover
    > a::before {
    color: var(--primary-color-4);
}
.header__area-menubar-center-menu.four.header__area-menubar-center-menu
    ul
    li.home
    > a::after {
    background: var(--primary-color-4);
}
.header__area-menubar-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}
.header__area-menubar-right-search-icon i {
    cursor: pointer;
    position: relative;
    z-index: 9;
    display: block;
    color: var(--text-heading-color);
    font-size: 20px;
    font-weight: 400;
}
.header__area-menubar-right-search-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 0;
    background: var(--bg-heading-color);
    z-index: 9999;
    transition: all 0.5s ease-out;
    overflow: hidden;
}
.header__area-menubar-right-search-box form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 55%;
    transition: all 0.5s ease-out;
}
.header__area-menubar-right-search-box input {
    background: var(--bg-white);
    color: var(--text-heading-color);
    border: 0;
}
.header__area-menubar-right-search-box button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    font-size: 22px;
    color: var(--primary-color-1);
    padding: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.header__area-menubar-right-search-box.active {
    height: 100%;
    top: 0;
}
.header__area-menubar-right-search-box.active.header__area-menubar-right-search-box
    form {
    transform: translate(-50%, -50%) scale(1);
}
.header__area-menubar-right-search-box-icon {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 22px;
    color: var(--text-white);
    cursor: pointer;
    transform: rotate(0deg);
}
.header__area-menubar-right-search-box-icon:hover {
    animation: rotate 0.4s ease 0s;
}
.header__area-menubar-right-search-box-icon i {
    cursor: pointer;
    position: relative;
    z-index: 9;
}
.header__area-menubar-right-search-box-icon i::before {
    display: block;
}
.header__area-menubar-right-search.two
    .header__area-menubar-right-search-box
    button {
    color: var(--primary-color-2);
}
.header__area-menubar-right-search.three
    .header__area-menubar-right-search-box
    button {
    color: var(--primary-color-3);
}
.header__area-menubar-right-search.four
    .header__area-menubar-right-search-box
    button {
    color: var(--primary-color-4);
}
.header__area-menubar-right-contact {
    display: flex;
    align-items: center;
    gap: 15px;
}
.header__area-menubar-right-contact-icon i {
    position: relative;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--primary-color-4);
    font-size: 25px;
    animation: icon-animation 2s ease-in-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header__area-menubar-right-contact-icon i::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    border-radius: 50%;
    height: 50px;
    background: var(--primary-color-4);
    z-index: -1;
    opacity: 0.1;
}
.header__area-menubar-right-contact-info span {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: block;
}
.header__area-menubar-right-contact-info h6 a {
    transition: 0.4s;
}
.header__area-menubar-right-contact-info h6 a:hover {
    color: var(--primary-color-4);
}
.header__area-menubar-right-contact.three {
    padding: 0;
    padding-left: 30px;
    border-left: 1px solid var(--border-color-1);
}
.header__area-menubar-right-contact.three
    .header__area-menubar-right-contact-icon
    i {
    color: var(--primary-color-3);
}
.header__area-menubar-right-contact.three
    .header__area-menubar-right-contact-icon
    i::after {
    background: var(--primary-color-3);
}
.header__area-menubar-right-contact.three
    .header__area-menubar-right-contact-info
    h6
    a:hover {
    color: var(--primary-color-3);
}
.header__area-menubar-right-btn .btn-two {
    padding: 15px 42px;
}
.header__area-menubar-right-btn.four .btn-one {
    background: var(--primary-color-4);
}
.header__area-menubar-right-sidebar {
    padding-left: 40px;
    border-left: 1px solid var(--border-color-1);
}
.header__area-menubar-right-sidebar-popup {
    position: fixed;
    width: 460px;
    height: 100%;
    right: 0;
    overflow: auto;
    transform: translateX(100%);
    top: 0;
    background: var(--bg-heading-color);
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
    transition: transform 0.5s ease 0.4s;
    padding: 100px 40px;
    scrollbar-width: none;
}
.header__area-menubar-right-sidebar-popup::-webkit-scrollbar {
    display: none;
}
.header__area-menubar-right-sidebar-popup.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
    right: 0;
}
.header__area-menubar-right-sidebar-popup-icon i::before {
    font-size: 25px;
    color: var(--text-heading-color);
    line-height: 2;
    cursor: pointer;
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn {
    position: absolute;
    top: 40px;
    right: 40px;
    transform: rotate(0);
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn i {
    background: var(--primary-color-1);
    width: 40px;
    color: var(--text-white);
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    display: block;
}
.header__area-menubar-right-sidebar-popup .sidebar-close-btn:hover {
    animation: rotate 0.4s ease 0s;
}
.header__area-menubar-right-sidebar-popup-logo {
    margin-bottom: 30px;
}
.header__area-menubar-right-sidebar-popup-logo a {
    display: inline-block;
}
.header__area-menubar-right-sidebar-popup-logo a img {
    max-width: 160px;
}
.header__area-menubar-right-sidebar-popup p {
    color: var(--color-2);
}
.header__area-menubar-right-sidebar-popup-contact {
    margin: 40px 0;
    padding: 40px 0;
    border-top: 1px solid var(--color-10);
    border-bottom: 1px solid var(--color-10);
}
.header__area-menubar-right-sidebar-popup-contact-item {
    display: flex;
    margin-bottom: 25px;
    gap: 25px;
}
.header__area-menubar-right-sidebar-popup-contact-item-icon {
    margin-top: 8px;
    width: 30px;
}
.header__area-menubar-right-sidebar-popup-contact-item-icon i {
    color: var(--primary-color-1);
    font-size: 30px;
}
.header__area-menubar-right-sidebar-popup-contact-item-content span {
    color: var(--color-2);
    display: inline-block;
    margin-bottom: 4px;
}
.header__area-menubar-right-sidebar-popup-contact-item-content h6 {
    max-width: 240px;
}
.header__area-menubar-right-sidebar-popup-contact-item-content h6 a {
    transition: all 0.4s ease-out;
    color: var(--text-white);
}
.header__area-menubar-right-sidebar-popup-contact-item-content h6 a:hover {
    color: var(--primary-color-1);
}
.header__area-menubar-right-sidebar-popup-contact-item:last-child {
    margin: 0;
}
.header__area-menubar-right-sidebar-popup-contact h4 {
    color: var(--text-white);
}
.header__area-menubar-right-sidebar-popup-social ul {
    padding: 0;
    margin: 0;
}
.header__area-menubar-right-sidebar-popup-social ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
}
.header__area-menubar-right-sidebar-popup-social ul li:last-child {
    margin: 0;
}
.header__area-menubar-right-sidebar-popup-social ul li a i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    color: var(--text-white);
    transition: all 0.4s ease-out;
    background: var(--color-10);
}
.header__area-menubar-right-sidebar-popup-social ul li a i:hover {
    background: var(--primary-color-1);
}
.header__area-menubar-right-sidebar-popup.two.header__area-menubar-right-sidebar-popup
    .sidebar-close-btn
    i {
    background: var(--primary-color-2);
}
.header__area-menubar-right-sidebar-popup.two
    .header__area-menubar-right-sidebar-popup-contact-item-icon
    i {
    color: var(--primary-color-2);
}
.header__area-menubar-right-sidebar-popup.two
    .header__area-menubar-right-sidebar-popup-contact-item-content
    h6
    a:hover {
    color: var(--primary-color-2);
}
.header__area-menubar-right-sidebar-popup.two
    .header__area-menubar-right-sidebar-popup-social
    ul
    li
    a
    i:hover {
    background: var(--primary-color-2);
}
.header__area-menubar-right-sidebar-popup.three.header__area-menubar-right-sidebar-popup
    .sidebar-close-btn
    i {
    background: var(--primary-color-3);
}
.header__area-menubar-right-sidebar-popup.three
    .header__area-menubar-right-sidebar-popup-contact-item-icon
    i {
    color: var(--primary-color-3);
}
.header__area-menubar-right-sidebar-popup.three
    .header__area-menubar-right-sidebar-popup-contact-item-content
    h6
    a:hover {
    color: var(--primary-color-3);
}
.header__area-menubar-right-sidebar-popup.three
    .header__area-menubar-right-sidebar-popup-social
    ul
    li
    a
    i:hover {
    background: var(--primary-color-3);
}
.header__area-menubar-right-sidebar-popup.four.header__area-menubar-right-sidebar-popup
    .sidebar-close-btn
    i {
    background: var(--primary-color-4);
}
.header__area-menubar-right-sidebar-popup.four
    .header__area-menubar-right-sidebar-popup-contact-item-icon
    i {
    color: var(--primary-color-4);
}
.header__area-menubar-right-sidebar-popup.four
    .header__area-menubar-right-sidebar-popup-contact-item-content
    h6
    a:hover {
    color: var(--primary-color-4);
}
.header__area-menubar-right-sidebar-popup.four
    .header__area-menubar-right-sidebar-popup-social
    ul
    li
    a
    i:hover {
    background: var(--primary-color-4);
}
.header__area-menubar-right.three {
    gap: 30px;
    justify-content: end;
    padding-right: 24px;
}
.header__area-menubar-right.three .header__area-menubar-right-sidebar {
    padding: 0;
    border: 0;
}
.header__area-menubar-right-responsive-menu i {
    font-size: 25px;
    color: var(--text-heading-color);
    cursor: pointer;
    height: 25px;
    display: block;
}
.header__area.one {
    background: #fff8f8;
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

/*==========================================================================
* Menu Bar Sticky CSS
==========================================================================*/
.header__sticky-sticky-menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background: var(--bg-white);
    box-shadow: var(--box-shadow-1);
    animation: header_sticky 1.1s;
    display: block;
}
@keyframes header_sticky {
    0% {
        top: -250px;
    }
    100% {
        top: 0;
    }
}

.header__sticky.header__sticky-sticky-menu .header__area {
    background: var(--bg-heading-color);
}

.top__header.header__sticky.three.header__sticky-sticky-menu {
    background: transparent;
}
.top__header.header__sticky.three.header__sticky-sticky-menu
    .custom__container {
    max-width: 100%;
    padding: 0;
}

/*==========================================================================
* Menu Sidebar CSS
==========================================================================*/
.sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    transition: all 0.8s ease-out;
    visibility: hidden;
    z-index: 99999;
    background: rgba(24, 24, 24, 0.6);
}
.sidebar-overlay.show {
    visibility: visible;
    width: 100%;
}

/*==========================================================================
* Responsive Main Menu Css
==========================================================================*/
.responsive-menu {
    width: 100%;
    opacity: 0;
    transition: transform 0.4s ease 0s, opacity 0.4s ease 0s;
    transform: translateY(20px);
    margin-top: 50px;
    position: relative;
}

.mean-container a.meanmenu-reveal {
    display: none !important;
}
.mean-container .mean-nav {
    background: var(--bg-heading-color);
    margin: 0;
}
.mean-container .mean-nav ul li a {
    transition: 0.4s;
}
.mean-container .mean-nav ul li a.mean-expand {
    background: var(--color-10);
    border-color: var(--color-18) !important;
    font-size: 0px !important;
    padding: 0 !important;
    width: 63px;
    height: 62px;
}
.mean-container .mean-nav ul li a.mean-expand::before {
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    position: absolute;
    color: var(--text-white);
    left: 50%;
    font-size: 18px;
    top: 50%;
    transform: translate(-50%, -50%);
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked::before {
    content: "\f068";
}
.mean-container .mean-nav ul li a.mean-expand:hover {
    background: var(--primary-color-1);
    color: var(--text-white);
}
.mean-container .mean-nav ul li a:hover {
    color: var(--primary-color-1);
    background: none;
}
.mean-container .mean-nav .sub-menu li a {
    font-size: 20px;
    padding: 6px 0 6px 20px;
    color: var(--color-2);
}
.mean-container .mean-nav .sub-menu li a.mean-expand {
    background: var(--color-10);
    color: var(--text-white);
    height: 48px;
}
.mean-container .mean-nav .sub-menu li a.mean-expand:hover {
    background: var(--primary-color-1);
}
.mean-container .mean-nav ul li li li a {
    width: 100%;
    padding-left: 40px !important;
}

.mean-container .mean-nav > ul {
    display: block !important;
}

.mean-container .mean-bar {
    padding: 0;
    border-bottom: 1px solid var(--color-18);
    overflow: hidden;
}

.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li li a {
    color: var(--text-white);
    font-size: 26px;
    line-height: 36px;
    font-family: var(--heading-font);
    font-weight: 500;
    text-transform: capitalize;
    border-top: 1px solid var(--color-10);
    opacity: 1;
    padding: 13px 0;
    width: 100%;
}
.mean-container .mean-nav ul li a:hover,
.mean-container .mean-nav ul li li a:hover {
    color: var(--primary-color-1);
}

.header__area-menubar.two .menu-item-has-children ul li a:hover,
.header__area-menubar.two .menu-item-has-children > a:hover,
.header__area-menubar.two .menu-item-has-children ul li li a:hover {
    color: var(--primary-color-2);
}

.header__area-menubar.three .menu-item-has-children ul li a:hover,
.header__area-menubar.three .menu-item-has-children > a:hover,
.header__area-menubar.three .menu-item-has-children ul li li a:hover {
    color: var(--primary-color-3);
}

.mean-container .mean-nav ul li a.mean-expand:hover {
    color: var(--text-white);
}

/*==========================================================================
* Responsive Menu Sidebar CSS
==========================================================================*/
.menu__bar {
    display: none;
    padding-left: 30px;
    border-left: 1px solid var(--border-color-1);
}
.menu__bar-popup {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: grid;
    grid-template-columns: 3fr 2fr;
    transition: 0.5s;
}
.menu__bar-popup.show {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 99;
}
.menu__bar-popup.show::before,
.menu__bar-popup.show::after {
    height: 100%;
}
.menu__bar-popup.show .responsive-menu {
    transition: transform 0.7s ease 0.4s, opacity 0.5s ease 0.4s;
    transform: translateY(0);
    opacity: 1;
}
.menu__bar-popup.show .menu__bar-popup-left-social {
    background: var(--bg-heading-color);
}
.menu__bar-popup.show .menu__bar-popup-right-contact {
    transition: transform 2s ease 0.4s, opacity 2s ease 0.4s;
    transform: translateX(0);
    opacity: 1;
}
.menu__bar-popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
    transform: rotate(0);
    z-index: 99;
}
.menu__bar-popup-close:hover {
    animation: rotate 0.4s ease 0s;
}
.menu__bar-popup-close i {
    background: var(--primary-color-1);
    width: 40px;
    color: var(--text-white);
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    display: block;
}
.menu__bar-popup-left {
    position: relative;
    padding: 40px 0 0 40px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--bg-heading-color);
}
.menu__bar-popup-left-logo > a img {
    max-width: 160px;
}
.menu__bar-popup-left-social {
    padding: 40px 0;
    position: sticky;
    bottom: 0;
    width: 100%;
    transition: 1s;
}
.menu__bar-popup-left-social h6 {
    color: var(--text-white);
    border-bottom: 1px solid var(--color-10);
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.menu__bar-popup-left-social ul {
    padding: 0;
    margin: 0;
}
.menu__bar-popup-left-social ul li {
    list-style: none;
    display: inline-block;
    margin-right: 15px;
}
.menu__bar-popup-left-social ul li a {
    color: var(--color-2);
    transition: 0.4s;
}
.menu__bar-popup-left-social ul li a:hover {
    color: var(--primary-color-1);
}
.menu__bar-popup-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 35px;
    border-left: 1px solid var(--color-10);
    background: var(--color-9);
}
.menu__bar-popup-right-search form {
    position: relative;
}
.menu__bar-popup-right-search form input {
    background: transparent;
    border-color: var(--color-10);
    border-radius: 30px;
}
.menu__bar-popup-right-search form input:focus {
    color: var(--color-2);
}
.menu__bar-popup-right-search form button {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    padding: 0;
    color: var(--color-2);
    width: 60px;
    height: 60px;
    text-align: center;
}
.menu__bar-popup-right-contact {
    opacity: 0;
    transition: transform 0.4s ease 0s, opacity 0.4s ease 0s;
    transform: translateX(20px);
}
.menu__bar-popup-right-contact h4 {
    color: var(--text-white);
    border-bottom: 1px solid var(--color-10);
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.menu__bar-popup-right-contact-item {
    margin-bottom: 10px;
}
.menu__bar-popup-right-contact-item:last-child {
    margin: 0;
}
.menu__bar-popup-right-contact-item-info span {
    color: var(--color-2);
    font-weight: 500;
    font-size: 15px;
}
.menu__bar-popup-right-contact-item-info h6 {
    color: var(--text-white);
    transition: 0.4s;
}
.menu__bar-popup-right-contact-item-info h6:hover {
    color: var(--primary-color-1);
}
.menu__bar-popup.two .menu__bar-popup-close i {
    background: var(--primary-color-2);
}
.menu__bar-popup.two .menu__bar-popup-left-social ul li a:hover {
    color: var(--primary-color-2);
}
.menu__bar-popup.two .menu__bar-popup-right-contact-item-info h6:hover {
    color: var(--primary-color-2);
}
.menu__bar-popup.two .menu__bar-popup-right-search form input:focus {
    border-color: var(--primary-color-2);
}
.menu__bar-popup.two .mean-container .mean-nav ul li a.mean-expand:hover {
    background: var(--primary-color-2);
}
.menu__bar-popup.two .mean-container .mean-nav ul li a:hover {
    color: var(--primary-color-2);
}
.menu__bar-popup.three .menu__bar-popup-close i {
    background: var(--primary-color-3);
}
.menu__bar-popup.three .menu__bar-popup-left-social ul li a:hover {
    color: var(--primary-color-3);
}
.menu__bar-popup.three .menu__bar-popup-right-contact-item-info h6:hover {
    color: var(--primary-color-3);
}
.menu__bar-popup.three .menu__bar-popup-right-search form input:focus {
    border-color: var(--primary-color-3);
}
.menu__bar-popup.three .mean-container .mean-nav ul li a.mean-expand:hover {
    background: var(--primary-color-3);
}
.menu__bar-popup.three .mean-container .mean-nav ul li a:hover {
    color: var(--primary-color-3);
}
.menu__bar-popup.four .menu__bar-popup-close i {
    background: var(--primary-color-4);
}
.menu__bar-popup.four .menu__bar-popup-left-social ul li a:hover {
    color: var(--primary-color-4);
}
.menu__bar-popup.four .menu__bar-popup-right-contact-item-info h6:hover {
    color: var(--primary-color-4);
}
.menu__bar-popup.four .menu__bar-popup-right-search form input:focus {
    border-color: var(--primary-color-4);
}
.menu__bar-popup.four .mean-container .mean-nav ul li a.mean-expand:hover {
    background: var(--primary-color-4);
}
.menu__bar-popup.four .mean-container .mean-nav ul li a:hover {
    color: var(--primary-color-4);
}

@media (max-width: 1550px) {
    .header__area-menubar-center-menu.two {
        margin-left: 0;
    }
    .header__area-menubar-center-menu
        ul
        li:nth-child(n + 4)
        .sub-menu
        li
        .sub-menu {
        right: 100%;
        left: initial;
    }
}
/* @media (max-width: 1450px) {
  .header__area-menubar-right-btn {
    display: none;
  }
} */
@media (max-width: 1399px) {
    .header__area-menubar-center-menu ul li {
        margin: 0 20px;
        padding-right: 10px;
    }
}
/* @media (max-width: 1299px) {
  .header__area-menubar-right-btn {
    display: none;
  }
} */
@media (max-width: 1199px) {
    .header__area-menubar-left-logo.three {
        width: 220px;
    }
    .header__area-menubar-center-menu.three {
        margin-left: 0;
    }
    .header__area-menubar-right-contact,
    .header__area-menubar-right-contact.three {
        display: none;
    }
}
@media (max-width: 1050px) {
    .header__area {
        padding: 14px 20px;
    }
    .header__area-menubar-right-sidebar {
        display: none;
    }
    .menu__bar {
        display: block;
    }
}
@media (max-width: 667px) {
    .menu__bar-popup {
        grid-template-columns: initial;
    }
    .menu__bar-popup::before {
        display: none;
    }
    .menu__bar-popup-left {
        padding: 30px 20px 0 20px;
    }
    .menu__bar-popup-right {
        display: none;
    }
}
@media (max-width: 614px) {
    .header__area {
        padding: 14px 0;
    }
    .header__area-menubar-right-search-box form {
        width: 90%;
    }
    .header__area-menubar-right {
        gap: 20px;
    }
}
@media (max-width: 460px) {
    .mean-container .mean-nav ul li a,
    .mean-container .mean-nav ul li li a {
        font-size: 20px;
        line-height: 30px;
        padding: 12px 0;
    }
    .mean-container .mean-nav ul li a.mean-expand {
        width: 54px;
        height: 54px;
    }
    .mean-container .mean-nav .sub-menu li a {
        font-size: 17px;
    }
    .mean-container .mean-nav .sub-menu li a.mean-expand {
        height: 42px;
    }
    .menu__bar-popup-left-social {
        padding: 30px 0;
    }
    .menu__bar-popup-left-social h6 {
        font-size: 24px;
        line-height: 34px;
    }
    .menu__bar {
        padding-left: 0;
        border: 0;
    }
    .menu__bar-popup-close {
        top: 30px;
        right: 20px;
    }
    .header__area.two {
        padding: 8px 0px;
    }
    .header__area-menubar-left-logo.three {
        width: 180px;
        padding: 25px 0 15px 12px;
    }
    .header__area-menubar-left-logo.three a img {
        max-width: 160px;
    }
    .header__area-menubar-right.three {
        padding-right: 12px;
    }
}
/*==========================================================================
* Menu Demo CSS
==========================================================================*/
.header__area-menubar-center-menu ul li.home {
    position: static;
}
.header__area-menubar-center-menu ul li.home::after {
    display: none;
}
.header__area-menubar-center-menu ul li.home > a::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1);
    bottom: -38px;
    transform: skew(-35deg);
    height: 4px;
    background: var(--primary-color-1);
    z-index: 1;
}
.header__area-menubar-center-menu ul li.home:hover a::after {
    width: 130%;
}
.header__area-menubar-center-menu ul li.menus {
    display: none;
}
.header__area-menubar-center-menu ul li .sub-menu.home-area {
    border-top: 1px solid var(--border-color-1);
    left: 0;
    right: 0;
    top: 100px;
    width: 100%;
    padding: 40px 50px 30px 50px;
    margin: 0 auto;
    z-index: 9;
}
.header__area-menubar-center-menu ul li .sub-menu.home-area li {
    padding: 0;
}
.header__area-menubar-center-menu ul li .sub-menu.home-area li .home-area-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb {
    position: relative;
    z-index: 1;
    border: 1px solid var(--border-color-1);
    border-bottom: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    opacity: 0;
    transition: 0.4s;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb
    img {
    width: 100%;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb
    ul {
    display: flex;
    justify-content: center;
    gap: 15px;
    position: absolute;
    z-index: 10;
    bottom: 110px;
    width: 100%;
    transition: 0.4s;
    opacity: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb
    ul
    li
    a {
    background: var(--text-white);
    color: var(--bg-heading-color);
    padding: 3px 20px;
    border-radius: 15px;
    border: 0;
    transition: 0.4s;
    font-size: 14px;
    line-height: 24px;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb
    ul
    li
    a::after {
    display: none;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    color: var(--text-white);
    background: var(--primary-color-1);
    opacity: 1;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 15px 0 15px;
    z-index: 1;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--border-color-1);
    transition: 0.3s;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-white);
    transition: 0.4s;
    z-index: -1;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content
    .one {
    transition: 0.4s;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content
    .one
    a {
    background: transparent;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content
    .two {
    opacity: 0;
    transition: 0.33s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item-content
    .two
    a {
    display: inline-block;
    transition: 0.4s;
    background: var(--primary-color-1);
    color: var(--text-white);
    border-radius: 5px;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-thumb::after {
    opacity: 0.5;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-thumb
    ul {
    opacity: 1;
    bottom: 93px;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content {
    border-color: rgba(141, 141, 141, 0.0745098039);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content::after {
    height: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content::before,
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content
    .one {
    opacity: 0;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content
    .two {
    opacity: 1;
    bottom: 25px;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:hover
    .home-area-box-item-content
    .two
    a {
    padding: 10px 20px;
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(2)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(2)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(3)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(3)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(4)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-4);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(4)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-4);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(6)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(6)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-2);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(7)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(7)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-3);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(8)
    .home-area-box-item-thumb
    ul
    li
    a:hover {
    background: var(--primary-color-4);
}
.header__area-menubar-center-menu
    ul
    li
    .sub-menu.home-area
    li
    .home-area-box-item:nth-child(8)
    .home-area-box-item-content
    .two
    a {
    background: var(--primary-color-4);
}

@media (max-width: 1499px) {
    .header__area-menubar-center-menu ul li .sub-menu.home-area {
        padding: 30px;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box {
        gap: 25px;
    }
}
@media (max-width: 1399px) {
    .header__area-menubar-center-menu ul li .sub-menu.home-area {
        padding: 30px 15px;
    }
    .header__area-menubar-center-menu ul li .sub-menu.home-area li {
        padding: 0;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box {
        gap: 15px;
        grid-row-gap: 30px;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box-item-thumb
        ul {
        bottom: 85px;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box-item-content {
        padding: 15px 0 0 0;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box-item-content
        .two
        a {
        font-size: 13px;
    }
    .header__area-menubar-center-menu
        ul
        li
        .sub-menu.home-area
        li
        .home-area-box-item:hover
        .home-area-box-item-content
        .two
        a {
        padding: 6px 9px;
    }
}
@media (max-width: 1050px) {
    li.menu-item-has-children.home {
        display: none;
    }
    li.menu-item-has-children.menus {
        display: block;
    }
}
/*==========================================================================
* Banner One CSS
==========================================================================*/
.banner__four,
.banner__three,
.banner__two,
.banner__one {
    position: relative;
    z-index: 1;
}
.banner__four-content,
.banner__three-content,
.banner__two-content,
.banner__one-content {
    position: relative;
    z-index: 1;
}
.banner__four-content > span,
.banner__three-content > span,
.banner__two-content > span,
.banner__one-content > span {
    color: var(--primary-color-4);
    font-weight: 500;
    margin-bottom: 15px;
    display: inline-block;
    padding: 3px 15px;
    padding-left: 30px;
    position: relative;
    z-index: 1;
    font-family: var(--heading-font);
}
.banner__four-content > span::before,
.banner__three-content > span::before,
.banner__two-content > span::before,
.banner__one-content > span::before {
    position: absolute;
    content: "";
    left: 15px;
    top: 14px;
    background: var(--primary-color-4);
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.banner__four-content > span::after,
.banner__three-content > span::after,
.banner__two-content > span::after,
.banner__one-content > span::after {
    content: "";
    position: absolute;
    background: var(--primary-color-4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.15;
    z-index: -1;
    border-radius: 20px;
}
.banner__four-content-button,
.banner__three-content-button,
.banner__two-content-button,
.banner__one-content-button {
    display: flex;
    gap: 35px;
}
.banner__four-content-button-video,
.banner__three-content-button-video,
.banner__two-content-button-video,
.banner__one-content-button-video {
    position: relative;
}
.banner__four-content-button-video::after,
.banner__four-content-button-video::before,
.banner__three-content-button-video::after,
.banner__three-content-button-video::before,
.banner__two-content-button-video::after,
.banner__two-content-button-video::before,
.banner__one-content-button-video::after,
.banner__one-content-button-video::before {
    background: var(--primary-color-4);
}
.banner__four-content-button-video a,
.banner__three-content-button-video a,
.banner__two-content-button-video a,
.banner__one-content-button-video a {
    position: relative;
    z-index: 9;
}
.banner__four-content-button-video i,
.banner__three-content-button-video i,
.banner__two-content-button-video i,
.banner__one-content-button-video i {
    background: var(--primary-color-4);
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    color: var(--text-white);
}

.banner__one-content {
    padding: 140px 0 250px 0;
}
.banner__one-content h1 {
    font-size: 80px;
    line-height: 85px;
    text-transform: capitalize;
    font-weight: 600;
    max-width: 600px;
    margin-bottom: 50px;
    color: var(--text-white);
}
.banner__one-content-button .btn-one {
    background: var(--primary-color-4);
}
.banner__one-shape-one {
    position: absolute;
    left: 3%;
    top: 15%;
    z-index: 1;
    animation: rollIn 10s infinite linear;
}
.banner__one-shape-two {
    position: absolute;
    left: 0;
    bottom: 0;
}
.banner__one-shape-three {
    position: absolute;
    top: 0;
    right: 0;
}
.banner__one-shape-four {
    position: absolute;
    top: 60%;
    left: 45%;
    animation: upDown 6s linear infinite;
}
.banner__one-arrow {
    top: 46%;
}
.banner__one-arrow-next i,
.banner__one-arrow-prev i {
    opacity: 0.4;
}
.banner__one-arrow-next i:hover,
.banner__one-arrow-prev i:hover {
    background: var(--primary-color-4);
}

.banner__two-arrow,
.banner__one-arrow {
    position: absolute;
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 10px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 99;
    display: grid;
    justify-content: end;
    width: 100%;
}
.banner__two-arrow .swiper-button-next,
.banner__two-arrow .swiper-button-prev,
.banner__one-arrow .swiper-button-next,
.banner__one-arrow .swiper-button-prev {
    position: static;
}
.banner__two-arrow-next,
.banner__two-arrow-prev,
.banner__one-arrow-next,
.banner__one-arrow-prev {
    display: inline-block;
}
.banner__two-arrow-next i,
.banner__two-arrow-prev i,
.banner__one-arrow-next i,
.banner__one-arrow-prev i {
    font-size: 22px;
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--bg-white);
    color: var(--text-heading-color);
    transition: 0.4s;
    opacity: 0.3;
}
.banner__two-arrow-next i:hover,
.banner__two-arrow-prev i:hover,
.banner__one-arrow-next i:hover,
.banner__one-arrow-prev i:hover {
    opacity: 1;
    background: var(--primary-color-4);
    color: var(--text-white);
}
.banner__two-arrow-next,
.banner__one-arrow-next {
    margin: 30px 35px 0 0;
}
.banner__two-image,
.banner__one-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*==========================================================================
* Banner Two CSS
==========================================================================*/
.banner__two {
    overflow: hidden;
}
.banner__two-bg {
    background: #f9fbff;
}
.banner__two-content {
    padding: 190px 0;
    z-index: 99;
}
.banner__two-content > span {
    color: var(--primary-color-2);
}
.banner__two-content > span::before,
.banner__two-content > span::after {
    background: var(--primary-color-2);
}
.banner__two-content h1 {
    font-size: 90px;
    line-height: 100px;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.banner__two-content p {
    font-weight: 500;
    max-width: 500px;
    margin-bottom: 45px;
}
.banner__two-content-button-video::after,
.banner__two-content-button-video::before {
    background: var(--primary-color-2);
    border-color: var(--primary-color-2);
}
.banner__two-content-button-video i {
    background: var(--primary-color-2);
}
.banner__two-right {
    position: absolute;
    bottom: 0;
}
.banner__two-shape-one {
    position: absolute;
    left: 50px;
    bottom: 60px;
    animation: rollIn 5s infinite linear;
    z-index: 2;
}
.banner__two-shape-two {
    position: absolute;
    top: 70px;
    right: 50px;
    z-index: 2;
}
.banner__two-shape-three {
    position: absolute;
    bottom: 80px;
    right: 70px;
    z-index: 2;
    animation: upDown 7s linear infinite;
}
.banner__two-shape-four {
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 2;
    animation: upDown 10s linear infinite;
}
.banner__two-arrow-next i,
.banner__two-arrow-prev i {
    background: var(--bg-white);
    transition: 0.4s;
    opacity: 1;
    box-shadow: var(--box-shadow-1);
}
.banner__two-arrow-next i:hover,
.banner__two-arrow-prev i:hover {
    background: var(--primary-color-2);
}

/*==========================================================================
* Banner Three CSS
==========================================================================*/
.banner__three-image {
    background-position: right;
    background-size: initial;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}
.banner__three-shape {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    max-width: 50%;
}
.banner__three-content {
    padding: 326px 0 175px 0;
}
.banner__three-content > span {
    color: var(--text-white);
}
.banner__three-content > span::before,
.banner__three-content > span::after {
    background: var(--primary-color-3);
}
.banner__three-content > span::after {
    opacity: 0.4;
}
.banner__three-content h1 {
    font-size: 80px;
    line-height: 90px;
    max-width: 600px;
    margin-bottom: 35px;
    color: var(--text-white);
}
.banner__three-content p {
    color: var(--text-white);
    font-weight: 500;
    max-width: 560px;
    margin-bottom: 50px;
}
.banner__three-content-button {
    margin-bottom: 40px;
}
.banner__three-content-button-video::after,
.banner__three-content-button-video::before {
    background: var(--primary-color-3);
    border-color: var(--primary-color-3);
}
.banner__three-content-button-video i {
    background: var(--primary-color-3);
}
.banner__three-content-button .btn-one {
    background: var(--primary-color-3);
}
.banner__three-content-button .btn-one::before,
.banner__three-content-button .btn-one::after {
    background: var(--bg-white);
}
.banner__three-content-button .btn-one:hover {
    color: var(--text-heading-color);
}
.banner__three-content .banner-pagination {
    font-size: 40px;
}
.banner__three-content .banner-pagination .swiper-pagination-current {
    color: var(--primary-color-3);
    font-size: 30px;
    position: relative;
    top: -10px;
}
.banner__three-content .banner-pagination span {
    font-size: 20px;
    line-height: 30px;
    font-family: var(--heading-font);
}

/*==========================================================================
* Banner Four CSS
==========================================================================*/
.banner__four {
    overflow: hidden;
}
.banner__four::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
}
.banner__four-image {
    background-position: right;
    background-repeat: no-repeat;
    background-size: initial;
}
.banner__four-content {
    padding: 200px 0;
}
.banner__four-content > span {
    color: var(--primary-color-1);
}
.banner__four-content > span::before {
    background: var(--primary-color-1);
}
.banner__four-content > span::after {
    background: var(--primary-color-1);
}
.banner__four-content h1 {
    max-width: 480px;
}
.banner__four-content-button {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 30px;
}
.banner__four-content-button .btn-one {
    background: var(--primary-color-1);
}
.banner__four-content-button ul {
    padding: 0;
    margin: 0;
}
.banner__four-content-button ul li {
    list-style: none;
    display: inline-block;
    margin-left: -35px;
}
.banner__four-content-button ul li img {
    max-width: 60px;
    border-radius: 50%;
    border: 5px solid var(--bg-white);
}
.banner__four-content-button ul li:first-child {
    margin: 0;
}
.banner__four-content-button ul li:first-child img {
    border-color: var(--primary-color-1);
}
.banner__four-content-button-customer {
    display: flex;
    align-items: center;
    gap: 15px;
}
.banner__four-content-button-customer h6 {
    font-weight: 500;
    font-family: var(--body-font);
}
.banner__four-content-button-customer h6 i {
    color: #ffc600;
}
.banner__four-shape-three,
.banner__four-shape-one {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.banner__four-shape-two {
    position: absolute;
    left: 90px;
    bottom: 70px;
    animation: rollIn 10s infinite linear;
    z-index: 1;
}
.banner__four-shape-three {
    left: 40%;
    top: 50%;
}

@media (max-width: 1599px) {
    .banner__one-shape-one {
        top: 7%;
    }
    .banner__one-shape-four {
        left: 40%;
    }
    .banner__two-arrow-prev {
        left: 35px;
    }
    .banner__two-arrow-next {
        right: 65px;
    }
    .banner__four::after {
        left: -270px;
    }
}
@media (max-width: 1399px) {
    .banner__two-content {
        padding: 160px 0;
    }
    .banner__two-content h1 {
        font-size: 80px;
        line-height: 90px;
    }
    .banner__two-right {
        right: 30px;
        max-width: 43%;
    }
    .banner__four::after {
        left: -270px;
        width: 110%;
    }
}
@media (max-width: 1199px) {
    .banner__three-shape {
        max-width: 65%;
    }
    .banner__two-arrow-prev {
        left: 12px;
    }
    .banner__two-arrow-next {
        right: 55px;
    }
    .banner__four::after {
        left: -350px;
        width: 130%;
    }
    .banner__four-content {
        padding: 180px 0;
    }
    .banner__four-shape-one {
        max-width: 150px;
    }
    .banner__four-shape-three {
        left: 50%;
    }
}
@media (max-width: 1050px) {
    .banner__three-shape {
        width: 100%;
        max-width: 100%;
    }
    .banner__one-shape-four {
        left: 60%;
        top: 70%;
    }
}
@media (max-width: 991px) {
    .banner__four::after {
        left: 0;
        width: 100%;
    }
    .banner__four-shape-three {
        left: 70%;
    }
    .banner__three-content {
        padding: 326px 0 130px 0;
    }
    .banner__two-right {
        position: relative;
        max-width: initial;
        right: 0;
        top: 0;
        text-align: center;
    }
    .banner__two-content {
        text-align: center;
        padding-bottom: 50px;
    }
    .banner__two-content p {
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .banner__two-content-button {
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .banner__one-content {
        padding: 140px 0 220px 0;
    }
    .banner__one-content h1 {
        font-size: 72px;
        line-height: 82px;
        max-width: 500px;
    }
    .banner__two-content {
        padding: 150px 0;
    }
    .banner__two-right {
        display: none;
    }
    .banner__two-shape-one {
        left: -45px;
    }
    .banner__two-shape-four {
        max-width: 150px;
    }
    .banner__two-shape-three {
        right: -20px;
        bottom: 80px;
    }
    .banner__two-content h1,
    .banner__three-content h1 {
        font-size: 68px;
        line-height: 78px;
    }
    .banner__three-content p,
    .banner__one-content p {
        margin-bottom: 40px;
    }
}
@media (max-width: 640px) {
    .banner__one-content {
        padding-top: 140px;
    }
    .banner__two-arrow,
    .banner__one-arrow {
        display: none;
    }
    .banner__four-content-button {
        display: inline-grid;
        margin-top: 35px;
    }
}
@media (max-width: 614px) {
    .banner__three-content {
        padding: 370px 0 130px 0;
    }
}
@media (max-width: 575px) {
    .banner__four-content {
        padding: 120px 0;
    }
    .banner__four-shape-one {
        max-width: 100px;
    }
    .banner__four-shape-three {
        left: 60%;
    }
    .banner__four-shape-two {
        left: 80px;
        bottom: 35px;
        max-width: 60px;
    }
    .banner__two-content {
        padding: 135px 0;
    }
    .banner__two-shape-one {
        left: 0;
        bottom: 30px;
        max-width: 90px;
    }
    .banner__two-shape-two {
        right: 30px;
        max-width: 120px;
    }
    .banner__two-shape-four {
        left: 0;
        max-width: 120px;
    }
    .banner__three-content {
        padding: 340px 0 120px 0;
    }
}
@media (max-width: 530px) {
    .banner__one-content h1 {
        font-size: 62px;
        line-height: 72px;
        max-width: 430px;
    }
    .banner__one-shape-two,
    .banner__one-shape-three {
        max-width: 40%;
    }
    .banner__one-shape-one,
    .banner__one-shape-four {
        max-width: 55px;
    }
    .banner__two-content h1 {
        font-size: 54px;
        line-height: 64px;
    }
    .banner__three-content h1 {
        font-size: 56px;
        line-height: 66px;
    }
    .banner__four-content h1 {
        font-size: 72px;
        line-height: 82px;
    }
}
@media (max-width: 430px) {
    .banner__two-content h1 {
        font-size: 44px;
        line-height: 54px;
    }
    .banner__one-content h1 {
        font-size: 49px;
        line-height: 59px;
        max-width: 300px;
    }
    .banner__three-content h1 {
        font-size: 46px;
        line-height: 56px;
    }
    .banner__four-content h1 {
        font-size: 62px;
        line-height: 72px;
    }
}
@media (max-width: 355px) {
    .banner__four-content-button,
    .banner__three-content-button,
    .banner__two-content-button,
    .banner__one-content-button {
        gap: 20px;
    }
    .banner__two-content h1 {
        font-size: 39px;
        line-height: 49px;
    }
    .banner__three-content h1 {
        font-size: 41px;
        line-height: 51px;
    }
    .banner__four-content h1 {
        font-size: 54px;
        line-height: 64px;
    }
}
/*==========================================================================
* Features One CSS
==========================================================================*/
.features {
    margin-top: -80px;
    position: relative;
    z-index: 1;
}
.features-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 6px;
    overflow: hidden;
}
.features-area-item {
    position: relative;
    padding: 50px 40px;
    background: var(--color-4);
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 25px;
}
.features-area-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.features-area-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background: var(--primary-color-4);
    transition: all 300ms ease;
    z-index: -2;
}
.features-area-item-icon i {
    width: 90px;
    height: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--text-white);
    border-radius: 50%;
    font-size: 60px;
    color: var(--primary-color-4);
}
.features-area-item-content h4 {
    transition: 0.4s;
    margin-bottom: 3px;
}
.features-area-item-content p {
    transition: 0.4s;
    margin: 0;
}
.features-area-item-hover::after {
    height: 100%;
}
.features-area-item-hover h4 {
    color: var(--text-white);
}
.features-area-item-hover p {
    color: var(--text-white);
}
.features-area-item-hover i {
    animation: rotateY 0.5s;
}

/*==========================================================================
* Features Two CSS
==========================================================================*/
.features__two {
    position: relative;
    z-index: 1;
    background: var(--color-4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.features__two::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background: var(--bg-white);
    z-index: -1;
}
.features__two-left-title {
    margin-bottom: 35px;
}
.features__two-left-title h2 {
    margin-bottom: 20px;
}
.features__two-left-title p {
    margin: 0;
}
.features__two-left-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-right: 108px;
}
.features__two-left-bottom-item {
    padding: 30px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 1;
    background: var(--bg-white);
    border-radius: 5px;
}
.features__two-left-bottom-item::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: var(--primary-color-3);
    width: 100%;
    height: 0;
    transition: all 400ms ease;
    z-index: -1;
    border-radius: 5px;
}
.features__two-left-bottom-item-icon {
    margin-bottom: 10px;
    padding: 10px 0 0 10px;
    position: relative;
    z-index: 1;
}
.features__two-left-bottom-item-icon::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 55px;
    height: 55px;
    background: var(--primary-color-3);
    opacity: 0.1;
    border-radius: 50%;
    transition: 0.4s;
}
.features__two-left-bottom-item-icon i {
    font-size: 70px;
    color: var(--primary-color-3);
    transition: 0.4s;
}
.features__two-left-bottom-item h5 {
    transition: 0.4s;
}
.features__two-left-bottom-item:hover::before {
    height: 100%;
}
.features__two-left-bottom-item:hover
    .features__two-left-bottom-item-icon::after {
    background: var(--text-white);
}
.features__two-left-bottom-item:hover i,
.features__two-left-bottom-item:hover h5 {
    color: var(--text-white);
}
.features__two-right {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    z-index: -2;
    max-width: 98%;
    height: 100%;
    width: 100%;
}
.features__two-right img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}
.features__two.services .features__two-left-bottom-item::before {
    background: var(--primary-color-1);
}
.features__two.services .features__two-left-bottom-item-icon i {
    color: var(--primary-color-1);
}
.features__two.services .features__two-left-bottom-item-icon::after {
    background: var(--primary-color-1);
}
.features__two.services
    .features__two-left-bottom-item:hover
    .features__two-left-bottom-item-icon::after {
    background: var(--text-white);
    opacity: 0.3;
}
.features__two.services
    .features__two-left-bottom-item:hover
    .features__two-left-bottom-item-icon
    i {
    color: var(--text-white);
}

@media (max-width: 1770px) {
    .features__two-right {
        max-width: 50%;
    }
}
@media (max-width: 1499px) {
    .features__two-left-title p {
        margin: 0;
        margin-right: 33px;
    }
}
@media (max-width: 1399px) {
    .features-area-item {
        padding: 35px 25px;
    }
}
@media (max-width: 1199px) {
    .features-area-item {
        text-align: center;
        display: grid;
    }
    .features__two-right {
        max-width: 45%;
    }
    .features__two-left-bottom {
        margin-right: 60px;
    }
    .features__two-left-bottom-item {
        z-index: 2;
    }
}
@media (max-width: 1100px) {
    .features__two-right {
        max-width: 45%;
    }
}
@media (max-width: 991px) {
    .features-area {
        grid-template-columns: repeat(2, 1fr);
    }
    .features__two-right {
        position: relative;
        max-width: 100%;
    }
    .features__two-left-bottom {
        margin-right: 0;
    }
}
@media (max-width: 766px) {
    .features-area {
        grid-template-columns: repeat(1, 1fr);
    }
    .features-area-item {
        border-radius: 4px;
    }
}
@media (max-width: 495px) {
    .features__two-left-bottom {
        grid-template-columns: repeat(1, 1fr);
    }
}
/*==========================================================================
* About One CSS
==========================================================================*/
.about__one {
    position: relative;
    z-index: 1;
}
.about__one-left-image {
    position: relative;
    padding-bottom: 45px;
    display: inline-flex;
}
.about__one-left-image img {
    width: 100%;
}
.about__one-left-image-experience {
    position: absolute;
    right: 80px;
    bottom: 0;
    background: var(--primary-color-2);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 45px 35px;
    z-index: 1;
}
.about__one-left-image-experience h2 {
    color: var(--text-white);
}
.about__one-left-image-experience h6 {
    color: var(--text-white);
    max-width: 160px;
    font-weight: 600;
}
.about__one-right {
    margin-left: 90px;
}
.about__one-right-title {
    margin-bottom: 30px;
}
.about__one-right-title h2 {
    margin-bottom: 20px;
}
.about__one-right-title p {
    max-width: 500px;
    margin: 0;
}
.about__one-right-bottom {
    display: flex;
    justify-content: space-between;
}
.about__one-right-bottom-list p {
    color: var(--color-1);
    font-family: var(--heading-font);
    font-weight: 500;
    margin-bottom: 10px;
}
.about__one-right-bottom-list p i {
    color: var(--primary-color-2);
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary-color-2);
    border-radius: 50%;
    margin-right: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: relative;
    top: 2px;
}
.about__one-right-bottom-list .btn-two {
    margin-top: 42px;
}
.about__one-right-bottom-award {
    display: inline-block;
    background: var(--color-5);
    padding: 30px 23px;
    height: 190px;
    text-align: center;
    border-radius: 20px;
    border-top: 4px solid var(--primary-color-2);
}
.about__one-right-bottom-award h6 {
    max-width: 145px;
    margin-top: 5px;
    color: var(--color-1);
}

/*==========================================================================
* About Two CSS
==========================================================================*/
.about__two {
    position: relative;
}
.about__two-shape-one {
    position: absolute;
    right: 0;
    top: -110px;
    z-index: -1;
}
.about__two-shape-two {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.about__two-left {
    margin-right: 65px;
}
.about__two-left-image {
    position: relative;
    padding-bottom: 80px;
    display: flex;
}
.about__two-left-image-two {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 10px solid var(--bg-white);
    filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.08));
}
.about__two-right-title {
    margin-bottom: 30px;
}
.about__two-right-title h2 {
    margin-bottom: 20px;
}
.about__two-right-title p {
    max-width: 470px;
    margin: 0;
}
.about__two-right-bottom {
    display: flex;
    justify-content: space-between;
}
.about__two-right-bottom-list-item {
    display: flex;
    gap: 20px;
}
.about__two-right-bottom-list-item-icon i {
    color: var(--primary-color-4);
    font-size: 60px;
    line-height: 60px;
}
.about__two-right-bottom-list-item-content p {
    max-width: 220px;
    margin: 0;
}
.about__two-right-bottom-founder {
    background: var(--color-4);
    border-top: 4px solid var(--primary-color-4);
    display: inline-block;
    padding: 30px 35px;
    text-align: center;
}
.about__two-right-bottom-founder img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}
.about__two-right-bottom-founder span {
    font-size: 14px;
    line-height: 24px;
}
.about__two-right .btn-one {
    margin-top: 30px;
    background: var(--primary-color-4);
}

/*==========================================================================
* About Three CSS
==========================================================================*/
.about__three {
    position: relative;
    z-index: 1;
    background: var(--bg-white);
}
.about__three-shape {
    position: absolute;
    left: 0;
    top: 170px;
    z-index: -1;
}
.about__three-left {
    position: relative;
    z-index: 1;
    padding-bottom: 70px;
    margin-right: 70px;
}
.about__three-left-shape {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 90%;
    z-index: -1;
    animation: rollIn 40s infinite linear;
}
.about__three-left-image {
    display: inline-flex;
}
.about__three-left-image-experience {
    position: absolute;
    right: 0;
    bottom: 0;
    background: var(--primary-color-3);
    padding: 50px;
    border-radius: 20px;
    z-index: 1;
}
.about__three-left-image-experience h1 {
    margin-bottom: 8px;
    color: var(--text-white);
}
.about__three-left-image-experience h5 {
    color: var(--text-white);
    margin-bottom: 10px;
}
.about__three-left-image-experience p {
    margin: 0;
    max-width: 180px;
    color: var(--color-5);
}
.about__three-right-title {
    margin-bottom: 30px;
}
.about__three-right-title h2 {
    margin-bottom: 20px;
}
.about__three-right-title p {
    max-width: 460px;
    margin: 0;
}
.about__three-right-list-item {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color-1);
}
.about__three-right-list-item-icon {
    position: relative;
    z-index: 1;
    padding: 15px 0 0 15px;
}
.about__three-right-list-item-icon::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: var(--primary-color-3);
    opacity: 0.1;
    border-radius: 50%;
}
.about__three-right-list-item-icon i {
    font-size: 50px;
    line-height: 50px;
    color: var(--primary-color-3);
    display: inline-block;
}
.about__three-right-list-item-content h4 {
    margin-bottom: 10px;
}
.about__three-right-list-item-content p {
    max-width: 420px;
    margin: 0;
}
.about__three-right-list-item:hover .about__three-right-list-item-icon i {
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 4;
}
.about__three-right-list-item:last-child {
    margin: 0;
}

/*==========================================================================
* About Four CSS
==========================================================================*/
.about__five {
    background: var(--bg-white);
    position: relative;
    z-index: 1;
}
.about__five-shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.about__five-left {
    position: relative;
    z-index: 1;
}
.about__five-left-shape {
    position: absolute;
    left: -70px;
    top: 0;
    z-index: -1;
    animation: upDown 5s linear infinite;
    opacity: 0.1;
}
.about__five-left-image {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 25px;
}
.about__five-left-image img {
    width: 100%;
}
.about__five-left-image-right {
    display: grid;
    grid-row-gap: 25px;
}
.about__five-left-image-experience {
    position: absolute;
    left: 40px;
    bottom: 20px;
    background: var(--primary-color-1);
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    padding: 35px 30px;
    z-index: 1;
}
.about__five-left-image-experience h1 {
    font-weight: 500;
    color: var(--text-white);
}
.about__five-left-image-experience h6 {
    color: var(--text-white);
    max-width: 160px;
    font-weight: 400;
}
.about__five-right {
    margin-left: 65px;
}
.about__five-right-title h2 {
    margin-bottom: 20px;
}
.about__five-right-title p {
    max-width: 495px;
    margin: 0;
}
.about__five-right-author {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.about__five-right-author-info span {
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-color-1);
    font-weight: 500;
}
.about__five-right-author-info p {
    margin: 0;
    max-width: 300px;
}
.about__five-right .btn-one {
    margin-top: 50px;
    background: var(--primary-color-1);
}

@media (max-width: 1299px) {
    .about__two-left {
        margin-right: 0;
    }
    .about__two-left-image {
        padding-bottom: 200px;
        padding-right: 70px;
    }
}
@media (max-width: 1199px) {
    .about__one-left {
        margin-right: 10px;
    }
    .about__one-right {
        margin-left: 0;
    }
    .about__one-right-bottom-list .btn-two {
        margin-top: 20px;
    }
    .about__three {
        overflow: hidden;
    }
    .about__three-left {
        padding-bottom: 100px;
        margin-right: 0;
        padding-right: 60px;
    }
    .about__five-right {
        margin-left: 0;
    }
}
@media (max-width: 991px) {
    .about__two-left-image {
        padding-bottom: 50px;
        padding-right: 0;
    }
    .about__two-left-image img {
        max-width: 90%;
    }
    .about__two-left-image-two {
        max-width: 70%;
    }
    .about__two-left-image-two img {
        max-width: 100%;
    }
    .company__two-right .image-overlay {
        display: block;
    }
}
@media (max-width: 550px) {
    .about__two-right-bottom-founder {
        display: none;
    }
}
@media (max-width: 460px) {
    .about__one-right-bottom-award {
        display: none;
    }
    .about__one-left-image-experience {
        right: 0;
        padding: 35px 25px;
    }
    .about__three-left-image-experience {
        padding: 30px;
    }
    .about__five-left-image-experience h1 {
        font-size: 60px;
        line-height: 70px;
    }
    .about__five-left-image-experience {
        left: 20px;
        padding: 30px 20px;
    }
}
/*==========================================================================
* Services One CSS
==========================================================================*/
.services__one {
    background: var(--color-4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 245px;
    position: relative;
    z-index: 1;
}
.services__one-item {
    position: relative;
    z-index: 1;
    background: var(--bg-white);
    border-radius: 6px;
    margin-top: 30px;
}
.services__one-item::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: var(--primary-color-4);
    width: 100%;
    height: 0;
    transition: all 400ms ease;
    border-radius: 6px;
    z-index: -1;
}
.services__one-item-image {
    position: relative;
    padding: 10px 10px 0 10px;
    z-index: 1;
    overflow: hidden;
}
.services__one-item-image > img {
    border-radius: 6px;
}
.services__one-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.113);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.services__one-item-image:hover::before {
    animation: circle 0.9s;
}
.services__one-item-content {
    padding: 22px 25px;
    position: relative;
    z-index: 1;
}
.services__one-item-content-icon {
    position: absolute;
    right: 25px;
    top: -40px;
    background: var(--primary-color-4);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 1;
}
.services__one-item-content-icon::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: scale(0);
    border-radius: 10px;
    background-color: var(--text-white);
    z-index: -1;
    transition: all 300ms ease;
}
.services__one-item-content-icon i {
    color: var(--text-white);
    font-size: 40px;
    transition: 0.4s;
}
.services__one-item-content p {
    position: relative;
    /* padding-bottom: 18px;
  margin-bottom: 17px; */
}
/* .services__one-item-content p::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--text-heading-color);
  opacity: 0.1;
  transition: 0.4s;
} */
.services__one-item-content p,
.services__one-item-content h4 {
    transition: 0.4s;
}
.services__one-item-content > a {
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: var(--text-heading-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 500ms ease;
}
.services__one-item-content > a i {
    position: relative;
    top: 1px;
}
.services__one-item:hover::before {
    height: 100%;
}
.services__one-item:hover .services__one-item-content > a,
.services__one-item:hover .services__one-item-content p,
.services__one-item:hover .services__one-item-content h4 {
    color: var(--text-white);
}
.services__one-item:hover .services__one-item-content p {
    border-color: var(--color-11);
}
.services__one-item:hover .services__one-item-content p::before {
    background: var(--text-white);
    opacity: 0.25;
}
.services__one-item:hover .services__one-item-content-icon i {
    color: var(--primary-color-4);
}
.services__one-item:hover .services__one-item-content-icon::after {
    transform: scale(1);
}
.services__one-btn .btn-one {
    background: var(--primary-color-4);
}

/*==========================================================================
* Services Two CSS
==========================================================================*/
.services__two {
    position: relative;
    z-index: 1;
}
.services__two-title {
    text-align: center;
}
.services__two-item {
    background: var(--bg-white);
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);
    padding: 0 30px 18px 30px;
    text-align: center;
    position: relative;
    z-index: 9;
    margin-top: 46px;
}
.services__two-item::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -16px;
    width: 213px;
    height: 100%;
    background-repeat: no-repeat;
    transform: translateX(-50%);
    z-index: -1;
}
.services__two-item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
    background: var(--bg-heading-color);
    z-index: -2;
    transition: all 400ms ease;
    -webkit-clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
    clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
}
.services__two-item-icon i::before {
    font-size: 50px;
    color: var(--text-white);
}
.services__two-item-content {
    margin-top: 37px;
}
.services__two-item-content h4 {
    margin-bottom: 15px;
}
.services__two-item-content h4 a {
    transition: 0.4s;
}
.services__two-item-content p {
    transition: 0.4s;
}
.services__two-item-btn {
    margin-top: 26px;
    padding-top: 18px;
    position: relative;
    z-index: 1;
}
.services__two-item-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: var(--text-heading-color);
    opacity: 0.1;
    transition: 0.4s;
}
.services__two-item-btn a {
    font-weight: 600;
    transition: 0.4s;
    font-family: var(--heading-font);
    color: var(--text-heading-color);
}
.services__two-item:hover::after {
    -webkit-clip-path: initial;
    clip-path: initial;
    height: 100%;
}
.services__two-item:hover .services__two-item-content p {
    color: var(--text-white);
}
.services__two-item:hover .services__two-item-content h4 a {
    color: var(--text-white);
}
.services__two-item:hover .services__two-item-content h4 a:hover {
    color: var(--primary-color-2);
}
.services__two-item:hover .services__two-item-btn::before {
    background: var(--text-white);
    opacity: 0.2;
}
.services__two-item:hover .services__two-item-btn a {
    color: var(--text-white);
}
.services__two-item:hover .services__two-item-btn a:hover {
    color: var(--primary-color-2);
}
.services__two-shape-one {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.services__two-shape-two {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

/*==========================================================================
* Services Three CSS
==========================================================================*/
.services__three {
    position: relative;
    z-index: 0;
    background: var(--bg-white);
}
.services__three-title {
    text-align: center;
}
.services__three-items {
    margin-top: 30px;
    background: var(--bg-white);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.08);
    border-top: 4px solid var(--primary-color-3);
    border-radius: 0 0 6px 6px;
}
.services__three-items:hover .services__three-item {
    transform: translateY(100%);
}
.services__three-items:hover .two {
    bottom: 0;
}
.services__three-items .two {
    background: var(--bg-heading-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 100%;
    transition: all 500ms ease;
    padding: 35px 40px 40px 40px;
}
.services__three-items .two span {
    position: absolute;
    right: 40px;
    top: 70px;
    font-size: 80px;
    color: var(--text-white);
    opacity: 0.2;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    font-weight: 700;
    font-family: var(--heading-font);
    transform: rotate(-40deg);
}
.services__three-items .two .services__three-item-content p {
    color: var(--text-white);
}
.services__three-items .two .services__three-item-content h4 a {
    color: var(--text-white);
    transition: 0.4s;
}
.services__three-items .two .services__three-item-content h4 a:hover {
    color: var(--primary-color-3);
}
.services__three-items .two .services__three-item-icon {
    margin-bottom: 30px;
    margin-left: 10px;
}
.services__three-items .two .services__three-item-icon::after {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    border: 1px dashed var(--primary-color-3);
    border-radius: 50%;
    display: block;
    top: -10px;
    left: -10px;
    animation: rollIn 10s infinite linear;
}
.services__three-item {
    padding: 35px 40px 40px 40px;
    position: relative;
    z-index: 9;
    transition: 0.5s;
}
.services__three-item span {
    position: absolute;
    right: 40px;
    top: 70px;
    font-size: 80px;
    color: var(--text-heading-color);
    opacity: 0.1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    font-weight: 700;
    font-family: var(--heading-font);
    transform: rotate(-40deg);
}
.services__three-item-icon {
    position: relative;
}
.services__three-item-icon i {
    color: var(--text-white);
    font-size: 60px;
    background: var(--primary-color-3);
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}
.services__three-item-content h4 {
    margin-bottom: 15px;
}
.services__three-item-content h4 a {
    transition: 0.4s;
}
.services__three-item-content p {
    transition: 0.4s;
    margin: 0;
}

/*==========================================================================
* Services Four CSS
==========================================================================*/
.services__four {
    background: var(--bg-heading-color);
}
.services__four-title h2 {
    color: var(--text-white);
}
.services__four-item {
    border-top: 2px solid var(--border-color-3);
    padding: 55px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.services__four-item:last-child {
    border-bottom: 2px solid var(--border-color-3);
}
.services__four-item:before {
    content: "";
    background: var(--bg-white);
    height: 100%;
    width: 0%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.5s;
}
.services__four-item-left {
    display: flex;
    align-items: center;
    gap: 40px;
}
.services__four-item-left i {
    font-size: 80px;
    color: var(--primary-color-1);
}
.services__four-item-left h3 a {
    color: var(--text-white);
    max-width: 184px;
    display: inline-block;
    transition: 0.3s;
}
.services__four-item-left p {
    max-width: 580px;
    margin: 0;
    padding-left: 70px;
    position: relative;
}
.services__four-item-left p::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: var(--border-color-3);
    width: 1px;
    height: 100%;
    transition: 1s;
}
.services__four-item-right a i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    font-size: 20px;
    background: var(--primary-color-1);
    color: var(--text-white);
    transition: 0.4s;
}
.services__four-item:hover .services__four-item-left h3 a {
    color: var(--text-heading-color);
}
.services__four-item:hover .services__four-item-left h3 a:hover {
    color: var(--primary-color-1);
}
.services__four-item:hover .services__four-item-left p::after {
    background: var(--border-color-1);
}
.services__four-item:hover .services__four-item-right a i {
    transform: rotate(45deg);
}
.services__four-item:hover::before {
    animation: bounceRight 2s;
    width: 100%;
}
.services__four-titles {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}
.services__four-titles span {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
}
.services__four-titles span a {
    color: var(--primary-color-1);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%)
        0px 95%/0px 1.7px no-repeat;
    transition: all 600ms ease;
}
.services__four-titles span a:hover {
    background-size: 100% 1.7px;
}

/*==========================================================================
* Services Details CSS
==========================================================================*/
.services__details-left-content h2 {
    margin-bottom: 15px;
}
.services__details-left-content-box {
    background: var(--color-4);
    margin: 30px 0;
    padding: 40px 30px;
    border-left: 3px solid var(--primary-color-1);
    display: block;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: var(--text-heading-color);
}
.services__details-left-content-list {
    margin-top: 30px;
}
.services__details-left-content-list p {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--heading-font);
    color: var(--text-heading-color);
    margin-bottom: 8px;
}
.services__details-left-content-list p i {
    width: 25px;
    height: 25px;
    font-size: 14px;
    border: 1px solid var(--border-color-1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-1);
    border-radius: 50%;
    color: var(--primary-color-1);
}
.services__details-left-content-list p:last-child {
    margin: 0;
}

@media (max-width: 1399px) {
    .services__three-items .two,
    .services__three-item {
        padding: 30px 26px 35px 26px;
    }
}
@media (max-width: 1199px) {
    .services__four-item {
        padding: 50px 30px;
    }
    .services__four-item-left p {
        padding-left: 45px;
    }
}
@media (max-width: 991px) {
    .services__four-item {
        display: block;
    }
    .services__four-item-left {
        display: block;
    }
    .services__four-item-left i {
        margin-bottom: 25px;
        display: block;
    }
    .services__four-item-left h3 a {
        max-width: initial;
    }
    .services__four-item-left p {
        padding: 0;
        margin-top: 20px;
        margin-bottom: 35px;
    }
    .services__four-item-left p::after {
        display: none;
    }
}
@media (max-width: 575px) {
    .services__four-item {
        padding: 32px 22px;
    }
}
/*==========================================================================
* Counter CSS
==========================================================================*/
.counter__area {
    background: var(--primary-color-4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 80px 120px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: -125px;
}
.counter__area-item {
    display: flex;
    gap: 30px;
}
.counter__area-item-icon i {
    color: var(--text-white);
    font-size: 70px;
}
.counter__area-item-info {
    margin-top: -10px;
}
.counter__area-item-info h6,
.counter__area-item-info h2 {
    color: var(--text-white);
}

/*==========================================================================
* Counter Two CSS
==========================================================================*/
.counter__two {
    background: var(--bg-white);
    position: relative;
    z-index: 1;
}
.counter__two-bg {
    border-top: 1px solid var(--border-color-1);
    padding-top: 120px;
    display: flex;
    justify-content: space-between;
}
.counter__two-item {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}
.counter__two-item-icon i {
    color: var(--primary-color-1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    font-size: 45px;
    border-radius: 50%;
    background: var(--color-5);
}
.counter__two-item-content h6 {
    font-weight: 400;
    color: var(--color-2);
}

@media (max-width: 1350px) {
    .counter__area {
        padding: 80px 60px;
    }
}
@media (max-width: 1200px) {
    .counter__area {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 35px;
    }
}
@media (max-width: 1199px) {
    .counter__two-item {
        display: grid;
    }
}
@media (max-width: 991px) {
    .counter__area-item {
        flex-direction: column;
        text-align: center;
        gap: 25px;
    }
    .counter__two-bg {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    .counter__two-item {
        display: flex;
        justify-content: flex-start;
    }
}
@media (max-width: 670px) {
    .counter__area {
        padding: 60px 25px;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 575px) {
    .counter__two-item {
        display: block;
        text-align: center;
    }
    .counter__two-item-icon {
        margin-bottom: 10px;
    }
    .counter__two-item-icon i {
        width: 70px;
        height: 70px;
        font-size: 30px;
    }
}
@media (max-width: 420px) {
    .counter__area {
        grid-template-columns: repeat(1, 1fr);
    }
    .counter__area-item {
        flex-direction: inherit;
        text-align: left;
        justify-content: center;
    }
}
/*==========================================================================
* Benefits CSS
==========================================================================*/
.benefits__area {
    position: relative;
    z-index: 2;
}
.benefits__area-left-image {
    position: relative;
    padding-left: 150px;
    display: inline-flex;
}
.benefits__area-left-image-from {
    position: absolute;
    left: -48px;
    top: 50%;
    background: var(--primary-color-4);
    padding: 40px 60px;
    border-radius: 10px;
    transform: translateY(-50%) rotate(-90deg);
    z-index: 1;
}
.benefits__area-left-image-from h6 {
    color: var(--text-white);
    font-size: 20px;
    line-height: 26px;
    display: inline-block;
    position: relative;
    font-weight: 400;
    z-index: 1;
    padding-right: 80px;
}
.benefits__area-left-image-from h6::before {
    content: "";
    position: absolute;
    top: 13px;
    right: 0;
    width: 65px;
    height: 1px;
    background: var(--text-white);
}
.benefits__area-left-image-from h2 {
    color: var(--text-white);
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
}
.benefits__area-right-title {
    margin-bottom: 30px;
}
.benefits__area-right-title h2 {
    margin-bottom: 20px;
}
.benefits__area-right-title p {
    margin: 0;
}
.benefits__area-right-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.benefits__area-right-list p {
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.benefits__area-right-list p i {
    color: var(--text-white);
    width: 20px;
    height: 20px;
    background: var(--primary-color-4);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: relative;
    top: 1px;
}
.benefits__area-right-bottom {
    border-top: 1px solid var(--border-color-1);
    margin-top: 45px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    gap: 50px;
}
.benefits__area-right-bottom-btn .btn-one {
    background: var(--primary-color-4);
}
.benefits__area-right-bottom-contact {
    display: flex;
    align-items: center;
    gap: 23px;
}
.benefits__area-right-bottom-contact-icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-13);
    border-radius: 50%;
    font-size: 35px;
    color: var(--primary-color-4);
}
.benefits__area-right-bottom-contact-info span {
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}
.benefits__area-right-bottom-contact-info h5 a {
    transition: 0.4s;
}
.benefits__area-right-bottom-contact-info h5 a:hover {
    color: var(--primary-color-4);
}

/*==========================================================================
* Benefits Two CSS
==========================================================================*/
.benefits__two {
    position: relative;
    z-index: 1;
}
.benefits__two-shape {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: -1;
    animation: rollIn 15s linear infinite;
}
.benefits__two-left-image {
    position: relative;
    padding-bottom: 90px;
    z-index: 1;
    display: flex;
}
.benefits__two-left-image-shape {
    position: absolute;
    left: -50px;
    top: 0;
    z-index: -1;
    opacity: 0.11;
    max-width: 80%;
    animation: upDown 5s linear infinite;
}
.benefits__two-left-image-two {
    position: absolute;
    right: 0;
    bottom: 0;
}
.benefits__two-left-image-two img {
    border: 10px solid var(--color-5);
}
.benefits__two-right {
    margin-left: 65px;
}
.benefits__two-right-title h2 {
    margin-bottom: 20px;
}
.benefits__two-right-title p {
    max-width: 400px;
    margin: 0;
}
.benefits__two-right .btn-one {
    background: var(--primary-color-1);
    margin-top: 40px;
}
.benefits__two-right-bottom {
    margin-top: 20px;
    margin-right: 95px;
}
.benefits__two-right-bottom .skill__area-item-bar {
    background: var(--primary-color-1);
}

@media (max-width: 1199px) {
    .benefits__two-right {
        margin-left: 0;
    }
}
@media (max-width: 555px) {
    .benefits__area-left-image-from h2 {
        font-size: 55px;
        line-height: 65px;
    }
    .benefits__area-left-image {
        padding-left: 30px;
    }
    .benefits__area-left-image-from {
        left: -36px;
        padding: 30px 50px;
    }
    .benefits__area-right-list {
        display: grid;
        grid-template-columns: initial;
        gap: 8px;
    }
    .benefits__area-right-bottom {
        border: 0;
        margin-top: 0;
        padding-top: 0;
        display: inherit;
    }
    .benefits__area-right-bottom-btn {
        margin: 20px 0;
    }
    .benefits__two-right-bottom {
        margin-right: 0;
    }
}
@media (max-width: 450px) {
    .benefits__two-left-image {
        padding-bottom: 50px;
    }
    .benefits__two-left-image-two img {
        max-width: 230px;
    }
    .benefits__two-left-image-one {
        max-width: 270px;
    }
}
/*==========================================================================
* Quote Area CSS
==========================================================================*/
.quote__area {
    background: var(--bg-heading-color);
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.quote__area-shape-one {
    position: absolute;
    left: -100px;
    top: -42%;
    z-index: -1;
    animation: rollIn 70s infinite linear;
}
.quote__area-shape-two {
    position: absolute;
    right: -30%;
    top: 0;
    z-index: -1;
    animation: rollIn 70s infinite linear;
}
.quote__area-title {
    max-width: 650px;
    margin: 0 auto;
}
.quote__area-title h2 {
    color: var(--text-white);
}
.quote__area-left {
    background: var(--color-15);
    border: 1px solid var(--border-color-3);
    padding: 40px 30px;
}
.quote__area-left ul {
    display: block;
}
.quote__area-left ul li {
    margin-bottom: 10px;
}
.quote__area-left ul li:last-child {
    margin: 0;
}
.quote__area-left ul li h6 {
    color: var(--text-white);
    background: var(--color-16);
    border-radius: 5px;
    padding: 16px 30px;
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    font-size: 20px;
}
.quote__area-left ul li h6 i {
    color: var(--primary-color-2);
    font-size: 45px;
}
.quote__area-left ul li .active {
    background: var(--primary-color-2);
}
.quote__area-left ul li .active i {
    color: var(--text-white);
}
.quote__area-right-form {
    background: var(--color-15);
    border: 1px solid var(--border-color-3);
    padding: 40px;
}
.quote__area-right-form-item textarea,
.quote__area-right-form-item input {
    background: var(--color-16);
    border-color: var(--color-16);
    color: var(--color-3);
}
.quote__area-right-form-item textarea:focus,
.quote__area-right-form-item input:focus {
    border-color: var(--primary-color-2);
    color: var(--color-3);
}
.quote__area-right-form-item textarea {
    background: var(--color-16);
}
.quote__area-right-form-item .btn-two::after,
.quote__area-right-form-item .btn-two::before {
    background: var(--bg-white);
}
.quote__area-right-form-item .btn-two:hover {
    color: var(--primary-color-2);
}
.quote__area-right-form .contact-item span {
    color: var(--primary-color-2);
}
.pageReplica button {
    padding: 6px;
    background: transparent;
    color: #666;
}
.react-international-phone-country-selector button {
    border: solid 1px #e5e5e5 !important;
    border-right: 0;
}
.register_row .regsiter_second_step_bx textarea.form-control {
    height: auto;
}
.regsiter_second_step_bx #parent .MuiInputBase-input {
    height: 36px !important;
    padding: 0px !important;
    padding-left: 8px !important;
}

@media (max-width: 991px) {
    .quote__area-right-form {
        padding: 30px;
    }
}
@media (max-width: 460px) {
    .quote__area-left ul li h6 {
        padding: 10px 20px;
        gap: 14px;
        font-size: 20px;
    }
    .quote__area-right-form,
    .quote__area-left {
        padding: 30px 25px;
    }
}
@media (max-width: 359px) {
    .quote__area-left ul li h6 {
        padding: 10px 15px;
        gap: 12px;
        font-size: 18px;
    }
}
/*==========================================================================
* Work Area CSS
==========================================================================*/
.work__area {
    position: relative;
    z-index: 1;
}
.work__area-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.work__area-item {
    text-align: center;
    padding: 0 30px;
    position: relative;
    z-index: 1;
    margin-top: 30px;
}
.work__area-item::after {
    content: "";
    position: absolute;
    left: 85%;
    top: 35px;
    width: 120px;
    height: 55px;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
}
.work__area-item-icon {
    display: inline-flex;
    position: relative;
    z-index: 1;
    width: 120px;
    height: 120px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
.work__area-item-icon::after {
    content: "";
    position: absolute;
    top: 0;
    width: 120px;
    height: 120px;
    left: 0;
    border: 1px solid var(--primary-color-2);
    border-radius: 50%;
    opacity: 0.1;
}
.work__area-item-icon span {
    position: absolute;
    right: -30px;
    top: 0;
    background: var(--primary-color-2);
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: var(--text-white);
    font-family: var(--heading-font);
    font-weight: 700;
    font-size: 14px;
    border-radius: 50%;
}
.work__area-item-icon span::before {
    position: absolute;
    content: "";
    left: 3px;
    bottom: -3px;
    display: block;
    z-index: -1;
    width: 0;
    height: 0;
    border-left: 24px solid var(--primary-color-2);
    border-top: 15px solid transparent;
    border-bottom: 12px solid transparent;
    transform: rotate(21deg);
}
.work__area-item-icon i::before {
    font-size: 50px;
    line-height: 50px;
    color: var(--primary-color-2);
    background: var(--color-5);
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 400ms ease;
}
.work__area-item-content p {
    margin: 0;
    margin-top: 5px;
}
.work__area-item:hover .work__area-item-icon i::before {
    width: 120px;
    height: 120px;
    line-height: 120px;
    background: var(--primary-color-2);
    color: var(--text-white);
    font-size: 55px;
    line-height: 55px;
}
.work__area .row.work-n .col-xl-3:last-child .work__area-item {
    margin: 0;
}
.work__area .row.work-n .col-xl-3:last-child .work__area-item::after {
    display: none;
}
.work__area .row.work-n .col-xl-3:nth-child(2) .work__area-item {
    margin: 0;
}

@media (max-width: 1399px) {
    .work__area-item {
        padding: 0 25px;
    }
}
@media (max-width: 1199px) {
    .work__area .row.work-n .col-xl-3:nth-child(3) .work__area-item::after {
        display: none;
    }
    .work__area .row.work-n .col-xl-3:first-child .work__area-item {
        margin-bottom: 30px;
    }
}
@media (max-width: 991px) {
    .work__area .row.work-n .col-xl-3:nth-child(3) .work__area-item {
        margin: 0;
    }
    .work__area .row.work-n .col-xl-3:nth-child(3) .work__area-item::after {
        display: block;
    }
    .work__area .row.work-n .col-xl-3:first-child .work__area-item {
        margin: 0;
    }
    .work__area .row.work-n .col-xl-3:nth-child(2) .work__area-item {
        margin-bottom: 30px;
    }
    .work__area .row.work-n .col-xl-3:nth-child(2) .work__area-item::after {
        display: none;
    }
}
@media (max-width: 767px) {
    .work__area .row.work-n .col-xl-3:first-child .work__area-item,
    .work__area .row.work-n .col-xl-3:nth-child(3) .work__area-item {
        margin-bottom: 30px;
    }
    .work__area .row.work-n .col-xl-3:first-child .work__area-item::after,
    .work__area .row.work-n .col-xl-3:nth-child(3) .work__area-item::after {
        display: none;
    }
}
/*==========================================================================
* Offer Area CSS
==========================================================================*/
.offer__area {
    position: relative;
    z-index: 1;
    background: var(--bg-white);
}
.offer__area-shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.offer__area-left {
    position: relative;
    display: flex;
    padding-bottom: 45px;
}
.offer__area-left div:first-child {
    padding-right: 30px;
    border-right: 24px solid var(--primary-color-2);
}
.offer__area-left .image-overlay.two {
    position: absolute;
    right: 0;
    bottom: 0;
}
.offer__area-right {
    margin-left: 90px;
}
.offer__area-right-title h2 {
    margin-bottom: 20px;
}
.offer__area-right-title p {
    margin: 0;
}
.offer__area-right-list-item {
    display: flex;
    gap: 30px;
    padding: 25px 35px;
    border: 1px solid var(--border-color-1);
    position: relative;
    z-index: 1;
    margin-top: 30px;
}
.offer__area-right-list-item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--primary-color-2);
    opacity: 0;
    transition: 0.4s;
    z-index: -1;
}
.offer__area-right-list-item i {
    font-size: 60px;
    margin-top: 8px;
    color: var(--primary-color-2);
    display: inline-block;
}
.offer__area-right-list-item-content p {
    margin: 0;
    margin-top: 8px;
}
.offer__area-right-list-item:hover::after {
    opacity: 1;
}
.offer__area-right-list-item:hover i {
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

@media (max-width: 1199px) {
    .offer__area-left {
        padding-bottom: 163px;
    }
    .offer__area-left-one {
        max-width: 400px;
    }
    .offer__area-left .image-overlay.two {
        max-width: 300px;
    }
    .offer__area-right {
        margin-left: 0;
    }
    .offer__area-right-list-item {
        padding: 25px 30px;
        gap: 25px;
    }
}
@media (max-width: 991px) {
    .offer__area-left {
        padding-bottom: 40px;
    }
    .offer__area-left-one,
    .offer__area-left .image-overlay.two {
        max-width: 100%;
    }
}
@media (max-width: 767px) {
    .offer__area-left-one {
        max-width: 400px;
    }
    .offer__area-left .image-overlay.two {
        max-width: 250px;
    }
}
@media (max-width: 460px) {
    .offer__area-left-one {
        max-width: 260px;
    }
    .offer__area-left .image-overlay.two {
        max-width: 200px;
    }
    .offer__area-right-list-item {
        display: inherit;
    }
    .offer__area-right-list-item i {
        margin: 0 0 15px 0;
    }
}
@media (max-width: 359px) {
    .offer__area-left-one {
        max-width: 230px;
    }
    .offer__area-left .image-overlay.two {
        max-width: 160px;
    }
}
/*==========================================================================
* Solution CSS
==========================================================================*/
.solution__area {
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}
.solution__area::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    z-index: -1;
    opacity: 0.55;
}
.solution__area-title h2 {
    color: var(--text-white);
    max-width: 500px;
    margin-bottom: 40px;
}
.solution__area-title .btn-one {
    background: var(--primary-color-1);
}
.solution__area-right {
    position: relative;
}
.solution__area-right h6 {
    color: var(--text-white);
    margin-top: 30px;
}
.solution__area-right-video {
    max-width: 160px;
    text-align: center;
    margin: 0 0 0 auto;
}
.solution__area-right-video a {
    color: var(--primary-color-1);
}
.solution__area-shape-one {
    position: absolute;
    left: -80px;
    bottom: -100px;
    animation: rollIn 30s infinite linear;
}
.solution__area-shape-two {
    position: absolute;
    right: -90px;
    top: -50px;
}

@media (max-width: 767px) {
    .solution__area-right-video {
        margin: 0 auto;
        margin-top: 50px;
    }
    .solution__area-title h2 {
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .solution__area-shape-one {
        max-width: 200px;
    }
    .solution__area-shape-two {
        max-width: 300px;
    }
}
/*==========================================================================
* Request Quote CSS
==========================================================================*/
.request__quote {
    position: relative;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    padding: 160px 0;
}
.request__quote-shape-two,
.request__quote-shape-one {
    mix-blend-mode: overlay;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}
.request__quote::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    z-index: -1;
    opacity: 0.75;
}
.request__quote-title h2 {
    color: var(--text-white);
    max-width: 550px;
    margin-bottom: 50px;
}
.request__quote-title-btn {
    display: flex;
    align-items: center;
    gap: 50px;
}
.request__quote-title-btn .btn-one {
    background: var(--text-white);
    color: var(--bg-heading-color);
}
.request__quote-title-btn .btn-one::after,
.request__quote-title-btn .btn-one::before {
    background: var(--primary-color-4);
}
.request__quote-title-btn .btn-one:hover {
    color: var(--text-white);
}
.request__quote-right {
    position: relative;
}
.request__quote-right h6 {
    color: var(--text-white);
    margin-top: 30px;
}
.request__quote-right-video {
    max-width: 160px;
    text-align: center;
    margin: 0 0 0 auto;
}
.request__quote-right-icon a {
    color: var(--primary-color-4);
}

/*==========================================================================
* Request Quote Two CSS
==========================================================================*/
.request__quote-page-left {
    padding: 40px;
    box-shadow: var(--box-shadow-1);
}
.request__quote-page-left h2,
.request__quote-page-left h3 {
    margin-bottom: 25px;
}
.request__quote-page-left > p {
    margin-bottom: 50px;
}
.request__quote-page-left .space input {
    accent-color: var(--primary-color-1);
}
.request__quote-page-left .space span {
    margin: 0 8px;
}
.request__quote-page p {
    font-size: 16px;
    line-height: 26px;
}
.request__quote-page-item {
    position: relative;
    z-index: 1;
}
.request__quote-page-item-label {
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: 300ms ease;
    z-index: -1;
}
.request__quote-page-item span {
    color: var(--primary-color-1);
}
.request__quote-page-services label {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--text-heading-color);
    background: var(--color-4);
    padding: 12px 15px;
    margin: 8px 0;
}
.request__quote-page-right {
    background: #f7fcfc;
    padding: 30px;
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
    position: sticky;
    top: 140px;
}
.request__quote-page-right-item {
    background: #f7fcfc;
}
.request__quote-page-right-item-icon i {
    color: var(--primary-color-1);
    font-size: 60px;
    line-height: 60px;
    display: inline-block;
    margin-bottom: 20px;
}
.request__quote-page-right-item-bottom {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 40px;
}
.request__quote-page-right-item-bottom img {
    width: 70px;
    height: 70px;
    max-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}
.request__quote-page-right .swiper-pagination {
    position: absolute;
    left: 30px;
    bottom: 30px;
    z-index: -1;
    text-align: left;
}
.request__quote-page-right .swiper-pagination span {
    width: 8px;
    height: 8px;
    background: var(--primary-color-1);
    opacity: 1;
}
.request__quote-page-right .swiper-pagination .swiper-pagination-bullet {
    margin: 5px;
    position: relative;
}
.request__quote-page-right .swiper-pagination .swiper-pagination-bullet-active {
    background: none;
    border: 1px solid var(--primary-color-1);
    width: 24px;
    height: 24px;
    margin-bottom: 0;
}
.request__quote-page-right
    .swiper-pagination
    .swiper-pagination-bullet-active::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 8px;
    height: 8px;
    background: var(--primary-color-1);
    border-radius: 50%;
}
.request__quote-page .description {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

@media (max-width: 767px) {
    .request__quote-title {
        text-align: center;
    }
    .request__quote-title h2 {
        margin-bottom: 40px;
    }
    .request__quote-title-btn {
        justify-content: center;
        margin-left: 110px;
    }
    .request__quote-right-video {
        max-width: 160px;
        text-align: center;
        margin: 0 auto;
    }
}
@media (max-width: 575px) {
    .request__quote-title-btn {
        justify-content: center;
        margin-left: 0;
    }
    .request__quote-title-btn img {
        display: none;
    }
}
/*==========================================================================
* Portfolio One CSS
==========================================================================*/
.portfolio__one-item {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.portfolio__one-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 20%;
    width: 100%;
    background: var(--bg-heading-color);
    transition: all 400ms ease;
    opacity: 0;
    z-index: 1;
}
.portfolio__one-item-content {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 40px;
    display: flex;
    align-items: center;
    background: var(--bg-white);
    transform: translateX(-30%);
    transition: 0.5s;
    opacity: 0;
    z-index: 2;
}
.portfolio__one-item-content-left {
    width: 110px;
    height: 110px;
    background: var(--primary-color-2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.portfolio__one-item-content-left i {
    background: var(--bg-white);
    color: var(--primary-color-2);
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}
.portfolio__one-item-content-right {
    padding: 0 30px;
}
.portfolio__one-item-content-right span {
    font-family: var(--heading-font);
    color: var(--primary-color-2);
    font-weight: 600;
}
.portfolio__one-item-content-right h4 a {
    transition: 0.4s;
}
.portfolio__one-item-content-right h4 a:hover {
    color: var(--primary-color-2);
}
.portfolio__one-item:hover img {
    transform: scale(1.06);
}
.portfolio__one-item:hover::before {
    height: 100%;
    opacity: 0.5;
}
.portfolio__one-item:hover .portfolio__one-item-content {
    transform: translateX(0);
    opacity: 1;
}
.portfolio__one-item.page .portfolio__one-item-content-left {
    background: var(--primary-color-1);
}
.portfolio__one-item.page .portfolio__one-item-content-left i {
    color: var(--primary-color-1);
}
.portfolio__one-item.page .portfolio__one-item-content-right h4 a:hover,
.portfolio__one-item.page .portfolio__one-item-content-right span {
    color: var(--primary-color-1);
}

/*==========================================================================
* Portfolio Two CSS
==========================================================================*/
.portfolio__two {
    position: relative;
    z-index: 1;
}
.portfolio__two-shape {
    position: absolute;
    right: 0;
    top: -300px;
    z-index: -1;
}
.portfolio__two-item {
    margin-top: 30px;
}
.portfolio__two-item-image {
    position: relative;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
}
.portfolio__two-item-image::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    transition: all 300ms ease;
    background: linear-gradient(
        180deg,
        rgba(249, 77, 29, 0) 0%,
        var(--primary-color-4) 85.11%
    );
    opacity: 0.92;
    border-radius: 8px;
}
.portfolio__two-item-image-content {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 80px;
    z-index: 1;
    transition: 0.6s;
    opacity: 0;
}
.portfolio__two-item-image-content span {
    font-weight: 500;
    color: var(--text-white);
}
.portfolio__two-item-image-content > a {
    display: block;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.portfolio__two-item-image-content > a i {
    display: inline-flex;
    font-size: 30px;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--primary-color-4);
    background: var(--bg-white);
}
.portfolio__two-item-image-content h4 {
    color: var(--text-white);
}
.portfolio__two-item-image:hover::after {
    height: 100%;
}
.portfolio__two-item-image:hover .portfolio__two-item-image-content {
    bottom: 50px;
    opacity: 1;
}

/*==========================================================================
* Portfolio Three CSS
==========================================================================*/
.portfolio__three {
    background: var(--bg-white);
}
.portfolio__three .transition {
    transition: 0.4s;
}
.portfolio__three-item {
    min-height: 640px;
    flex: 2;
    transition: 0.5s;
    will-change: transform;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;
}
.portfolio__three-item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    opacity: 0.1;
    transition: 0.4s;
}
.portfolio__three-item img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: relative;
    z-index: -1;
}
.portfolio__three-item:hover {
    cursor: pointer;
}
.portfolio__three-item.active {
    flex: 6;
    scroll-snap-align: start;
}
.portfolio__three-item.active .portfolio__three-item-content {
    opacity: 1;
    z-index: 1;
    width: 50%;
}
.portfolio__three-item.active .portfolio__three-item-content.vertical {
    opacity: 0;
    z-index: -1;
}
.portfolio__three-item-content {
    background: var(--bg-white);
    padding: 50px 30px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    z-index: -1;
    width: 450px;
    border-radius: 5px;
}
.portfolio__three-item-content span {
    font-family: var(--heading-font);
    color: var(--primary-color-3);
    font-weight: 600;
}
.portfolio__three-item-content h4 a {
    transition: 0.4s;
}
.portfolio__three-item-content h4 a:hover {
    color: var(--primary-color-3);
}
.portfolio__three-item-content-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.portfolio__three-item-content-icon a i {
    background: var(--primary-color-3);
    color: var(--text-white);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.portfolio__three-item-content-icon.vertical {
    position: initial;
    transform: initial;
}
.portfolio__three-item-content.vertical {
    padding: 30px;
    bottom: 0;
    left: -1px;
    right: -1px;
    z-index: 1;
    transform: rotate(-180deg);
    height: 100%;
    writing-mode: vertical-rl;
    transition: initial;
    width: initial;
    opacity: 1;
    background: var(--color-4);
}
.portfolio__three-item-content.vertical > a {
    position: initial;
}
.portfolio__three .portfolio {
    display: flex;
    overflow: auto;
    gap: 25px;
    scroll-snap-type: x mandatory;
    overflow: hidden;
}
.portfolio__three-page .portfolio__two-item-image-content > a i {
    color: var(--primary-color-1);
}
.portfolio__three-page .portfolio__two-item-image::after {
    background: linear-gradient(
        180deg,
        rgba(249, 77, 29, 0) 0%,
        var(--primary-color-1) 85.11%
    );
}

/*==========================================================================
* Portfolio Four CSS
==========================================================================*/
.portfolio__four {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
}
.portfolio__four-item {
    height: 750px;
}
.portfolio__four-item:first-child {
    border-left: 0;
}
.portfolio__four-item:last-child {
    margin-right: 0;
    border-right: 0;
}
.portfolio__four-item-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    transition: 1s;
    width: 100%;
    z-index: -1;
}
.portfolio__four-item-inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.portfolio__four-item-inner-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.6s;
}
.portfolio__four-item-inner-content::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.5s;
    height: 0;
    width: 100%;
    background: var(--bg-white);
    z-index: -1;
}
.portfolio__four-item-inner-content-top {
    position: relative;
    padding: 30px 35px;
    opacity: 0;
    transition: 0.6s;
}
.portfolio__four-item-inner-content-top span {
    position: absolute;
    right: 30px;
    top: 20px;
    font-size: 80px;
    line-height: 1;
    font-weight: 500;
    font-family: var(--heading-font);
    opacity: 0.1;
    color: var(--primary-color-1);
}
.portfolio__four-item-inner-content-top i {
    color: var(--primary-color-1);
    font-size: 70px;
    display: block;
    margin-bottom: 20px;
}
.portfolio__four-item-inner-content-top h4 {
    margin-bottom: 15px;
    display: inline-block;
    transition: 0.4s;
}
.portfolio__four-item-inner-content-top h4:hover {
    color: var(--primary-color-1);
}
.portfolio__four-item-inner-content-top p {
    margin-bottom: 0;
}
.portfolio__four-item-inner-content-btn {
    background: var(--bg-heading-color);
    transition: 0.6s;
    padding: 20px 35px;
}
.portfolio__four-item-inner-content-btn h4 {
    transition: 0.6s;
    color: var(--text-white);
}
.portfolio__four-item-inner-content-btn span {
    margin-top: -30px;
    display: block;
    opacity: 0;
    z-index: -1;
    position: relative;
    transition: 1s;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--text-white);
    font-family: var(--heading-font);
}
.portfolio__four-item.active .portfolio__four-item-image {
    opacity: 1;
}
.portfolio__four-item.active .portfolio__four-item-inner-content::after {
    height: 100%;
}
.portfolio__four-item.active .portfolio__four-item-inner-content-top {
    opacity: 1;
}
.portfolio__four-item.active .portfolio__four-item-inner-content-btn {
    background: var(--primary-color-1);
}
.portfolio__four-item.active .portfolio__four-item-inner-content-btn h4 {
    opacity: 0;
}
.portfolio__four-item.active .portfolio__four-item-inner-content-btn span {
    opacity: 1;
    z-index: 2;
}

/*==========================================================================
* Noxiy Filter CSS
==========================================================================*/
.noxiy__filter-button {
    text-align: center;
    margin-top: -5px;
}
.noxiy__filter-button button {
    font-weight: 600;
    padding: 0 25px;
    line-height: 48px;
    color: var(--text-heading-color);
    border: 1px solid var(--border-color-4);
    box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
    background: none;
    margin: 5px;
    transition: 0.4s;
}
.noxiy__filter-button button:hover {
    background: var(--primary-color-1);
    color: var(--text-white);
    border-color: var(--primary-color-1);
}
.noxiy__filter-button .active {
    background: var(--primary-color-1);
    color: var(--text-white);
    border-color: var(--primary-color-1);
}

/*==========================================================================
* Project Filter CSS
==========================================================================*/
.project__one-item {
    position: relative;
    z-index: 1;
}
.project__one-item::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-heading-color);
    visibility: hidden;
    transform-origin: bottom center;
    opacity: 0.5;
    transform: scaleY(0) translateZ(100px);
    transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
}
.project__one-item-content {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    padding: 40px 30px;
    z-index: 1;
    transform: translateY(-40px);
    transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
    background: var(--bg-white);
    opacity: 0;
    overflow: hidden;
    border-radius: 8px;
}
.project__one-item-content::before {
    content: "";
    position: absolute;
    right: -55px;
    bottom: -60px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: var(--primary-color-1);
}
.project__one-item-content::after {
    content: "";
    position: absolute;
    right: -40px;
    bottom: -60px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: var(--primary-color-1);
    opacity: 0.2;
}
.project__one-item-content span {
    font-weight: 500;
    font-family: var(--heading-font);
    color: var(--primary-color-1);
    display: block;
    margin-bottom: 5px;
}
.project__one-item-content h4 a {
    transition: 0.4s;
}
.project__one-item-content h4 a:hover {
    color: var(--primary-color-1);
}
.project__one-item:hover::before {
    visibility: visible;
    transform: scaleY(1) translateZ(0px);
}
.project__one-item:hover .project__one-item-content {
    opacity: 1;
    transform: translateY(0);
}

/*==========================================================================
* Project Single CSS
==========================================================================*/
.project__single-area {
    position: sticky;
    top: 0;
}
.project__single-area-content h2 {
    margin-bottom: 20px;
}
.project__single-area-content h3 {
    margin-bottom: 20px;
    margin-top: 30px;
}

@media (max-width: 1530px) {
    .portfolio__one-item-content {
        right: -1px;
    }
    .portfolio__one-item-content-left {
        width: 0;
        height: 100px;
        padding: 0 50px;
    }
    .portfolio__one-item-content-left i {
        width: 55px;
        height: 55px;
        font-size: 24px;
    }
    .portfolio__one-item-content-right {
        padding: 0 20px;
    }
    .portfolio__three-item {
        flex: 1.3;
    }
}
@media (max-width: 1420px) {
    .project__one-item-content {
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;
        padding: 35px 25px;
    }
}
@media (max-width: 1399px) {
    .portfolio__four {
        grid-template-columns: repeat(2, 1fr);
    }
    .portfolio__four-item {
        height: 500px;
    }
}
@media (max-width: 1299px) {
    .portfolio__three-item {
        min-height: 580px;
        flex: 2;
    }
    .portfolio__three-item-content {
        width: 380px;
    }
    .portfolio__three-item.active .portfolio__three-item-content {
        width: 85%;
    }
}
@media (max-width: 991px) {
    .portfolio__three .portfolio {
        display: grid;
    }
    .portfolio__three-item {
        min-height: inherit;
        height: 163px;
    }
    .portfolio__three-item-content {
        width: 100%;
    }
    .portfolio__three-item-content.vertical {
        padding: 50px 30px;
        bottom: 0;
        left: 0;
        right: 0;
        transform: initial;
        height: initial;
        writing-mode: initial;
        width: 100%;
    }
    .portfolio__three-item img {
        width: 100%;
    }
    .portfolio__three-item.active {
        height: 500px;
    }
    .portfolio__three-item.active .portfolio__three-item-content {
        width: inherit;
    }
}
@media (max-width: 767px) {
    .portfolio__four {
        grid-template-columns: repeat(1, 1fr);
    }
    .portfolio__four-item {
        height: 460px;
    }
}
@media (max-width: 575px) {
    .portfolio__three-item {
        height: 123px;
    }
    .portfolio__three-item-content {
        padding: 30px 22px;
        bottom: 22px;
        left: 22px;
        right: 22px;
    }
    .portfolio__three-item-content a i {
        width: 50px;
        height: 50px;
        font-size: 15px;
    }
    .portfolio__three-item-content.vertical {
        padding: 32px 22px;
    }
    .portfolio__three-item-content-icon {
        right: 22px;
    }
    .portfolio__three-item.active {
        height: 400px;
    }
}
@media (max-width: 359px) {
    .portfolio__one-item-content {
        right: -1px;
    }
    .portfolio__one-item-content-left {
        padding: 0 40px;
    }
    .portfolio__one-item-content-right h4 {
        font-size: 20px;
        line-height: 30px;
    }
}
/*==========================================================================
* Video Area CSS
==========================================================================*/
.video__area {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    padding: 180px 0 170px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.video__area::before {
    content: "";
    position: absolute;
    background: var(--bg-heading-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.5;
}
.video__area-play a {
    color: var(--primary-color-3);
}
.video__area h2 {
    max-width: 740px;
    text-align: center;
    margin: 0 auto;
    margin-top: 60px;
    color: var(--text-white);
}
.video__area-shape-one {
    position: absolute;
    left: 0;
    top: -20%;
    animation: upDown 5s linear infinite;
}
.video__area-shape-two {
    position: absolute;
    right: 20px;
    top: 70px;
    max-width: 80px;
}
.video__area-shape-three {
    position: absolute;
    right: 120px;
    bottom: 70px;
    max-width: 55px;
    animation: rollIn 3s infinite linear;
}

@media (max-width: 991px) {
    .video__area {
        padding: 150px 0 140px 0;
    }
    .video__area-shape-one {
        left: -100px;
        top: -80px;
    }
}
@media (max-width: 575px) {
    .video__area {
        padding: 120px 0 110px 0;
    }
    .video__area-shape-three {
        right: 80px;
        bottom: 40px;
    }
}
/*==========================================================================
* Cta One CSS
==========================================================================*/
.cta__area {
    background: var(--primary-color-3);
    padding: 80px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.cta__area-title h2 {
    color: var(--text-white);
}
.cta__area-btn .btn-two {
    background: var(--bg-white);
    color: var(--text-heading-color);
}
.cta__area-btn .btn-two:hover {
    color: var(--text-white);
}

/*==========================================================================
* Cta Two CSS
==========================================================================*/
.cta__two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.cta__two-item {
    padding: 40px;
    box-shadow: 0px -10px 50px rgba(0, 0, 0, 0.06);
    background: var(--bg-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cta__two-item-info {
    display: flex;
    gap: 50px;
    align-items: center;
}
.cta__two-item-info-icon {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}
.cta__two-item-info-icon i {
    color: var(--primary-color-3);
    font-size: 70px;
    line-height: 0;
}
.cta__two-item-info-icon i:last-child {
    opacity: 0.1;
    position: absolute;
    left: 15px;
    top: 20px;
    z-index: -1;
}
.cta__two-item-info h4 {
    max-width: 245px;
}
.cta__two-item-info h4 a {
    transition: 0.4s;
}
.cta__two-item-info h4 a:hover {
    color: var(--primary-color-3);
}
.cta__two-item > a i {
    color: var(--text-white);
    background: var(--primary-color-3);
    font-size: 22px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}
.cta__two-item:first-child {
    border-radius: 5px 0 0 5px;
}
.cta__two-item:last-child {
    border-radius: 0 5px 5px 0;
}
.cta__two-area.services .cta__two-item > a i {
    background: var(--primary-color-1);
}
.cta__two-area.services .cta__two-item-info-icon i {
    color: var(--primary-color-1);
}
.cta__two-area.services .cta__two-item-info h4 a:hover {
    color: var(--primary-color-1);
}

/*==========================================================================
* Cta Three CSS
==========================================================================*/
.cta__three {
    position: relative;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.cta__three::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    opacity: 0.85;
    z-index: -2;
}
.cta__three-shape {
    position: absolute;
    right: 200px;
    top: 0;
    z-index: -1;
    mix-blend-mode: soft-light;
    height: 100%;
}
.cta__three-title h2 {
    color: var(--text-white);
    max-width: 600px;
}
.cta__three-info {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}
.cta__three-info-item {
    display: inline-block;
    background: var(--bg-white);
    padding: 35px 50px;
    border-radius: 6px;
}
.cta__three-info-item-icon {
    margin-bottom: 15px;
}
.cta__three-info-item-icon i {
    color: var(--primary-color-1);
    font-size: 25px;
    background: var(--color-5);
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    transition: 0.4s;
}
.cta__three-info-item h6 {
    text-transform: lowercase;
}
.cta__three-info-item h6 a {
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%)
        0px 95%/0px 2px no-repeat;
    transition: all 400ms ease;
}
.cta__three-info-item h6 a:hover {
    background-size: 100% 2px;
    color: var(--primary-color-1);
}
.cta__three-info-item span {
    font-size: 14px;
    font-weight: 500;
}
.cta__three-info-item:hover .cta__three-info-item-icon i {
    background: var(--primary-color-1);
    color: var(--text-white);
}

@media (max-width: 1399px) {
    .cta__two-item {
        padding: 40px 30px;
    }
    .cta__two-item-info {
        gap: 35px;
    }
    .cta__two-item-info-icon i {
        font-size: 60px;
    }
    .cta__two-item > a i {
        font-size: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
}
@media (max-width: 1199px) {
    .cta__three-info-item {
        padding: 30px 40px;
    }
}
@media (max-width: 991px) {
    .cta__area.one,
    .cta__area {
        padding: 60px 30px;
    }
    .cta__three-title {
        text-align: center;
        margin: 0 auto;
    }
    .cta__two {
        display: block;
    }
    .cta__two-item:first-child {
        border-radius: 5px 5px 0 0;
    }
    .cta__two-item:last-child {
        border-radius: 0 0 5px 5px;
    }
    .cta__three-title {
        text-align: center;
    }
    .cta__three-info {
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .cta__three-info-item {
        padding: 30px;
    }
}
@media (max-width: 575px) {
    .cta__two.one {
        padding: 60px 20px;
    }
}
@media (max-width: 540px) {
    .cta__three-info {
        display: block;
    }
    .cta__three-info-item {
        display: block;
        margin-bottom: 30px;
    }
    .cta__three-info-item:last-child {
        margin: 0;
    }
}
/*==========================================================================
* Team One CSS
==========================================================================*/
.team__one-item {
    margin-top: 30px;
}
.team__one-item-image {
    position: relative;
    z-index: 1;
    border-radius: 6px;
}
.team__one-item-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
        180deg,
        rgba(5, 6, 8, 0) 0%,
        var(--bg-heading-color) 100%
    );
    transition: 0.5s;
    z-index: 1;
    border-radius: 6px;
}
.team__one-item-image::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    transition: all 400ms ease;
    background: linear-gradient(
        180deg,
        rgba(249, 77, 29, 0) 0%,
        var(--primary-color-1) 100%
    );
    transition: 0.5s;
    border-radius: 6px;
}
.team__one-item-image img {
    width: 100%;
    border-radius: 6px;
}
.team__one-item-image-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 40px;
    z-index: 1;
}
.team__one-item-image-info-name span {
    color: var(--text-white);
    font-weight: 500;
}
.team__one-item-image-info-name h4 a {
    color: var(--text-white);
}
.team__one-item-image-info-icon {
    position: relative;
    z-index: 1;
}
.team__one-item-image-info-icon::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 17px;
    height: 15px;
    background-image: url("../src/assests/img/icon/union.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: 0.6s;
}
.team__one-item-image-info-icon span {
    color: var(--primary-color-1);
    height: 45px;
    width: 45px;
    line-height: 45px;
    background: var(--bg-white);
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    display: inline-block;
    transition: 0.4s;
    cursor: pointer;
}
.team__one-item-image-info-icon .team__one-item-image-info-social {
    position: absolute;
    bottom: 60px;
}
.team__one-item-image-info-icon:hover::after {
    top: -9px;
    opacity: 1;
}
.team__one-item-image-info-icon:hover
    .team__one-item-image-info-social::before {
    height: 100%;
}
.team__one-item-image-info-icon:hover .team__one-item-image-info-social ul {
    opacity: 1;
    transition: transform 0.5s ease 0.5s, opacity 0.5s ease 0.5s;
    transform: translateY(0);
    visibility: visible;
}
.team__one-item-image-info-social {
    position: absolute;
    left: 0;
    padding: 15px 0;
}
.team__one-item-image-info-social::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-radius: 6px;
    background: var(--primary-color-1);
    z-index: -1;
    transition: 0.5s;
}
.team__one-item-image-info-social ul {
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
    transform: translateY(-30px);
    padding: 0;
    margin: 0;
}
.team__one-item-image-info-social ul li {
    list-style: none;
}
.team__one-item-image-info-social ul li:last-child {
    margin: 0;
}
.team__one-item-image-info-social ul li a {
    overflow: hidden;
}
.team__one-item-image-info-social ul li a i {
    width: 45px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    color: var(--text-white);
}
.team__one-item:hover .team__one-item-image::before {
    opacity: 0;
}
.team__one-item:hover .team__one-item-image::after {
    height: 70%;
}
.team__one.four .team__one-item-image-info-social::before {
    background: var(--primary-color-4);
}
.team__one.four .team__one-item-image::after {
    background: linear-gradient(
        180deg,
        rgba(249, 77, 29, 0) 0%,
        var(--primary-color-4) 100%
    );
}
.team__one.four .team__one-item-image-info-icon span {
    color: var(--primary-color-4);
}

.team-one {
    position: relative;
    z-index: 1;
    padding-top: 90px;
}
.team-one h6 {
    font-size: 18px;
    line-height: 30px;
}
.team-one h6 a {
    color: var(--primary-color-1);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%)
        0px 95%/0px 1px no-repeat;
    transition: all 600ms ease;
}
.team-one h6 a:hover {
    background-size: 100% 1px;
}
.team-one.about .team__five-item-content {
    padding: 30px;
    margin: 0 25px;
    margin-top: -40px;
}

/*==========================================================================
* Team Two CSS
==========================================================================*/
.team__two {
    background: var(--bg-white);
}
.team__two-item {
    margin-top: 30px;
}
.team__two-item-image {
    position: relative;
    z-index: 1;
}
.team__two-item-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20%;
    top: 0;
    z-index: 1;
    left: 0;
    background: var(--bg-heading-color);
    opacity: 0;
    transition: 0.4s;
}
.team__two-item-image-icon {
    position: absolute;
    bottom: 70px;
    right: 30px;
    z-index: 1;
}
.team__two-item-image-icon span {
    color: var(--text-white);
    height: 45px;
    width: 45px;
    background: var(--primary-color-3);
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    display: inline-block;
    transition: 0.4s;
    cursor: pointer;
}
.team__two-item-image-icon .team__two-item-image-social {
    position: absolute;
    bottom: 55px;
}
.team__two-item-image-icon:hover .team__two-item-image-social {
    opacity: 1;
    visibility: visible;
}
.team__two-item-image-icon.four {
    bottom: 30px;
}
.team__two-item-image-icon.four span {
    background: var(--primary-color-1);
}
.team__two-item-image-icon.four .team__two-item-image-social ul li a i:hover {
    background: var(--primary-color-1);
}
.team__two-item-image-social {
    position: absolute;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
}
.team__two-item-image-social ul {
    padding: 0;
    margin: 0;
}
.team__two-item-image-social ul li {
    list-style: none;
    margin-bottom: 8px;
}
.team__two-item-image-social ul li:last-child {
    margin: 0;
}
.team__two-item-image-social ul li a i {
    display: inline-block;
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 14px;
    border-radius: 50%;
    background: var(--bg-white);
    color: var(--text-heading-color);
    transition: 0.4s;
}
.team__two-item-image-social ul li a i:hover {
    background: var(--primary-color-3);
    color: var(--text-white);
}
.team__two-item-image img {
    width: 100%;
}
.team__two-item-content {
    padding: 25px 20px;
    margin: 0 25px;
    border-radius: 5px;
    margin-top: -55px;
    background: var(--bg-white);
    position: relative;
    z-index: 1;
    text-align: center;
    box-shadow: var(--box-shadow-1);
}
.team__two-item-content span {
    color: var(--primary-color-3);
}
.team__two-item-content h4 a {
    transition: 0.4s;
}
.team__two-item-content h4 a:hover {
    color: var(--primary-color-3);
}
.team__two-item:hover .team__two-item-image::before {
    height: 100%;
    opacity: 0.7;
    z-index: 1;
}

/*==========================================================================
* Team Three CSS
==========================================================================*/
.team__three-item {
    margin-top: 30px;
}
.team__three-item-image img {
    width: 100%;
}
.team__three-item-content {
    padding: 25px 20px;
    margin: 0 25px;
    border-radius: 5px;
    margin-top: -60px;
    transition: 0.4s;
    background: var(--bg-white);
    position: relative;
    z-index: 1;
    text-align: center;
    box-shadow: var(--box-shadow-1);
}
.team__three-item-content span {
    color: var(--primary-color-1);
    transition: 0.5s;
}
.team__three-item-content h5 {
    margin-bottom: 20px;
    transition: 0.5s;
}
.team__three-item-content ul {
    padding: 0;
    margin: 0;
}
.team__three-item-content ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px;
}
.team__three-item-content ul li:last-child {
    margin: 0;
}
.team__three-item-content ul li a i {
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    border-radius: 50%;
    background: var(--color-4);
    color: var(--text-heading-color);
    transition: 0.4s;
}
.team__three-item-content ul li a i:hover {
    color: var(--primary-color-1);
}
.team__three-item:hover .team__three-item-content {
    margin-top: -80px;
    background: var(--primary-color-1);
}
.team__three-item:hover .team__three-item-content span,
.team__three-item:hover .team__three-item-content h5 {
    color: var(--text-white);
}

/*==========================================================================
* Team Four CSS
==========================================================================*/
.team__four {
    padding-top: 90px;
}
.team__four-item {
    margin-top: 30px;
}
.team__four-item-image {
    margin: 0 30px;
    position: relative;
    z-index: 2;
}
.team__four-item-image::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: var(--bg-heading-color);
    opacity: 0;
    transition: 0.4s;
    height: 100%;
}
.team__four-item-image-social {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    width: 100%;
    text-align: center;
}
.team__four-item-image-social ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 8px;
}
.team__four-item-image-social ul li {
    list-style: none;
    display: inline-block;
}
.team__four-item-image-social ul li a {
    display: inline-flex;
    transition: all ease 0.4s;
    opacity: 0;
    transform: translateY(20px);
}
.team__four-item-image-social ul li a i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--bg-white);
    color: var(--primary-color-1);
    transition: 0.4s;
    font-size: 14px;
}
.team__four-item-image-social ul li a:hover i {
    color: var(--text-white);
    background: var(--primary-color-1);
}
.team__four-item-image-social ul li:nth-child(1) a {
    transition-delay: 0.1s;
}
.team__four-item-image-social ul li:nth-child(2) a {
    transition-delay: 0.2s;
}
.team__four-item-image-social ul li:nth-child(3) a {
    transition-delay: 0.3s;
}
.team__four-item-image-social ul li:nth-child(4) a {
    transition-delay: 0.4s;
}
.team__four-item-content {
    text-align: center;
    background: var(--color-4);
    padding: 30px;
    padding-top: 100px;
    margin-top: -70px;
    border-radius: 5px;
    position: relative;
    border: 1px solid var(--border-color-1);
    z-index: 1;
}
.team__four-item-content::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    background: var(--primary-color-1);
    transition: all 400ms ease;
    height: 0;
    z-index: -1;
    border-radius: 5px;
}
.team__four-item-content span {
    color: var(--primary-color-1);
    transition: 0.4s;
}
.team__four-item-content h4 {
    transition: 0.4s;
}
.team__four-item:hover .team__four-item-image::before {
    opacity: 0.5;
}
.team__four-item:hover .team__four-item-image-social ul li a {
    transform: translateY(0);
    opacity: 1;
}
.team__four-item:hover .team__four-item-content::before {
    height: 100%;
}
.team__four-item:hover .team__four-item-content h4,
.team__four-item:hover .team__four-item-content span {
    color: var(--text-white);
}

/*==========================================================================
* Team Five CSS
==========================================================================*/
.team__five {
    padding-top: 90px;
}
.team__five-item {
    margin-top: 30px;
}
.team__five-item-image {
    position: relative;
    z-index: 1;
}
.team__five-item-image::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: var(--bg-heading-color);
    opacity: 0;
    transition: 0.4s;
    height: 100%;
}
.team__five-item-image-social {
    position: absolute;
    top: 30px;
    right: 30px;
}
.team__five-item-image-social ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 8px;
}
.team__five-item-image-social ul li {
    list-style: none;
}
.team__five-item-image-social ul li a {
    display: inline-flex;
    transition: all ease 0.4s;
    opacity: 0;
    transform: translateX(20px);
}
.team__five-item-image-social ul li a i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--bg-white);
    color: var(--primary-color-1);
    transition: 0.4s;
    font-size: 14px;
}
.team__five-item-image-social ul li a:hover i {
    background: var(--primary-color-1);
    color: var(--text-white);
}
.team__five-item-image-social ul li:nth-child(1) a {
    transition-delay: 0.1s;
}
.team__five-item-image-social ul li:nth-child(2) a {
    transition-delay: 0.2s;
}
.team__five-item-image-social ul li:nth-child(3) a {
    transition-delay: 0.3s;
}
.team__five-item-image-social ul li:nth-child(4) a {
    transition-delay: 0.4s;
}
.team__five-item-content {
    position: relative;
    z-index: 1;
    text-align: center;
    box-shadow: var(--box-shadow-1);
    background: var(--bg-white);
    padding: 40px 30px;
    margin: 0 30px;
    margin-top: -40px;
    border-radius: 5px;
    transition: 0.4s;
}
.team__five-item-content span {
    color: var(--primary-color-1);
    transition: 0.4s;
}
.team__five-item-content h4 {
    transition: 0.4s;
}
.team__five-item:hover .team__five-item-image::before {
    opacity: 0.5;
}
.team__five-item:hover .team__five-item-image-social ul li a {
    transform: translateX(0);
    opacity: 1;
}
.team__five-item:hover .team__five-item-content {
    margin-top: -60px;
    background: var(--primary-color-1);
}
.team__five-item:hover .team__five-item-content h4,
.team__five-item:hover .team__five-item-content span {
    color: var(--text-white);
}

/*==========================================================================
* Team Six CSS
==========================================================================*/
.team__six {
    background: var(--bg-white);
    position: relative;
    z-index: 1;
}
.team__six-shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.team__six-item {
    margin-top: 30px;
    text-align: center;
}
.team__six-item-image {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.team__six-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.138);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.team__six-item-content {
    padding-top: 20px;
}
.team__six-item-content span {
    color: var(--primary-color-1);
}
.team__six-item-content h4 a {
    transition: 0.4s;
}
.team__six-item-content h4 a:hover {
    color: var(--primary-color-1);
}
.team__six-item:hover .team__six-item-image img {
    transform: scale(1.05);
}
.team__six-item:hover .team__six-item-image::before {
    animation: circle 1s;
}

/*==========================================================================
* Team Single CSS
==========================================================================*/
.team__single-left {
    position: sticky;
    top: 130px;
    margin-right: 30px;
}
.team__single-left-thumb {
    position: relative;
}
.team__single-left-thumb-social {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    width: 100%;
    text-align: center;
}
.team__single-left-thumb-social ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 8px;
}
.team__single-left-thumb-social ul li {
    list-style: none;
    display: inline-block;
}
.team__single-left-thumb-social ul li a {
    display: inline-flex;
    transition: all ease 0.4s;
    opacity: 0;
    transform: translateY(20px);
}
.team__single-left-thumb-social ul li a i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--bg-white);
    transition: 0.4s;
    color: var(--primary-color-1);
}
.team__single-left-thumb-social ul li a:hover i {
    background: var(--primary-color-1);
    color: var(--text-white);
}
.team__single-left-thumb-social ul li:nth-child(1) a {
    transition-delay: 0.1s;
}
.team__single-left-thumb-social ul li:nth-child(2) a {
    transition-delay: 0.2s;
}
.team__single-left-thumb-social ul li:nth-child(3) a {
    transition-delay: 0.3s;
}
.team__single-left-thumb-social ul li:nth-child(4) a {
    transition-delay: 0.4s;
}
.team__single-left-info {
    padding: 40px;
    background: var(--color-4);
    border: 1px solid var(--border-color-4);
    margin-bottom: 30px;
}
.team__single-left-info > span {
    font-weight: 500;
    color: var(--primary-color-1);
    margin-top: -7px;
    display: block;
}
.team__single-left-info h3 {
    margin-bottom: 35px;
}
.team__single-left-info p {
    margin-bottom: 25px;
}
.team__single-left-info-contact-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
    background: var(--bg-white);
    border-radius: 10px;
}
.team__single-left-info-contact-item:last-child {
    margin: 0;
}
.team__single-left-info-contact-item i {
    width: 70px;
    height: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: 400;
    font-size: 28px;
    color: var(--text-white);
    background: var(--primary-color-1);
}
.team__single-left-info-contact-item-info span {
    font-size: 15px;
    font-weight: 500;
}
.team__single-left-info-contact-item-info h5 {
    text-transform: lowercase;
}
.team__single-left-info-contact-item-info h5 a {
    transition: 0.4s;
}
.team__single-left-info-contact-item-info h5 a:hover {
    color: var(--primary-color-1);
}
.team__single-left:hover .team__single-left-thumb-social ul li a {
    transform: translateY(0);
    opacity: 1;
}
.team__single-right-experience {
    margin-bottom: 40px;
}
.team__single-right-experience h3 {
    margin-bottom: 10px;
}
.team__single-right-experience p:last-child {
    margin: 0;
}
.team__single-right-skill h3 {
    margin-bottom: 10px;
}
.team__single-right-skill p {
    margin-bottom: 30px;
}
.team__single-right-form {
    margin-top: 50px;
    padding: 50px 30px;
    background: var(--color-4);
    border: 1px solid var(--border-color-4);
}
.team__single-right-form-select {
    position: relative;
    z-index: 1;
}
.team__single-right-form-select::before {
    content: "\f88c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color-1);
}
.team__single-right-form-select select {
    width: 100%;
    height: 60px;
    background: var(--bg-white);
    padding: 0 15px;
    color: var(--body-color);
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.team__single-right-form-select select:focus {
    outline: none;
}
.team__single-right-form .btn-one {
    background: var(--bg-heading-color);
}
.team__single-right-form .btn-one::after,
.team__single-right-form .btn-one::before {
    background: var(--primary-color-1);
}

.skill__area {
    margin-left: 10px;
}
.skill__area h3 {
    margin-bottom: 10px;
}
.skill__area > p {
    margin-bottom: 40px;
}
.skill__area-item {
    margin-bottom: 25px;
}
.skill__area-item:last-child {
    margin: 0;
}
.skill__area-item-content {
    position: relative;
}
.skill__area-item-content h6 {
    font-size: 18px;
    line-height: 28px;
}
.skill__area-item-count {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
}
.skill__area-item-bar {
    height: 8px;
    position: absolute;
    width: 0;
    top: -1.4px;
    left: 0;
    background: var(--primary-color-1);
    transition: all 3.5s ease-out 0s;
}
.skill__area-item-inner {
    width: 100%;
    height: 5px;
    position: relative;
    background: var(--border-color-2);
    margin-top: 11px;
}

@media (max-width: 1399px) {
    .team__one-item-image-info {
        padding: 30px;
    }
}
@media (max-width: 991px) {
    .team__single-left {
        margin: 0;
    }
    .team__single-left-info {
        padding: 35px 25px;
    }
}
@media (max-width: 575px) {
    .noxiy__filter-button button {
        font-weight: 500;
        padding: 0px 18px;
        line-height: 40px;
        margin: 4px;
    }
    .team__single-form {
        padding: 40px 20px;
    }
    .team-one {
        padding-top: 55px;
    }
    .team__four {
        padding-top: 55px;
    }
    .team__four-item-image {
        margin: 0 20px;
    }
    .team__four-item-content {
        padding: 20px;
        padding-top: 90px;
    }
    .team__single-left-info-contact-item {
        gap: 10px;
    }
    .team__single-left-info-contact-item i {
        width: 60px;
        height: 85px;
        font-size: 22px;
    }
    .team__single-left-info-contact-item-info span {
        font-size: 14px;
    }
    .team__single-left-info-contact-item-info h5 {
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
    }
}
/*==========================================================================
* Testimonial One CSS
==========================================================================*/
.testimonial__one {
    background: var(--color-4);
    position: relative;
    z-index: 1;
}
.testimonial__one-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.testimonial__one-title {
    margin-right: 90px;
}
.testimonial__one-title h2 {
    margin-bottom: 20px;
}
.testimonial__one-title p {
    max-width: 570px;
    margin: 0;
    margin-bottom: 30px;
}
.testimonial__one-title h6 {
    background: var(--bg-white);
    padding: 20px 30px;
    margin-bottom: 40px;
    border-left: 3px solid var(--primary-color-2);
}
.testimonial__one-title h6 span {
    color: var(--primary-color-2);
}
.testimonial__one-title h6 i {
    color: #ffc600;
    font-size: 14px;
    margin-left: 2px;
}
.testimonial__one-title h6 i:nth-child(3) {
    margin-left: 10px;
}
.testimonial__one-pagination {
    width: 280px;
    padding-left: 10px;
    margin-bottom: 15px;
}
.testimonial__one-pagination-item img {
    width: 70px;
    height: 70px;
    max-width: 70px;
    border-radius: 50%;
    cursor: pointer;
    border: 5px solid var(--color-3);
}
.testimonial__one-pagination .swiper-slide {
    width: 33.3333333333%;
    height: 80px;
    margin-top: 5px;
    transition: 0.3s;
}
.testimonial__one-pagination .swiper-slide-active {
    margin-top: 0;
}
.testimonial__one-pagination .swiper-slide-active img {
    width: 80px;
    height: 80px;
    max-width: 80px;
    border-color: var(--primary-color-2);
}
.testimonial__one-item {
    padding: 35px 50px;
    background: var(--bg-white);
    border: 1px solid rgba(5, 6, 8, 0.1);
    position: relative;
    margin-top: 25px;
}
.testimonial__one-item::after {
    content: "";
    position: absolute;
    left: 50%;
    top: -16px;
    width: 30px;
    height: 30px;
    background: var(--bg-white);
    border-top: var(--border-color-1) solid 1px;
    border-left: var(--border-color-1) solid 1px;
    transform: translateX(-50%) rotate(45deg);
}
.testimonial__one-item-review {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
}
.testimonial__one-item-review-rating i {
    color: #ffc600;
}
.testimonial__one-item-bottom {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 25px;
}
.testimonial__one-item-bottom i {
    color: var(--primary-color-2);
    font-size: 70px;
    line-height: 0;
}

/*==========================================================================
* Testimonial Two CSS
==========================================================================*/
.testimonial__two {
    background: var(--color-4);
    position: relative;
    z-index: 1;
}
.testimonial__two-title h2 {
    margin-bottom: 20px;
}
.testimonial__two-title p {
    max-width: 570px;
    margin: 0;
    margin-bottom: 30px;
}
.testimonial__two-title h6 {
    background: var(--bg-white);
    padding: 30px;
    margin-bottom: 40px;
    border-left: 3px solid var(--primary-color-4);
}
.testimonial__two-title .btn-one {
    background: var(--bg-heading-color);
}
.testimonial__two-title .btn-one::after,
.testimonial__two-title .btn-one::before {
    background: var(--primary-color-4);
}
.testimonial__two-item {
    position: relative;
    background: var(--bg-white);
    padding: 45px 45px 0 45px;
    border-radius: 20px;
    overflow: hidden;
}
.testimonial__two-item-inner {
    position: relative;
}
.testimonial__two-item-inner-client {
    max-width: 340px;
}
.testimonial__two-item-inner-client p {
    margin: 4px 0 25px 0;
}
.testimonial__two-item-inner-reviews {
    position: absolute;
    right: 0;
    top: 0;
}
.testimonial__two-item-inner-reviews i {
    color: #ffc600;
}
.testimonial__two-item-inner-avatar {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: -50px;
    background: var(--text-white);
    border-radius: 50%;
    padding: 6px;
}
.testimonial__two-item-inner-avatar img {
    width: 100px;
    height: 100px;
    max-width: 100px;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    border: 6px solid var(--primary-color-4);
}
.testimonial__two-item-inner-avatar .quote {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: -25px;
}
.testimonial__two-item-inner-avatar .quote i::before {
    color: var(--primary-color-4);
    background: var(--color-4);
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
}
.testimonial__two-item-bottom {
    background: var(--primary-color-4);
    margin: 0 -45px;
    padding: 14px 45px;
}
.testimonial__two-item-bottom span {
    color: var(--text-white);
}
.testimonial__two .testimonial__slider {
    height: 532px;
}
.testimonial__two-left {
    margin-left: 65px;
    position: relative;
}
.testimonial__two-left .testimonial-pagination {
    position: absolute;
    right: -40px;
    bottom: 0;
    top: 55%;
    transform: translateY(-50%);
    border-radius: 5px;
    overflow: hidden;
}
.testimonial__two-left .testimonial-pagination span {
    width: 8px;
    height: 45px;
    background: var(--color-20);
    opacity: 1;
}
.testimonial__two-left .testimonial-pagination .swiper-pagination-bullet {
    margin: 10px 0;
    position: relative;
    border-radius: 5px;
}
.testimonial__two-left
    .testimonial-pagination
    .swiper-pagination-bullet-active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--primary-color-4);
    width: 100%;
    border-radius: 5px;
    height: 100%;
}

/*==========================================================================
* Testimonial Page CSS
==========================================================================*/
.testimonial__page-item {
    padding: 40px;
    box-shadow: var(--box-shadow-2);
    background-position: right;
    background-repeat: no-repeat;
}
.testimonial__page-item-top {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 20px;
}
.testimonial__page-item-top > i {
    color: var(--primary-color-1);
    font-size: 53px;
    line-height: 0;
}
.testimonial__page-item-top-reviews h6 {
    font-size: 17px;
    line-height: 27px;
}
.testimonial__page-item-top-reviews ul {
    padding: 0;
    margin: 0;
}
.testimonial__page-item-top-reviews ul li {
    display: inline-block;
}
.testimonial__page-item-top-reviews ul li i {
    color: #ffc600;
}
.testimonial__page-item-bottom {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid var(--border-color-1);
    padding: 20px;
    margin-top: 40px;
    border-radius: 6px;
    position: relative;
}
.testimonial__page-item-bottom::before {
    content: "";
    position: absolute;
    left: 30px;
    top: -10px;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    background: var(--bg-white);
    border-top: var(--border-color-1) solid 1px;
    border-left: var(--border-color-1) solid 1px;
}
.testimonial__page-item-bottom img {
    max-width: 60px;
    border-radius: 50%;
}

@media (max-width: 1399px) {
    .testimonial__page-item {
        padding: 40px 30px;
    }
}
@media (max-width: 1299px) {
    .testimonial__two-left .testimonial-pagination {
        display: none;
    }
}
@media (max-width: 1199px) {
    .testimonial__one-title {
        margin-right: 0;
    }
    .testimonial__one-title h6 {
        font-size: 17px;
        padding: 20px 25px;
    }
    .testimonial__two-left {
        margin-left: 0;
    }
    .testimonial__two-left .testimonial-pagination {
        right: -30px;
        display: block;
    }
    .testimonial__two-item-inner-avatar img {
        width: 85px;
        height: 85px;
        max-width: 85px;
    }
}
@media (max-width: 1060px) {
    .testimonial__two-left .testimonial-pagination {
        display: none;
    }
}
@media (max-width: 991px) {
    .testimonial__page-item {
        padding: 35px 25px;
    }
}
@media (max-width: 575px) {
    .testimonial__two-title h6 {
        padding: 30px 20px;
    }
    .testimonial__one-item {
        padding: 35px 45px;
    }
}
@media (max-width: 460px) {
    .testimonial__one-item {
        padding: 30px 25px;
    }
    .testimonial__two-item {
        padding: 30px 25px 0 25px;
    }
    .testimonial__two-item-bottom {
        margin: 43px -45px;
    }
    .testimonial__two-item-inner-avatar {
        bottom: -74px;
    }
    .testimonial__two-item-inner-avatar img {
        width: 70px;
        height: 70px;
        max-width: 70px;
    }
}
@media (max-width: 359px) {
    .testimonial__two-item {
        padding: 30px 13px 0 14px;
    }
    .testimonial__one-item {
        padding: 30px 20px;
    }
    .testimonial__one-item-review {
        gap: 10px;
    }
    .testimonial__one-item-review-rating i {
        font-size: 15px;
    }
    .testimonial__one-item-bottom-name h4 {
        font-size: 21px;
    }
    .testimonial__one-title h6 {
        font-size: 16px;
        padding: 20px;
    }
}
/*==========================================================================
* FAQ One CSS
==========================================================================*/
.faq__one {
    background: var(--bg-white);
}
.faq__one-left {
    margin-right: 70px;
}
.faq__one-left-image {
    position: relative;
    padding-bottom: 83px;
    display: flex;
}
.faq__one-left-image-one {
    position: absolute;
    right: 0;
    bottom: 0;
}
.faq__one-left-image-question {
    position: absolute;
    top: 35px;
    z-index: 1;
    right: 70px;
    background: var(--bg-heading-color);
    padding: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.faq__one-left-image-question h6 {
    color: var(--text-white);
    max-width: 150px;
    text-transform: none;
}
.faq__one-left-image-question-icon i {
    background: var(--primary-color-3);
    width: 66px;
    height: 66px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--text-white);
    font-size: 35px;
    position: relative;
    margin-bottom: 7px;
}
.faq__one-left-image-question-icon i::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -5px;
    display: block;
    z-index: -1;
    width: 0;
    height: 0;
    border-left: 17px solid var(--primary-color-3);
    border-top: 9px solid transparent;
    border-bottom: 12px solid transparent;
    transform: rotate(-25deg) translateX(-50%);
}
.faq__one-left-image-question-icon.page i {
    background: var(--primary-color-1);
}
.faq__one-left-image-question-icon.page i::after {
    border-left-color: var(--primary-color-1);
}

/*==========================================================================
* FAQ Area CSS
==========================================================================*/
.faq__area {
    margin-top: 40px;
}
.faq__area-item {
    background: var(--color-4);
    margin-bottom: 20px;
    border-radius: 5px;
}
.faq__area-item:last-child {
    margin: 0;
}
.faq__area-item h6 {
    padding: 16px 0;
    margin: 0 30px;
    cursor: pointer;
}
.faq__area-item .icon {
    position: relative;
}
.faq__area-item .icon::after {
    content: "\f067";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    position: absolute;
    color: var(--primary-color-3);
    right: 0;
}
.faq__area-item .icon:not(.collapsed)::after {
    content: "\f068";
}
.faq__area-item .icon.page::after {
    color: var(--primary-color-1);
}
.faq__area-item-body {
    padding: 0 30px;
}
.faq__area-item-body p {
    margin: 0;
    border-top: 1px solid var(--border-color-1);
    padding: 15px 0 30px 0;
}

@media (max-width: 1399px) {
    .faq__one-left {
        margin-right: 0;
    }
}
@media (max-width: 520px) {
    .faq__one-left-image img {
        max-width: 280px;
    }
    .faq__one-left-image-question {
        right: 20px;
    }
    .faq__area-item h6 {
        padding: 15px 0;
        margin: 0 20px;
    }
    .faq__area-item-body {
        padding: 0 20px;
    }
    .faq__area-item-body p {
        padding: 15px 0 20px 0;
    }
}
/*==========================================================================
* Pricing One Css
========================================================================== */
.pricing__area {
    background: var(--color-4);
}
.pricing__area-item {
    padding: 50px;
    transition: 0.4s;
    background: var(--bg-white);
    border-radius: 5px;
}
.pricing__area-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}
.pricing__area-item-top::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--bg-heading-color);
    transition: 0.4s;
    opacity: 0.1;
}
.pricing__area-item-top-price p {
    font-size: 14px;
    line-height: 24px;
    transition: 0.4s;
    font-weight: 500;
}
.pricing__area-item-top-price h2 {
    transition: 0.4s;
}
.pricing__area-item-top-price h2 span {
    font-size: 30px;
    line-height: 40px;
}
.pricing__area-item-top-icon i {
    color: var(--primary-color-1);
    font-size: 60px;
    transition: 0.4s;
    display: inherit;
}
.pricing__area-item-content-item {
    position: relative;
    padding-left: 35px;
    margin-bottom: 5px;
}
.pricing__area-item-content-item::before {
    position: absolute;
    content: "";
    left: 0;
    top: 26px;
    width: 20px;
    height: 2px;
    background: var(--primary-color-1);
    transition: 0.4s;
    opacity: 0.6;
}
.pricing__area-item-content-item span {
    font-size: 14px;
    line-height: 24px;
    transition: 0.4s;
    font-weight: 500;
}
.pricing__area-item-content-item h6 {
    transition: 0.4s;
}
.pricing__area-item-content-item:last-child {
    margin: 0;
}
.pricing__area-item-btn {
    margin-top: 30px;
}
.pricing__area-item-btn a {
    display: block;
    width: 100%;
    line-height: 56px;
    color: var(--text-heading-color);
    text-align: center;
    border: 1px solid var(--border-color-1);
    border-radius: 3px;
    transition: 0.4s;
    font-weight: 600;
}
.pricing__area-item-btn a i {
    position: relative;
    top: 1px;
    margin-left: 8px;
}
.pricing__area-item:hover {
    background: var(--primary-color-1);
}
.pricing__area-item:hover .pricing__area-item-top::before {
    background: var(--text-white);
    opacity: 0.3;
}
.pricing__area-item:hover .pricing__area-item-top-price p {
    color: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-top-price h2 {
    color: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-top-icon i {
    color: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-content-item::before {
    background: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-content-item span {
    color: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-content-item h6 {
    color: var(--text-white);
}
.pricing__area-item:hover .pricing__area-item-btn a {
    background: var(--bg-white);
    border-color: var(--bg-white);
}

/*==========================================================================
* Pricing Two CSS
==========================================================================*/
.pricing__two-title h2 {
    margin-bottom: 30px;
}
.pricing__two-title p {
    margin-bottom: 40px;
}
.pricing__two-item {
    background: var(--bg-white);
    box-shadow: var(--box-shadow-1);
    padding: 40px;
}
.pricing__two-item-price {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--border-color-1);
}
.pricing__two-item-price > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    font-family: var(--heading-font);
    margin-bottom: 5px;
    display: inline-block;
}
.pricing__two-item-price h2 span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 10px;
    display: inline-block;
}
.pricing__two-item-list ul {
    padding: 0;
    margin: 0;
}
.pricing__two-item-list ul li {
    list-style: none;
    color: var(--text-heading-color);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.pricing__two-item-list ul li i {
    color: var(--primary-color-1);
    font-size: 20px;
}
.pricing__two-item-list ul li:last-child {
    margin: 0;
}
.pricing__two-item-btn {
    margin-top: 30px;
}
.pricing__two-button {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
}
.pricing__two-button .nav-item button {
    background: transparent;
    border: 1px solid var(--border-color-1);
    font-weight: 600;
    font-size: 14px;
    line-height: 50px;
    text-transform: capitalize;
    font-family: var(--heading-font);
    padding: 0 30px;
    margin: 0 10px;
    color: var(--text-heading-color);
}
.pricing__two-button .nav-item .active {
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
    color: var(--text-white);
}

@media (max-width: 1399px) {
    .pricing__two-item {
        padding: 30px 25px;
    }
    .pricing__two-item.center .pricing__two-item-btn .theme-btn {
        padding: 12px 34px;
    }
    .pricing__two-item-btn .btn-three {
        padding: 11px 34px;
    }
    .pricing__area-item {
        padding: 40px 30px;
    }
    .pricing__area-item-content-item {
        padding-left: 30px;
    }
    .pricing__area-item-content-item::before {
        width: 15px;
    }
    .pricing__area-item-content-item h6 {
        font-size: 14px;
        line-height: 24px;
    }
}
@media (max-width: 1199px) {
    .pricing__two-title {
        text-align: center;
    }
    .pricing__two-button {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
}
/*==========================================================================
* Blog One CSS
==========================================================================*/
.blog__one-item {
    margin-top: 30px;
}
.blog__one-item-image {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.blog__one-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.113);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.blog__one-item-image img {
    transition: transform 0.55s ease-in-out;
    width: 100%;
}
.blog__one-item-image-date {
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: var(--bg-white);
    display: inline-block;
    text-align: center;
    border-radius: 40px;
    padding: 5px;
}
.blog__one-item-image-date h5 {
    background: var(--primary-color-2);
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: var(--text-white);
}
.blog__one-item-image-date span {
    font-weight: 700;
    font-size: 15px;
    font-family: var(--heading-font);
    color: var(--text-heading-color);
}
.blog__one-item-content {
    padding: 20px 40px;
    border-right: 1px solid var(--border-color-1);
    border-left: 1px solid var(--border-color-1);
}
.blog__one-item-content-meta {
    margin-bottom: 10px;
}
.blog__one-item-content-meta ul {
    padding: 0;
    margin: 0;
}
.blog__one-item-content-meta ul li {
    display: inline-block;
    list-style: none;
}
.blog__one-item-content-meta ul li a {
    transition: 0.4s;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-1);
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid var(--border-color-1);
}
.blog__one-item-content-meta ul li a i {
    color: var(--primary-color-2);
    font-size: 15px;
    margin-right: 10px;
}
.blog__one-item-content-meta ul li a:hover {
    color: var(--primary-color-2);
}
.blog__one-item-content-meta ul li:last-child a {
    margin: 0;
    padding: 0;
    border: 0;
}
.blog__one-item-content h4 a {
    transition: 0.4s;
}
.blog__one-item-content h4 a:hover {
    color: var(--primary-color-2);
}
.blog__one-item-content p {
    margin: 0;
    margin-top: 10px;
}
.blog__one-item-btn a {
    border: 1px solid var(--border-color-1);
    color: var(--text-heading-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 40px;
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    transition: 0.4s;
}
.blog__one-item-btn a::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: var(--primary-color-2);
    opacity: 0;
    transform: translateX(-50%);
    transition: all 400ms ease;
    z-index: -1;
}
.blog__one-item:hover .blog__one-item-image img {
    transform: scale(1.05);
}
.blog__one-item:hover .blog__one-item-image::before {
    animation: circle 0.9s;
}
.blog__one-item:hover .blog__one-item-btn a {
    color: var(--text-white);
}
.blog__one-item:hover .blog__one-item-btn a::after {
    width: 100%;
    opacity: 1;
}

/*==========================================================================
* Blog Two CSS
==========================================================================*/
.blog__two-title {
    text-align: center;
}
.blog__two-item-image {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.blog__two-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.113);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.blog__two-item-image img {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    transition: transform 0.7s ease-in-out;
}
.blog__two-item-content {
    position: relative;
    padding: 30px 35px;
    border: 1px solid var(--border-color-2);
    border-bottom: 0;
}
.blog__two-item-content-date {
    position: absolute;
    border-radius: 5px;
    right: 35px;
    top: -40px;
    width: 80px;
    height: 80px;
    display: inline-block;
    background: var(--bg-white);
    color: var(--text-heading-color);
    box-shadow: var(--box-shadow-1);
    text-align: center;
    padding: 5px 0 10px 0;
    z-index: 1;
}
.blog__two-item-content-date p {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
}
.blog__two-item-content-date h3 {
    font-size: 40px;
}
.blog__two-item-content > span {
    color: var(--primary-color-4);
    font-weight: 700;
    font-size: 14px;
}
.blog__two-item-content h4 a {
    transition: all 0.4s ease-out 0s;
}
.blog__two-item-content h4 a:hover {
    color: var(--primary-color-4);
}
.blog__two-item-content-author {
    display: flex;
    align-items: center;
    margin-top: 25px;
    justify-content: space-between;
}
.blog__two-item-content-author-post {
    display: flex;
    align-items: center;
    gap: 20px;
}
.blog__two-item-content-author-post-image img {
    max-width: 50px;
    width: 50px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}
.blog__two-item-content-author-post-title span {
    font-size: 14px;
    line-height: 24px;
}
.blog__two-item-content-author-post-right a {
    display: inline-flex;
    border: 1px solid var(--border-color-2);
    color: var(--primary-color-4);
    align-items: center;
    padding: 5px 15px;
    justify-content: center;
    gap: 8px;
}
.blog__two-item-btn a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 35px;
    border: 1px solid var(--border-color-2);
    color: var(--text-heading-color);
    font-weight: 600;
    border-radius: 0 0 8px 8px;
    font-family: var(--heading-font);
    font-size: 14px;
    position: relative;
    transition: 0.3s;
    z-index: 1;
}
.blog__two-item-btn a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 6px 6px;
    height: 0;
    background: var(--primary-color-4);
    transition: all 400ms ease;
}
.blog__two-item:hover .blog__two-item-image img {
    transform: scale(1.05);
}
.blog__two-item:hover .blog__two-item-image::before {
    animation: circle 0.9s;
}
.blog__two-item:hover .blog__two-item-btn a {
    color: var(--text-white);
    border-color: var(--primary-color-4);
    border-top-color: var(--bg-white);
}
.blog__two-item:hover .blog__two-item-btn a::after {
    height: 100%;
    z-index: -1;
}
.blog__two-page .blog__two-item-btn a::after {
    background: var(--primary-color-1);
}
.blog__two-page .blog__two-item:hover .blog__two-item-btn a {
    border-color: var(--primary-color-1);
}
.blog__two-page .blog__two-item-content > span,
.blog__two-page .blog__two-item-content h4 a:hover,
.blog__two-page .blog__two-item-content-author-post-right a {
    color: var(--primary-color-1);
}

/*==========================================================================
* Blog Three CSS
==========================================================================*/
.blog__three {
    background: var(--bg-white);
}
.blog__three-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 40px 40px 32px 40px;
    margin-top: 30px;
    border-radius: 5px;
    overflow: hidden;
}
.blog__three-item::after,
.blog__three-item::before {
    content: "";
    position: absolute;
    background: var(--color-4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s;
}
.blog__three-item::after {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    top: initial;
    height: 0;
}
.blog__three-item-meta {
    background: var(--bg-white);
    padding: 13px 20px;
    margin-bottom: 25px;
    transition: 0.5s;
    border-radius: 4px;
}
.blog__three-item-meta ul {
    padding: 0;
    margin: 0;
}
.blog__three-item-meta ul li {
    display: inline-block;
    list-style: none;
}
.blog__three-item-meta ul li a {
    color: var(--primary-color-3);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-right: 1px solid var(--border-color-1);
    margin-right: 20px;
    padding-right: 20px;
    transition: 0.4s;
}
.blog__three-item-meta ul li a i {
    margin-right: 10px;
}
.blog__three-item-meta ul li:last-child a {
    margin: 0;
    padding: 0;
    border: 0;
}
.blog__three-item h4 a {
    transition: 0.4s;
}
.blog__three-item-author {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.blog__three-item-author img {
    max-width: 50px;
    border-radius: 50%;
}
.blog__three-item-author h6 {
    transition: 0.4s;
}
.blog__three-item-btn {
    padding-top: 25px;
    margin-top: 90px;
    position: relative;
}
.blog__three-item-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: var(--border-color-1);
    transition: transform 0.4s ease 0.4s, opacity 0.4s ease 0.3s;
}
.blog__three-item-btn a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-heading-color);
    font-weight: 700;
    font-family: var(--heading-font);
    font-size: 14px;
    transition: 0.4s;
}
.blog__three-item:hover::after {
    height: 100%;
}
.blog__three-item:hover::before {
    height: 0;
}
.blog__three-item:hover h4 a {
    color: var(--text-white);
}
.blog__three-item:hover h4 a:hover {
    color: var(--primary-color-3);
}
.blog__three-item:hover .blog__three-item-meta {
    background: var(--primary-color-3);
}
.blog__three-item:hover .blog__three-item-meta ul li a {
    color: var(--text-white);
    border-color: var(--color-24);
}
.blog__three-item:hover .blog__three-item-btn::before {
    background: var(--bg-white);
    opacity: 0.2;
    transition: transform 0.4s ease 0s, opacity 0.2s ease 0s;
}
.blog__three-item:hover .blog__three-item-btn a:hover {
    color: var(--primary-color-3);
}
.blog__three-item:hover .blog__three-item-btn a,
.blog__three-item:hover .blog__three-item-author h6 {
    color: var(--text-white);
}

/*==========================================================================
* Blog Four CSS
==========================================================================*/
.blog__four {
    background: var(--bg-white);
}
.blog__four-title.btn-one {
    background: var(--primary-color-1);
}
.blog__four-item {
    border: 1px solid var(--border-color-1);
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
}
.blog__four-item-image {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.blog__four-item-image::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: "";
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.113);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.blog__four-item-image img {
    max-width: 270px;
}
.blog__four-item-image-date {
    position: absolute;
    right: 20px;
    top: 20px;
}
.blog__four-item-image-date span {
    background: var(--primary-color-1);
    padding: 10px 20px;
    color: var(--text-white);
    border-radius: 3px;
}
.blog__four-item-content {
    max-width: 270px;
}
.blog__four-item-content ul {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--border-color-1);
    margin-bottom: 15px;
    padding-bottom: 8px;
}
.blog__four-item-content ul li {
    display: inline-flex;
    list-style: none;
    font-weight: 500;
    font-family: var(--heading-font);
    align-items: center;
    gap: 10px;
}
.blog__four-item-content ul li i {
    color: var(--primary-color-1);
    font-size: 20px;
}
.blog__four-item-content h4 {
    margin-bottom: 5px;
}
.blog__four-item-content h4 a {
    transition: all 0.4s ease-out 0s;
}
.blog__four-item-content h4 a:hover {
    color: var(--primary-color-1);
}
.blog__four-item-content > a {
    color: var(--primary-color-1);
    font-weight: 600;
    font-family: var(--heading-font);
    display: inline-flex;
    align-items: center;
    gap: 10px;
}
.blog__four-item-content > a i {
    margin-top: 2px;
}
.blog__four-item:hover .blog__four-item-image img {
    transform: scale(1.05);
}
.blog__four-item:hover .blog__four-item-image::before {
    animation: circle 0.9s;
}

/*==========================================================================
* Blog Standard CSS
========================================================================== */
.blog__standard-left-item {
    margin-bottom: 40px;
}
.blog__standard-left-item:last-child {
    margin: 0;
}
.blog__standard-left-item-image {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.blog__standard-left-item-image::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 0;
    display: block;
    content: "";
    width: 30%;
    height: 100%;
    transform: skewX(-25deg);
    background: var(--text-white);
    opacity: 0.4;
    z-index: 1;
}
.blog__standard-left-item-image img {
    width: 100%;
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}
.blog__standard-left-item-image img:hover {
    transform: scale3d(1.1, 1.1, 1.1);
}
.blog__standard-left-item-image:hover::before {
    animation: animation 0.95s;
}
.blog__standard-left-item-content {
    padding: 40px;
    box-shadow: var(--box-shadow-1);
}
.blog__standard-left-item-content-meta {
    margin-bottom: 15px;
}
.blog__standard-left-item-content-meta ul {
    padding: 0;
    margin: 0;
}
.blog__standard-left-item-content-meta ul li {
    display: inline-block;
    margin-right: 30px;
    list-style: none;
}
.blog__standard-left-item-content-meta ul li:last-child {
    margin: 0;
}
.blog__standard-left-item-content-meta ul li a {
    transition: 0.4s;
}
.blog__standard-left-item-content-meta ul li a i {
    color: var(--primary-color-1);
    margin-right: 10px;
}
.blog__standard-left-item-content-meta ul li a:hover {
    color: var(--primary-color-1);
}
.blog__standard-left-item-content h3 a {
    transition: 0.4s;
}
.blog__standard-left-item-content h3 a:hover {
    color: var(--primary-color-1);
}
.blog__standard-left-item-content p {
    margin-bottom: 35px;
}

/*==========================================================================
* Blog Single CSS
==========================================================================*/
.blog__details-left {
    padding: 40px;
    border: 1px solid var(--border-color-1);
}
.blog__details-left-meta {
    margin-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--border-color-1);
}
.blog__details-left-meta ul {
    padding: 0;
    margin: 0;
}
.blog__details-left-meta ul li {
    padding: 0;
    display: inline-block;
    list-style: none;
    margin-right: 30px;
    color: var(--color-2);
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    font-family: var(--heading-font);
}
.blog__details-left-meta ul li:last-child {
    margin: 0;
}
.blog__details-left-meta ul li a {
    transition: 0.4s;
}
.blog__details-left-meta ul li a i {
    color: var(--primary-color-1);
    margin-right: 10px;
    font-size: 18px;
}
.blog__details-left-meta ul li a:hover {
    color: var(--primary-color-1);
}
.blog__details-left-box {
    text-align: center;
    margin: 30px 0;
    background: var(--color-4);
    padding: 40px;
    position: relative;
    z-index: 1;
    border-left: 3px solid var(--primary-color-1);
}
.blog__details-left-box p {
    color: var(--text-heading-color);
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    max-width: 670px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.blog__details-left-box span {
    color: var(--primary-color-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--heading-font);
}
.blog__details-left-tag h6 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
}
.blog__details-left-tag ul {
    padding: 0;
    margin: 0;
    margin-left: -3px;
    margin-top: -1px;
}
.blog__details-left-tag ul li {
    display: inline-block;
    list-style: none;
}
.blog__details-left-tag ul li a {
    transition: 0.4s;
    border: 1px solid var(--border-color-1);
    margin: 1px 3px;
    overflow: hidden;
    padding: 4px 20px 5px 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    border-radius: 4px;
}
.blog__details-left-tag ul li a:hover {
    color: var(--text-white);
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
}
.blog__details-left-share h6 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
}
.blog__details-left-share ul {
    padding: 0;
    margin: 0;
}
.blog__details-left-share ul li {
    list-style: none;
    display: inline-block;
    margin-right: 8px;
}
.blog__details-left-share ul li:last-child {
    margin: 0;
}
.blog__details-left-share ul li a i {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: var(--color-4);
    font-size: 12px;
    border-radius: 50%;
    color: var(--text-heading-color);
    transition: 0.4s;
}
.blog__details-left-share ul li a i:hover {
    background: var(--primary-color-1);
    color: var(--text-white);
}
.blog__details-left-comment-item {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--border-color-2);
}
.blog__details-left-comment-item-comment-image {
    float: left;
}
.blog__details-left-comment-item-comment-image img {
    max-width: 80px;
    width: 80px;
    height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}
.blog__details-left-comment-item-comment-content {
    padding-left: 30px;
    overflow: hidden;
}
.blog__details-left-comment-item-comment-content span {
    font-size: 14px;
    margin-bottom: 15px;
    display: inline-block;
}
.blog__details-left-comment-item-comment-content h6 {
    font-size: 18px;
    line-height: 28px;
}
.blog__details-left-comment-item-comment-content h6 a {
    float: right;
    transition: 0.4s;
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-heading-color);
    border: 1px solid var(--border-color-1);
    padding: 7px 18px;
    border-radius: 4px;
}
.blog__details-left-comment-item-comment-content h6 a i {
    margin-right: 10px;
}
.blog__details-left-comment-item-comment-content h6 a:hover {
    color: var(--text-white);
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
}
.blog__details-left-comment-item-comment-content p {
    max-width: 580px;
}
.blog__details-left-contact-form-item input {
    background: transparent;
}
.blog__details-left-contact-form-item textarea {
    height: 220px;
    background: transparent;
}
.blog__details-pagination {
    padding: 40px 0;
    border-top: 1px solid var(--border-color-1);
    border-bottom: 1px solid var(--border-color-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog__details-pagination-item a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.blog__details-pagination-item a h6 {
    transition: 0.4s;
}
.blog__details-pagination-item a:hover h6 {
    color: var(--primary-color-1);
}
.blog__details-pagination-item-left {
    display: flex;
    align-items: center;
    gap: 20px;
}
.blog__details-pagination-item-icon {
    text-align: center;
}
.blog__details-pagination-item-icon i {
    line-height: 0;
    font-size: 25px;
    color: var(--primary-color-1);
}

@media (max-width: 1399px) {
    .blog__one-item-content {
        padding: 20px 35px;
    }
    .blog__one-item-btn a {
        padding: 16px 35px;
    }
    .blog__three-item {
        padding: 40px 35px 32px 35px;
    }
}
@media (max-width: 1199px) {
    .blog__details-left {
        padding: 40px 25px;
    }
    .blog__details-pagination-item a h6 {
        font-size: 16px;
        line-height: 26px;
    }
    .blog__details-pagination-item-left {
        gap: 15px;
    }
    .blog__details-pagination-item-left img {
        width: 70px;
        height: 70px;
        max-width: 70px;
        -o-object-fit: cover;
        object-fit: cover;
    }
}
@media (max-width: 767px) {
    .blog__details-pagination {
        display: flex;
        gap: 10px;
        justify-content: space-between;
    }
    .blog__details-pagination-item.center {
        display: none;
    }
    .blog__four-item {
        gap: 20px;
    }
    .blog__four-item-image img {
        max-width: 230px;
    }
}
@media (max-width: 575px) {
    .blog__details-left {
        padding: 30px 20px;
    }
    .blog__details-left-comment-item {
        margin-left: 0;
    }
    .blog__details-left-comment-item-comment-image {
        float: initial;
        margin-bottom: 20px;
    }
    .blog__details-left-comment-item-comment-content {
        padding-left: 0;
    }
    .blog__four-item {
        display: block;
    }
    .blog__four-item-image img {
        width: 100%;
        max-width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        height: 280px;
    }
    .blog__four-item-content {
        padding: 20px 30px;
        max-width: initial;
    }
}
@media (max-width: 460px) {
    .blog__one-item-content {
        padding: 20px 28px;
    }
    .blog__one-item-btn a {
        padding: 16px 28px;
    }
    .blog__two-item-content {
        padding: 30px 25px;
    }
    .blog__two-item-content-author-post-right a {
        padding: 3px 10px;
        gap: 6px;
    }
    .blog__two-item-btn a {
        padding: 15px 25px;
    }
    .blog__details-left-box {
        padding: 30px 20px;
    }
    .blog__details-left-box p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .blog__details-pagination-item a h6 {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
    }
    .blog__details-pagination-item-left {
        gap: 10px;
    }
    .blog__details-pagination-item-left img {
        width: 50px;
        height: 50px;
        max-width: 50px;
    }
    .blog__three-item {
        padding: 30px 25px 22px 25px;
    }
    .blog__three-item-meta ul li a {
        margin-right: 10px;
        padding-right: 10px;
    }
    .blog__three-item-btn {
        padding-top: 20px;
    }
    .blog__four-item-image img {
        height: 210px;
    }
    .blog__four-item-content {
        padding: 20px;
    }
}
@media (max-width: 359px) {
    .blog__details-left {
        padding: 30px 16px;
    }
    .blog__details-left-meta ul li {
        margin-right: 16px;
    }
    .blog__one-item-content {
        padding: 20px 20px;
    }
    .blog__one-item-btn a {
        padding: 16px 20px;
    }
    .blog__two-item-content {
        padding: 30px 20px;
    }
    .blog__two-item-btn a {
        padding: 15px 20px;
    }
    .blog__three-item {
        padding: 30px 20px 22px 20px;
    }
    .blog__three-item-meta {
        padding: 11px 10px;
    }
    .blog__three-item-meta ul li a {
        margin-right: 5px;
        padding-right: 8px;
    }
}
/*==========================================================================
* About Page CSS
==========================================================================*/
.about__four {
    position: relative;
    z-index: 1;
}
.about__four-left-image {
    position: relative;
    padding-bottom: 80px;
    display: flex;
    z-index: 1;
}
.about__four-left-image-shape {
    position: absolute;
    left: -50px;
    top: 0;
    z-index: -1;
    opacity: 0.08;
    max-width: 80%;
    animation: upDown 5s linear infinite;
}
.about__four-left-image-shape-one {
    position: absolute;
    right: 40px;
    top: 15%;
}
.about__four-left-image-two {
    position: absolute;
    right: 0;
    bottom: 0;
}
.about__four-left-image-two img {
    border: 10px solid var(--color-5);
}
.about__four-right {
    margin-left: 65px;
}
.about__four-right-title {
    margin-bottom: 30px;
}
.about__four-right-title h2 {
    margin-bottom: 20px;
}
.about__four-right-title p {
    max-width: 510px;
    margin: 0;
}
.about__four-right-bottom {
    margin-right: 50px;
}
.about__four-right-btn {
    display: flex;
    gap: 30px;
    margin-top: 40px;
}
.about__four-right-btn-founder {
    display: flex;
    gap: 20px;
    align-items: center;
}
.about__four-right-btn-founder img {
    max-width: 100%;
    width: 60px;
    height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}

.counter__three .counter__two-item-icon i {
    color: var(--primary-color-1);
}

/*==========================================================================
* About Company CSS
==========================================================================*/
.company__two {
    position: relative;
    z-index: 1;
}
.company__two-left-title h2 {
    max-width: 500px;
    margin-bottom: 20px;
}
.company__two-left-title p {
    max-width: 510px;
}
.company__two-left-skill {
    margin-top: 35px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    gap: 35px;
}
.company__two-left-skill-item {
    display: flex;
    align-items: center;
    gap: 20px;
}
.company__two-left-skill-item h6 {
    font-family: var(--body-font);
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    max-width: 145px;
    text-transform: capitalize;
}

@media (max-width: 1199px) {
    .about__four-right {
        margin: 0;
    }
    .about__four-left {
        margin: 0;
    }
}
@media (max-width: 991px) {
    .about__four-left-image {
        padding-bottom: 70px;
    }
    .about__four-right-bottom {
        margin-right: 0;
    }
}
@media (max-width: 510px) {
    .company__two-left-skill {
        margin-top: 30px;
        margin-bottom: 35px;
        display: block;
    }
    .company__two-left-skill-item {
        margin-bottom: 15px;
    }
    .company__two-left-skill-item:last-child {
        margin: 0;
    }
    .about__four-left-image img {
        max-width: 260px;
    }
}
@media (max-width: 490px) {
    .about__four-right-btn {
        display: block;
    }
    .about__four-right-btn-founder {
        margin-top: 30px;
    }
}
/*==========================================================================
* Services Page CSS
==========================================================================*/
.services__page {
    background: var(--color-4);
}
.services__page-two {
    background: var(--bg-white);
}
.services__page-two
    .services__three-items
    .two
    .services__three-item-icon::after,
.services__page-two .services__three-items {
    border-color: var(--primary-color-1);
}
.services__page-two .services__three-item-icon i {
    background: var(--primary-color-1);
}
.services__page-two
    .services__three-items
    .two
    .services__three-item-content
    h4
    a:hover {
    color: var(--primary-color-1);
}
.services__page .services__one-item-content-icon,
.services__page .services__one-item::before {
    background: var(--primary-color-1);
}
.services__page .services__one-item:hover .services__one-item-content-icon i {
    color: var(--primary-color-1);
}

.features__two.page::before {
    display: none;
}

.video__two {
    position: relative;
    z-index: 1;
}
.video__two-bg {
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 250px 0;
}
.video__two-bg::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    opacity: 0.6;
}
.video__two-area {
    text-align: center;
}
.video__two-area a {
    position: relative;
    z-index: 9;
    background: var(--text-white);
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    color: var(--text-white);
    display: inline-block;
    color: var(--primary-color-1);
}
.video__two-area a::after,
.video__two-area a::before {
    background: var(--text-white);
    z-index: -1;
}

.testimonial__one.page {
    background: var(--bg-white);
    padding-bottom: 120px;
}
.testimonial__one.page .testimonial__one-title h6 {
    border-color: var(--primary-color-1);
    box-shadow: var(--box-shadow-1);
}
.testimonial__one.page .testimonial__one-title h6 span {
    color: var(--primary-color-1);
}
.testimonial__one.page .testimonial__one-title .btn-one {
    background: var(--bg-heading-color);
}
.testimonial__one.page .testimonial__one-title .btn-one::after,
.testimonial__one.page .testimonial__one-title .btn-one::before {
    background: var(--primary-color-1);
}
.testimonial__one.page .testimonial__one-item-bottom i {
    color: var(--primary-color-1);
}
.testimonial__one.page .testimonial__one-pagination .swiper-slide-active img {
    border-color: var(--primary-color-1);
}

/*==========================================================================
* Noxiy Contact CSS
==========================================================================*/
.contact-item {
    position: relative;
}
.contact-item span {
    position: absolute;
    right: 16px;
    top: 23px;
    color: var(--primary-color-1);
    font-weight: 400;
}

/*==========================================================================
* Contact One CSS
==========================================================================*/
.contact__page-item {
    padding: 40px 30px;
    box-shadow: var(--box-shadow-1);
    text-align: center;
    border-radius: 10px;
    transition: 0.4s;
}
.contact__page-item img {
    max-width: 60px;
}
.contact__page-item h4 {
    margin-top: 25px;
    margin-bottom: 5px;
}
.contact__page-item p {
    margin: 0 auto;
    max-width: 235px;
}
.contact__page-item p a {
    text-transform: lowercase;
    display: block;
    transition: 0.4s;
}
.contact__page-item p a:hover {
    color: var(--primary-color-1);
}
.contact__page-item:hover {
    transform: translateY(-6px);
}
.contact__page-map {
    z-index: 1;
    position: relative;
    margin-bottom: -190px;
}
.contact__page-map iframe {
    width: 100%;
    height: 600px;
    display: block;
    filter: grayscale(1);
}

/*==========================================================================
* Contact Two CSS
==========================================================================*/
.contact__two {
    position: relative;
    z-index: 2;
}
.contact__two-form {
    background: var(--color-4);
    padding: 70px;
}
.contact__two-form-item textarea {
    height: 180px;
}
.contact__two-info-item {
    display: flex;
    margin-bottom: 40px;
    gap: 40px;
}
.contact__two-info-item:last-child {
    margin: 0;
}
.contact__two-info-item h6 {
    display: flex;
    gap: 40px;
}
.contact__two-info-item > span {
    max-width: 290px;
    margin-top: 1px;
}
.contact__two-info-item > span a {
    text-transform: lowercase;
    display: block;
    transition: 0.4s;
}
.contact__two-info-item > span a:hover {
    color: var(--primary-color-1);
}
.contact__two-map {
    margin-top: -180px;
    z-index: 1;
    position: relative;
}
.contact__two-map iframe {
    width: 100%;
    height: 500px;
    display: block;
    filter: grayscale(1);
}

/*==========================================================================
* Contact Three CSS
==========================================================================*/
.contact__three-info {
    padding: 35px 30px;
    transition: 0.4s;
    box-shadow: var(--box-shadow-1);
    position: relative;
    z-index: 1;
}
.contact__three-info-icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    border-radius: 50%;
    background: var(--primary-color-1);
    text-align: center;
    transition: 0.4s;
    position: relative;
    margin: 10px;
    margin-bottom: 30px;
}
.contact__three-info-icon::after {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    border: 1px dashed var(--primary-color-1);
    border-radius: 50%;
    display: block;
    top: -10px;
    left: -10px;
    animation: rollIn 10s infinite linear;
}
.contact__three-info-icon img {
    max-width: 25px;
    transform: rotateY(0);
}
.contact__three-info p {
    text-transform: lowercase;
    font-weight: 500;
    transition: 0.4s;
    color: var(--color-2);
    margin: 0;
}
.contact__three-info p a {
    transition: 0.4s;
}
.contact__three-info p a:hover {
    color: var(--primary-color-1);
}
.contact__three-info h4 {
    transition: 0.4s;
}
.contact__three-info span {
    transition: 0.4s;
    margin-top: 5px;
    font-size: 15px;
    line-height: 25px;
    color: var(--color-2);
    margin-bottom: 15px;
    display: block;
}
.contact__three-info:hover {
    background: var(--bg-heading-color);
}
.contact__three-info:hover h4 {
    color: var(--text-white);
}
.contact__three-info:hover p,
.contact__three-info:hover span {
    color: #b0b0bd;
}
.contact__three-info:hover .contact__three-info-icon img {
    animation: rotateY 0.6s;
}
.contact__three-info:hover .contact__three-info-icon::after {
    animation: initial;
}
.contact__three-form {
    padding: 70px 50px;
    box-shadow: var(--box-shadow-2);
    background: var(--bg-white);
    position: relative;
    z-index: 2;
}
.contact__three-form-title {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 60px;
}
.contact__three.page .contact__three-form {
    box-shadow: none;
    background: var(--color-4);
}

@media (max-width: 1399px) {
    .contact__two-form {
        padding: 60px 50px;
    }
    .contact-box {
        padding: 60px 40px;
    }
}
@media (max-width: 1199px) {
    .contact__two-form {
        padding: 60px 40px;
    }
    .contact__two-info-item {
        gap: 25px;
    }
    .contact__two-info-item h6 {
        gap: 25px;
    }
}
@media (max-width: 991px) {
    .contact__two-form {
        padding: 60px 40px;
    }
    .contact__two-map {
        margin-top: -160px;
    }
    .request__quote-page-left {
        padding: 0;
        box-shadow: none;
    }
}
@media (max-width: 767px) {
    .request__quote-page-right {
        padding: 20px;
        padding-bottom: 80px;
    }
    .request__quote-page-right-item h5 {
        font-weight: 400;
    }
    .request__quote-page-right-item .swiper-pagination {
        bottom: 20px;
    }
}
@media (max-width: 530px) {
    .contact__two-info-item {
        gap: 15px;
    }
    .contact__two-info-item h6 {
        gap: 15px;
    }
    .contact__two-form {
        padding: 40px 20px;
    }
    .contact__three-form {
        padding: 40px 20px;
    }
}
@media (max-width: 485px) {
    .contact__two-info-item {
        display: block;
    }
    .contact__two-map {
        margin-top: -140px;
    }
}
/*==========================================================================
* Text Slider CSS
==========================================================================*/
.text__slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: var(--primary-color-2);
    padding: 80px 0;
    direction: ltr;
}
.text__slider .text-slide {
    --gap: 0.1px;
    display: flex;
    gap: var(--gap);
}
.text__slider ul {
    padding: 0;
    margin: 0;
}
.text__slider ul li {
    display: inline-block;
}
.text__slider ul li span {
    margin: 0 40px;
    color: var(--text-white);
    opacity: 0.5;
}
.text__slider ul li h2 {
    color: var(--text-white);
    font-family: var(--body-font);
    font-weight: 400;
    font-size: 40px;
}
.text__slider.two {
    background: var(--primary-color-1);
    padding: 30px 0;
}
.text__slider.two ul li h2 {
    color: var(--text-white);
}
.text__slider.two ul li h2 a {
    color: #ffffff;
}
.text__slider.two ul li span {
    color: var(--text-heading-color);
    opacity: 0.6;
}

@media (max-width: 575px) {
    .text__slider ul li h2 {
        font-size: 28px;
    }
}
/*==========================================================================
* Who We CSS
==========================================================================*/
.who__area {
    overflow: hidden;
    background: var(--bg-white);
    position: relative;
    z-index: 1;
}
.who__area-shape {
    position: absolute;
    right: 0;
    top: -200px;
    z-index: -1;
}
.who__area-left-title h2 {
    margin-bottom: 20px;
}
.who__area-left-title p {
    margin: 0;
}
.who__area-left-list {
    margin-top: 30px;
}
.who__area-left-list p {
    color: var(--text-heading-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.who__area-left-list p:last-child {
    margin: 0;
}
.who__area-left-list p i {
    color: var(--text-white);
    width: 20px;
    height: 20px;
    background: var(--primary-color-1);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: relative;
    top: 1px;
}
.who__area-left .btn-one {
    background: var(--primary-color-1);
    margin-top: 40px;
}
.who__area-right-image {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: flex-end;
}
.who__area-right-image-shape {
    position: absolute;
    right: 10%;
    top: 10px;
    z-index: -1;
    animation: rollIn 20s infinite linear;
    max-width: 58%;
}
.who__area-right-image-shape-one {
    position: absolute;
    right: 0;
    top: 0;
    animation-duration: 3s;
}

@media (max-width: 1399px) {
    .who__area-left {
        margin-right: 65px;
    }
}
@media (max-width: 1199px) {
    .who__area-left {
        margin-right: 0;
    }
}
/*==========================================================================
* All Side Bar CSS
==========================================================================*/
.all__sidebar {
    position: sticky;
    top: 140px;
}
.all__sidebar-item {
    padding: 40px;
    background: var(--color-4);
    border: 1px solid var(--border-color-4);
    margin-bottom: 30px;
}
.all__sidebar-item:last-child {
    margin: 0;
}
.all__sidebar-item h4 {
    margin-bottom: 30px;
}
.all__sidebar-item-search form {
    display: flex;
    position: relative;
}
.all__sidebar-item-search form input {
    border-color: var(--bg-white);
}
.all__sidebar-item-search form button {
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 6px;
}
.all__sidebar-item-post .post__item {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color-3);
}
.all__sidebar-item-post .post__item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}
.all__sidebar-item-post .post__item-image img {
    width: 90px;
    height: 90px;
    max-width: 90px;
    border-radius: 0;
}
.all__sidebar-item-post .post__item-title h6 a {
    color: var(--text-heading-color);
}
.all__sidebar-item-post .post__item-title h6 a:hover {
    color: var(--primary-color-1);
}
.all__sidebar-item-post .post__item-title span {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}
.all__sidebar-item-post .post__item-title span i {
    color: var(--primary-color-1);
}
.all__sidebar-item-tag ul {
    padding: 0;
    margin: 0;
    margin-left: -3px;
    margin-top: -1px;
}
.all__sidebar-item-tag ul li {
    display: inline-block;
    list-style: none;
}
.all__sidebar-item-tag ul li a {
    transition: 0.4s;
    border: 1px solid var(--border-color-1);
    margin: 1px 3px;
    overflow: hidden;
    padding: 4px 20px 5px 20px;
    display: inline-block;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    border-radius: 4px;
}
.all__sidebar-item-tag ul li a:hover {
    color: var(--text-white);
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
}
.all__sidebar-item-solution ul {
    padding: 0;
    margin: 0;
}
.all__sidebar-item-solution ul li {
    list-style: none;
}
.all__sidebar-item-solution ul li .active {
    color: var(--text-white);
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
    padding-left: 20px;
}
.all__sidebar-item-solution ul li .active i {
    opacity: 1;
    margin-left: 0;
}
.all__sidebar-item-solution ul li a {
    display: block;
    transition: 0.4s;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding: 16px 20px;
    padding-left: 0;
    border-bottom: 1px solid var(--border-color-1);
    overflow: hidden;
    border-radius: 6px;
    position: relative;
}
.all__sidebar-item-solution ul li a i {
    font-size: 14px;
    margin-right: 10px;
    opacity: 0;
    margin-left: -20px;
    transition: 0.4s;
}
.all__sidebar-item-solution ul li a span {
    float: right;
    position: relative;
    top: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
.all__sidebar-item-solution ul li a::before {
    content: "\f324";
    color: var(--text-white);
    font-size: 14px;
    position: absolute;
    opacity: 0;
    transition: 0.5s;
    left: 10px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
}
.all__sidebar-item-solution ul li a:hover {
    color: var(--text-white);
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
    padding-left: 40px;
}
.all__sidebar-item-solution ul li a:hover::before {
    opacity: 1;
    left: 20px;
}
.all__sidebar-item-solution ul li:last-child a {
    margin-bottom: 0;
}
.all__sidebar-item-download ul {
    padding: 0;
    margin: 0;
}
.all__sidebar-item-download ul li {
    list-style: none;
}
.all__sidebar-item-download ul li a {
    transition: 0.4s;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    background: var(--bg-white);
    padding: 15px 20px;
    border-radius: 6px;
    justify-content: space-between;
    color: var(--text-heading-color);
    font-weight: 500;
}
.all__sidebar-item-download ul li a > div {
    display: flex;
    align-items: center;
}
.all__sidebar-item-download ul li a span {
    float: right;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: var(--primary-color-1);
    text-align: center;
    border-radius: 4px;
    color: var(--text-white);
}
.all__sidebar-item-download ul li a i {
    margin-right: 18px;
    padding-right: 10px;
    border-right: 1px solid var(--border-color-1);
    color: var(--primary-color-1);
    font-size: 25px;
    line-height: 0;
}
.all__sidebar-item-download ul li a:hover {
    color: var(--primary-color-1);
}
.all__sidebar-item-download ul li:last-child a {
    margin: 0;
}
.all__sidebar-item-help {
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    padding-top: 350px;
    overflow: hidden;
}
.all__sidebar-item-help::before {
    content: "";
    position: absolute;
    right: -60px;
    bottom: -30px;
    width: 150px;
    height: 150px;
    border: 26px solid var(--primary-color-1);
    border-radius: 50%;
    animation: upDown 5s linear infinite;
    opacity: 0.7;
    z-index: 0;
}
.all__sidebar-item-help-shape {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
}
.all__sidebar-item-help-icon {
    margin-bottom: 35px;
}
.all__sidebar-item-help-icon i {
    width: 90px;
    height: 90px;
    display: inline-flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--text-white);
    animation: icon-animation 2.4s ease-in-out infinite;
    color: var(--primary-color-1);
}
.all__sidebar-item-help h4 {
    color: var(--text-white);
    max-width: 210px;
    margin-bottom: 30px;
}
.all__sidebar-item-help-contact {
    display: flex;
    align-items: center;
    gap: 17px;
}
.all__sidebar-item-help-contact i {
    color: var(--text-white);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color-1);
    font-size: 25px;
    animation: icon-animation 2s ease-in-out infinite;
}
.all__sidebar-item-help-contact-content span {
    color: var(--text-white);
    font-weight: 500;
    font-family: var(--heading-font);
    display: inline-block;
}
.all__sidebar-item-help-contact-content h6 {
    color: var(--text-white);
}
.all__sidebar-item-help-contact-content h6 a {
    transition: 0.4s;
}
.all__sidebar-item-help-contact-content h6 a:hover {
    color: var(--primary-color-1);
}
.all__sidebar-item-info h4 {
    margin-bottom: 5px;
}
.all__sidebar-item-info p {
    margin-bottom: 25px;
}
.all__sidebar-item-info-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}
.all__sidebar-item-info-item:last-child {
    margin: 0;
}
.all__sidebar-item-info-item-icon i {
    width: 50px;
    height: 50px;
    background: var(--primary-color-1);
    color: var(--text-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 400;
}
.all__sidebar-help-image-content {
    box-shadow: var(--box-shadow-1);
    padding: 30px;
    margin: 0 40px;
    margin-top: -190px;
    background: var(--bg-white);
    position: relative;
    text-align: center;
}
.all__sidebar-help-image-content h4 {
    margin-top: 6px;
    margin-bottom: 26px;
}

/*==========================================================================
* Post CSS
==========================================================================*/
.post__item {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 20px;
}
.post__item:last-child {
    margin: 0;
}
.post__item-image img {
    width: 80px;
    height: 85px;
    max-width: 85px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}
.post__item-title span {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    font-family: var(--heading-font);
}
.post__item-title h6 {
    font-size: 18px;
    line-height: 28px;
    max-width: 200px;
}
.post__item-title h6 a {
    color: var(--text-white);
    transition: 0.4s;
}
.post__item-title h6 a:hover {
    color: var(--primary-color-1);
}

/*==========================================================================
* Theme Pagination CSS
==========================================================================*/
.theme__pagination ul {
    margin: 0;
    padding: 0;
    margin-left: -5px;
}
.theme__pagination ul li {
    list-style: none;
    display: inline-block;
    margin: 5px;
}
.theme__pagination ul li .active {
    background: var(--primary-color-1);
    color: var(--text-white);
    border-color: var(--primary-color-1);
}
.theme__pagination ul li a {
    font-family: var(--heading-font);
    border: 1px solid var(--border-color-1);
    display: block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    transition: 0.4s;
    border-radius: 3px;
}
.theme__pagination ul li a:hover {
    background: var(--primary-color-1);
    color: var(--text-white);
    border-color: var(--primary-color-1);
}
.theme__pagination ul li a i {
    font-size: 14px;
}

@media (max-width: 1399px) {
    .all__sidebar-item {
        padding: 35px 30px;
    }
    .all__sidebar-item-post .post__item {
        gap: 20px;
    }
    .all__sidebar-item-post .post__item-image img {
        width: 85px;
        height: 85px;
        max-width: 85px;
    }
    .all__sidebar-item-post .post__item .post__item-title h6 {
        font-size: 17px;
    }
    .all__sidebar-item-tag ul li a {
        padding: 4px 15px 5px 15px;
    }
}
@media (max-width: 1199px) {
    .all__sidebar-item {
        padding: 35px 25px;
    }
    .all__sidebar-item-solution ul li {
        list-style: none;
    }
    .all__sidebar-item-solution ul li .active {
        padding-left: 15px;
    }
    .all__sidebar-item-solution ul li a {
        padding: 16px 15px;
    }
    .all__sidebar-item-solution ul li a i {
        margin-right: 8px;
        margin-left: -15px;
    }
    .all__sidebar-item-solution ul li a:hover {
        padding-left: 15px;
    }
    .all__sidebar-item-post .post__item {
        gap: 15px;
    }
    .all__sidebar-item-post .post__item-image img {
        width: 70px;
        height: 70px;
        max-width: 70px;
    }
    .all__sidebar-item-post .post__item .post__item-title h6 {
        font-size: 16px;
    }
    .all__sidebar-item-tag ul li a {
        padding: 4px 10px 5px 10px;
    }
    .all__sidebar-item-download ul li a {
        padding: 15px;
    }
    .all__sidebar-item-download ul li a i {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
    }
    .all__sidebar-item-download ul li a img {
        margin-right: 10px;
    }
    .post__item {
        gap: 20px;
    }
    .post__item-title h6 {
        font-size: 17px;
        line-height: 27px;
    }
}
@media (max-width: 475px) {
    .all__sidebar-item-help h4 {
        max-width: 182px;
    }
}
/*==========================================================================
* Subscribe Area CSS
==========================================================================*/
.subscribe__area {
    background: var(--color-18);
    padding: 70px 0;
}
.subscribe__area-title h3 {
    color: var(--text-white);
    max-width: 430px;
}
.subscribe__area-form form {
    position: relative;
    z-index: 1;
}
.subscribe__area-form form input {
    background: var(--color-18);
    border-color: var(--color-1);
    color: var(--color-3);
    border-radius: 35px;
    height: 72px;
}
.subscribe__area-form form input:focus {
    color: var(--color-3);
    border-color: var(--primary-color-2);
}
.subscribe__area-form form .btn-two {
    position: absolute;
    right: 7px;
    top: 7px;
}
.subscribe__area-form form .btn-two::after,
.subscribe__area-form form .btn-two::before {
    background: var(--bg-white);
}
.subscribe__area-form form .btn-two:hover {
    color: var(--text-heading-color);
}

/*==========================================================================
* Subscribe Three CSS
==========================================================================*/
.subscribe__three {
    position: relative;
    z-index: 1;
    margin-bottom: 70px;
}
.subscribe__three::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: var(--bg-white);
}
.subscribe__three-bg {
    position: relative;
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 85px 70px;
    overflow: hidden;
    margin: 0;
    border-radius: 6px;
}
.subscribe__three-bg::after,
.subscribe__three-bg::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-heading-color);
    opacity: 0.5;
    z-index: -1;
}
.subscribe__three-bg::after {
    background: var(--primary-color-3);
    mix-blend-mode: multiply;
    z-index: -2;
    opacity: 1;
}
.subscribe__three-left h2 {
    color: var(--text-white);
    max-width: 440px;
}
.subscribe__three-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}
.subscribe__three-right .btn-one {
    background: var(--bg-white);
    color: var(--primary-color-3);
}
.subscribe__three-right .btn-one:hover {
    color: var(--text-white);
}
.subscribe__three-right .btn-one:hover::after,
.subscribe__three-right .btn-one:hover::before {
    background: var(--primary-color-3);
}
.subscribe__three-right-contact {
    display: flex;
    align-items: center;
    gap: 20px;
}
.subscribe__three-right-contact i {
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-white);
    color: var(--primary-color-3);
    border-radius: 50%;
    animation: icon-animation 2s ease-in-out infinite;
    font-size: 25px;
}
.subscribe__three-right-contact-info span {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--heading-font);
    color: var(--color-3);
}
.subscribe__three-right-contact-info h6 a {
    color: var(--text-white);
}

@media (max-width: 1199px) {
    .subscribe__three-left h2 {
        margin: 0 auto;
        text-align: center;
    }
    .subscribe__three-right {
        margin-top: 30px;
        justify-content: center;
    }
}
@media (max-width: 991px) {
    .subscribe__area-title h3 {
        margin: 0 auto;
        text-align: center;
    }
    .subscribe__area-form .btn-two {
        padding: 16px 10px;
    }
    .subscribe__three-bg {
        padding: 75px 30px;
    }
}
@media (max-width: 540px) {
    .subscribe__three-bg {
        padding: 50px 20px;
    }
    .subscribe__three-right {
        flex-direction: column;
    }
}
/*==========================================================================
* Page Banner CSS
==========================================================================*/
.page__banner {
    position: relative;
    z-index: 1;
    padding: 150px 0;
}
.page__banner-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.page__banner-content ul {
    padding: 0;
    margin: 0;
}
.page__banner-content ul li {
    display: inline-block;
    list-style: none;
    color: var(--primary-color-1);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}
.page__banner-content ul li a {
    color: var(--color-2);
    margin-right: 15px;
    transition: 0.4s;
}
.page__banner-content ul li a:hover {
    color: var(--primary-color-1);
}
.page__banner-content ul li span {
    margin-right: 15px;
    color: var(--color-2);
}
.page__banner-content h1 {
    color: var(--text-white);
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 15px;
    font-weight: 600;
}

/*==========================================================================
* Error CSS
==========================================================================*/
.error-page {
    text-align: center;
}
.error-page h1 {
    font-size: 240px;
    line-height: 240px;
    font-weight: 500;
}
.error-page h1 span {
    color: var(--primary-color-1);
}
.error-page h2 {
    margin: 10px 0 30px 0;
}
.error-page p {
    margin-bottom: 35px;
}

@media (max-width: 575px) {
    .page__banner {
        padding: 130px 0;
    }
    .page__banner-content h1 {
        font-size: 50px;
        line-height: 60px;
    }
    .page__banner-content ul li {
        font-size: 16px;
        line-height: 26px;
    }
    .error-page h1 {
        font-size: 160px;
        line-height: 160px;
    }
}
@media (max-width: 410px) {
    .page__banner-content h1 {
        font-size: 40px;
        line-height: 50px;
    }
}
@media (max-width: 359px) {
    .page__banner-content h1 {
        font-size: 36px;
        line-height: 46px;
    }
}
/*==========================================================================
* Footer Top Css
========================================================================== */
.footer__top {
    position: relative;
    z-index: 1;
}
.footer__top-area {
    background: var(--primary-color-4);
    border-radius: 10px;
    margin: 0;
    padding: 0 40px;
}
.footer__top-item-info {
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer__top-item-info-icon i {
    color: var(--text-white);
    border: 1px solid var(--color-11);
    font-size: 30px;
    width: 70px;
    height: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.footer__top-item-info-content span {
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-white);
}
.footer__top-item-info-content h5 a {
    color: var(--text-white);
}
.footer__top-item-logo {
    text-align: center;
}
.footer__top-item-logo a {
    display: inline-block;
    background: var(--bg-white);
    padding: 50px 60px;
    margin: -5px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-1);
}
.footer__top-item-logo a img {
    max-width: 150px;
    position: relative;
    z-index: 99;
}

/*==========================================================================
* Footer Menu Css
========================================================================== */
.footer__area-widget-menu ul {
    padding: 0;
    margin: 0;
}
.footer__area-widget-menu ul li {
    padding: 0;
    list-style: none;
    margin-bottom: 9px;
}
.footer__area-widget-menu ul li:last-child {
    margin: 0;
}
.footer__area-widget-menu ul li a {
    color: var(--color-2) !important;
    transition: 0.4s;
    font-weight: 500;
    position: relative;
}
.footer__area-widget-menu ul li a::before {
    content: "\f324";
    color: var(--primary-color-1) !important;
    font-size: 14px;
    position: absolute;
    opacity: 0;
    transition: 0.4s;
    left: 0px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
}
.footer__area-widget-menu ul li a:hover {
    color: var(--primary-color-1);
    padding-left: 20px;
}
.footer__area-widget-menu ul li a:hover::before {
    opacity: 1;
}
.footer__area-widget-menu.two ul li:last-child {
    margin: 0;
}
.footer__area-widget-menu.two ul li a {
    padding-left: 20px;
}
.footer__area-widget-menu.two ul li a::before {
    font-weight: 600;
    font-size: 7px;
    opacity: 1;
    top: -3px;
    color: var(--color-2);
    content: "\f111";
}
.footer__area-widget-menu.two ul li a:hover {
    color: var(--primary-color-2);
    margin-left: 7px;
}
.footer__area-widget-menu.two ul li a:hover::before {
    color: var(--primary-color-2);
}
.footer__area-widget-menu.three ul li a::before {
    color: var(--primary-color-3);
}
.footer__area-widget-menu.three ul li a:hover {
    color: var(--primary-color-3);
}
.footer__area-widget-menu.four ul li a::before {
    color: var(--primary-color-4);
}
.footer__area-widget-menu.four ul li a:hover {
    color: var(--primary-color-4);
}

/*==========================================================================
* Footer One CSS
==========================================================================*/
.footer__one {
    background: var(--bg-heading-color);
    padding-top: 70px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.footer__one-widget {
    margin-top: 30px;
}
.footer__one-widget h4 {
    color: var(--text-white);
    margin-bottom: 20px;
}
.footer__one-widget-about img {
    max-width: 160px;
}
.footer__one-widget-about p {
    color: var(--color-2);
    max-width: 304px;
    margin: 0;
    margin-top: 13px;
}
.footer__one-widget-about-social {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid var(--color-1);
    max-width: 300px;
}
.footer__one-widget-about-social h6 {
    color: var(--text-white);
    margin-bottom: 12px;
}
.footer__one-widget-about-social ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer__one-widget-about-social ul li {
    list-style: none;
}
.footer__one-widget-about-social ul li a i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-size: 14px;
    border: 1px solid var(--color-1);
    border-radius: 50%;
    color: var(--text-white);
    transition: 0.4s;
}
.footer__one-widget-about-social ul li a i:hover {
    background: var(--primary-color-2);
    border-color: var(--primary-color-2);
}
.footer__one-widget-menu {
    margin-left: -22px;
}
.footer__one-widget-location h6 {
    color: var(--text-white);
    margin-bottom: 9px;
}
.footer__one-widget-location-item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--color-1);
}
.footer__one-widget-location-item:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}
.footer__one-widget-location-item-icon i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    border: 1px solid var(--color-1);
    border-radius: 50%;
    color: var(--primary-color-2);
}
.footer__one-widget-location-item-info span,
.footer__one-widget-location-item-info a {
    color: var(--color-2);
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    max-width: 250px;
    display: inline-block;
    transition: 0.4s;
}
.footer__one-widget-location-item-info a:hover {
    color: var(--primary-color-2);
}
.footer__one-widget-gallery {
    margin-left: 40px;
}
.footer__one-widget-gallery-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

/*==========================================================================
* Footer Two CSS
==========================================================================*/
.footer__two {
    background: var(--bg-heading-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
    margin-top: 0px;
}
.footer__two-widget {
    margin-top: 30px;
}
.footer__two-widget h4 {
    color: var(--text-white);
    margin-bottom: 25px;
}
.footer__two-widget-about p {
    color: var(--color-2);
    max-width: 340px;
}
.footer__two-widget-about h6 {
    color: var(--text-white);
}
.footer__two-widget-about-social {
    margin-top: 15px;
}
.footer__two-widget-about-social ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer__two-widget-about-social ul li {
    list-style: none;
}
.footer__two-widget-about-social ul li a i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-size: 14px;
    background: var(--color-10);
    border-radius: 50%;
    color: var(--text-white);
    transition: 0.4s;
}
.footer__two-widget-about-social ul li a i:hover {
    background: var(--primary-color-4);
}
.footer__two-widget-subscribe p {
    color: var(--color-2);
}
.footer__two-widget-subscribe form {
    position: relative;
    margin-top: 30px;
}
.footer__two-widget-subscribe form input {
    background: transparent;
    color: var(--color-2);
    border-color: var(--color-9);
    background: var(--color-10);
    height: 60px;
}
.footer__two-widget-subscribe form input:focus {
    color: var(--color-2);
    border-color: var(--primary-color-4);
}
.footer__two-widget-subscribe form button {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    background: var(--primary-color-4);
}
.footer__two-widget.four .post__item-title h6 a:hover {
    color: var(--primary-color-4);
}

/*==========================================================================
* Footer Three CSS
==========================================================================*/
.footer__three {
    background: var(--bg-heading-color);
    background-position: bottom;
    background-repeat: no-repeat;
}
.footer__three-widget {
    margin-top: 30px;
}
.footer__three-widget h4 {
    color: var(--text-white);
    margin-bottom: 25px;
}
.footer__three-widget-company {
    margin-right: 90px;
}
.footer__three-widget-company p {
    margin: 0;
    color: var(--color-2);
}
.footer__three-widget-company form {
    position: relative;
    margin-top: 25px;
}
.footer__three-widget-company form input {
    background: transparent;
    color: var(--color-2);
    border-color: var(--color-1);
    background: var(--color-10);
    height: 60px;
}
.footer__three-widget-company form input:focus {
    color: var(--color-2);
    border-color: var(--primary-color-3);
}
.footer__three-widget-company form button {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    background: var(--primary-color-3);
}
.footer__three-widget-info-item {
    margin-bottom: 20px;
}
.footer__three-widget-info-item:last-child {
    margin: 0;
}
.footer__three-widget-info-item h6 {
    color: var(--text-white);
    margin-bottom: 3px;
}
.footer__three-widget-info-item a {
    color: var(--color-2);
    transition: 0.4s;
    display: block;
}
.footer__three-widget-info-item a:hover {
    color: var(--primary-color-3);
}

/*==========================================================================
* Footer Four CSS
==========================================================================*/
.footer__four {
    background: var(--color-18);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
}
.footer__four-widget h4 {
    margin-bottom: 25px;
    color: var(--text-white);
}
.footer__four-widget-about {
    margin-right: 30px;
}
.footer__four-widget-about p {
    color: var(--color-2);
    margin: 0;
}
.footer__four-widget-about-social {
    padding-top: 25px;
    margin-top: 20px;
    border-top: 1px solid var(--border-color-3);
}
.footer__four-widget-about-social ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.footer__four-widget-about-social ul li {
    list-style: none;
}
.footer__four-widget-about-social ul li a i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    font-size: 14px;
    border: 1px solid var(--border-color-3);
    border-radius: 50%;
    color: var(--text-white);
    transition: 0.4s;
}
.footer__four-widget-about-social ul li a i:hover {
    background: var(--primary-color-1);
    border-color: var(--primary-color-1);
}
.footer__four-widget .footer__one-widget-gallery-area.four {
    margin-right: 15px;
}
.footer__four-widget.four {
    margin-left: 15px;
}
.footer__four-widget-info p {
    color: var(--color-2);
    margin-bottom: 25px;
}
.footer__four-widget-info ul {
    padding: 0;
    margin: 0;
}
.footer__four-widget-info ul li {
    list-style: none;
    margin-bottom: 10px;
}
.footer__four-widget-info ul li:last-child {
    margin: 0;
}
.footer__four-widget-info ul li a {
    color: var(--color-2);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.4s;
}
.footer__four-widget-info ul li a i {
    color: var(--primary-color-1);
}
.footer__four-widget-info ul li a:hover {
    color: var(--primary-color-1);
}
.footer__four-top {
    display: grid;
    grid-template-columns: 0fr 1fr;
    gap: 35px;
    align-items: center;
    justify-content: space-between;
    background: var(--color-10);
    margin-bottom: 100px;
}
.footer__four-top-left {
    text-align: center;
}
.footer__four-top-left-logo {
    background: var(--primary-color-1);
    padding: 45px 40px;
}
.footer__four-top-left-logo a img {
    max-width: 160px;
}
.footer__four-top-right {
    display: flex;
    align-items: center;
    gap: 35px;
    padding-right: 35px;
    justify-content: space-between;
}
.footer__four-top-right-title h4 {
    color: var(--text-white);
    max-width: 350px;
}
.footer__four-top-right .btn-one {
    background: var(--primary-color-1);
}

@media (max-width: 1199px) {
    .footer__one-widget-menu {
        margin-left: 0;
    }
    .footer__one-widget-gallery {
        margin-left: 0;
    }
    .footer__top-area {
        padding: 0 20px;
    }
    .footer__three-widget-company {
        margin-right: 0;
    }
    .footer__four-widget-about,
    .footer__four-widget .footer__one-widget-gallery-area.four,
    .footer__four-widget.four {
        margin: 0;
    }
}
@media (max-width: 991px) {
    .footer__top-area {
        padding: 0 20px 50px 20px;
    }
    .footer__four-top {
        display: block;
        background: var(--color-21);
    }
    .footer__four-top-right {
        padding: 30px;
    }
}
@media (max-width: 767px) {
    .footer__four-top-right {
        display: block;
        text-align: center;
    }
    .footer__four-top-right-title h4 {
        margin: 0 auto;
        margin-bottom: 20px;
    }
}
@media (max-width: 520px) {
    .footer__top-item-logo a {
        padding: 35px 40px;
    }
    .footer__top-item-info-icon i {
        font-size: 20px;
        width: 50px;
        height: 50px;
    }
}
/*==========================================================================
* Copyright One CSS
==========================================================================*/
.copyright__one,
.copyright__two,
.copyright__three,
.copyright__four {
    margin-top: 100px;
    padding: 35px 0;
    text-align: center;
    border-top: 1px solid var(--color-10);
}
.copyright__one p,
.copyright__two p,
.copyright__three p,
.copyright__four p {
    margin: 0;
    color: var(--text-white);
    font-size: 15px;
    line-height: 26px;
}
.copyright__one p a,
.copyright__two p a,
.copyright__three p a,
.copyright__four p a {
    color: var(--primary-color-4);
    text-decoration: underline;
    margin: 0 2px;
}

/*==========================================================================
* Copyright Two CSS
==========================================================================*/
.copyright__two p a {
    color: var(--primary-color-2);
}

/*==========================================================================
* Copyright Three CSS
==========================================================================*/
.copyright__three p a {
    color: var(--primary-color-3);
}

/*==========================================================================
* Copyright Four CSS
==========================================================================*/
.copyright__four {
    border-color: var(--border-color-3);
}
.copyright__four p a {
    color: var(--primary-color-1);
}

/*==========================================================================
* Switch Tab CSS
==========================================================================*/
.switch__tab-icon {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    transition: all 0.3s;
}
.switch__tab-icon i {
    background: var(--primary-color-1);
    width: 50px;
    color: var(--text-white);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
.switch__tab-icon.two i {
    background: var(--primary-color-2);
}
.switch__tab-icon.three i {
    background: var(--primary-color-3);
}
.switch__tab-icon.four i {
    background: var(--primary-color-4);
}
.switch__tab-area {
    width: 260px;
    padding: 35px 25px;
    background: var(--bg-heading-color);
    border: 1px solid var(--bg-heading-color);
    position: fixed;
    left: -260px;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    transition: all 0.3s;
    direction: ltr;
}
.switch__tab-area-item {
    margin-bottom: 30px;
}
.switch__tab-area-item h5 {
    color: var(--text-white);
    margin-bottom: 10px;
}
.switch__tab-area-item-buttons,
.switch__tab-area-item-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.switch__tab-area-item-buttons button,
.switch__tab-area-item-button button {
    display: inline-block;
    padding: 4px 10px;
    color: #ddd;
    background: #2b2b2f;
    border-radius: 4px;
    text-transform: capitalize;
    opacity: 0.44;
    font-size: 15px;
    font-weight: 500;
}
.switch__tab-area-item-buttons button.active,
.switch__tab-area-item-button button.active {
    opacity: 1;
}
.switch__tab-area-item:last-child {
    margin: 0;
}
.switch__tab-open i::before {
    animation: rollIn 10s infinite linear;
}
.switch__tab-close {
    display: none;
}

/*==========================================================================
* Dark CSS
==========================================================================*/
.light-n {
    display: none;
}

.light {
    display: none;
}

.dark-mode {
    color: #909090;
    background: var(--dark-one);
    --bg-white: #181818;
    --text-heading-color: #ededed;
    --text-white: #ededed;
    --color-1: #ededed;
    --color-4: #0e0e0e;
    --color-5: #202123;
    --border-color-1: #2c2c2c;
    --border-color-2: #2c2c2c;
    --border-color-3: #2c2c2c;
    --border-color-4: #2c2c2c;
    --box-shadow-1: 0px 10px 50px rgba(0, 0, 0, 0.4);
}
.dark-mode .services__one-item-content-icon::after,
.dark-mode .request__quote-page-right,
.dark-mode .testimonial__page-item,
.dark-mode .services__details-left-content-box,
.dark-mode .services__four-item:before,
.dark-mode .request__quote-page-right-item,
.dark-mode .request__quote-page-services label {
    background: #1c1c1c;
}
.dark-mode .team__two-item-content {
    background: #0e0e0e;
    box-shadow: none;
}
.dark-mode .btn-one::after,
.dark-mode .btn-one::before {
    background: #202123;
}
.dark-mode .btn-one:hover {
    border-color: #202123;
}
.dark-mode .banner__two-bg {
    background: #161819;
}
.dark-mode .testimonial__one.page {
    background: #121212;
}
.dark-mode .services__one-item,
.dark-mode .portfolio__four-item-inner-content-btn,
.dark-mode .faq__area-item,
.dark-mode .faq__one-left-image-question,
.dark-mode .services__two-item::after {
    background: #202123;
}
.dark-mode .pricing__area-item-top::before {
    background: #ededed;
}
.dark-mode .work__area-item-icon::after {
    border-color: #ededed;
}
.dark-mode .work__area-item::after {
}
.dark-mode .footer__four {
    background: #0b0b0b;
}
.dark-mode .services__one,
.dark-mode .footer__two {
    background: #181818;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dark-mode .quote__area {
    background: #0d0d0d;
}
.dark-mode .footer__three-widget-company form input {
    border-color: var(--color-10);
}
.dark-mode .footer__three-widget-company form input:focus {
    border-color: var(--primary-color-3);
}
.dark-mode .header__area.one {
    background: #181818;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}
.dark-mode .cta__two-item,
.dark-mode .faq__one-left-image-question,
.dark-mode .features__two-left-bottom-item,
.dark-mode .services__three-items {
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.4);
}
.dark-mode .about__two-right-bottom-founder,
.dark-mode .features-area-item {
    box-shadow: 0px 10px 60px rgba(47, 47, 47, 0.4);
}
.dark-mode .blog__three-item:hover .blog__three-item-btn::before {
    background: rgba(255, 255, 255, 0.15);
    opacity: 1;
}
.dark-mode .light-n {
    display: inline-block;
}
.dark-mode .dark-n {
    display: none;
}
.dark-mode .solution__area::after,
.dark-mode .video__area::after,
.dark-mode .banner__four-image::before,
.dark-mode .cta__three::before,
.dark-mode .banner__two-image::after,
.dark-mode .all__sidebar-item-help::after,
.dark-mode .portfolio__four-item-image::after,
.dark-mode .request__quote::after,
.dark-mode .banner__three-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: var(--text-white);
    mix-blend-mode: color;
}
.dark-mode .banner__four-image::before {
    z-index: 0;
}
.dark-mode .about__three-shape {
    opacity: 0.02;
}
.dark-mode .offer__area-shape,
.dark-mode .about__two-shape-one,
.dark-mode .testimonial__one-shape {
    filter: invert(0.6);
}
.dark-mode .team__one-item-image-info-icon::after {
    background-image: url("../src/assests/img/icon/union-dark.png");
}
.dark-mode .banner__four::after {
}
.dark-mode .text__slider ul li h2 a {
    color: #fff;
}
.dark-mode .text__slider.two ul li h2 a {
    color: #fff;
}
.dark-mode .features-area-item::before {
    opacity: 0.1;
    transition: 0.5s;
}
.dark-mode .features-area-item.features-area-item-hover::before {
    opacity: 1;
}
.dark-mode .footer__one-widget-about-social,
.dark-mode .footer__one-widget-about-social ul li a i,
.dark-mode .footer__one-widget-location-item-icon i,
.dark-mode .footer__one-widget-location-item,
.dark-mode .subscribe__area-form form input {
    border-color: #333334;
}
.dark-mode .solution__area::before {
    opacity: 0.77;
}
.dark-mode .testimonial__one-pagination-item img,
.dark-mode .portfolio__one-item img,
.dark-mode .portfolio__two-item-image img,
.dark-mode .services__one-item-image > img,
.dark-mode .blog__one-item-image img,
.dark-mode img.mfp-img,
.dark-mode .team__six-item-image img,
.dark-mode .team__one-item-image img,
.dark-mode .blog__standard-left-item-image img,
.dark-mode .request__quote-page-right-item-bottom img,
.dark-mode .team__five-item-image img,
.dark-mode .about__five-left-image img,
.dark-mode .footer__one-widget-gallery-area-item img,
.dark-mode .blog__three-item-author img,
.dark-mode .about__five-right-author img,
.dark-mode .dark__image > img,
.dark-mode .banner__two-right img,
.dark-mode .faq__one-left-image img,
.dark-mode .team__three-item-image img,
.dark-mode .team__four-item-image img,
.dark-mode .project__one-item img,
.dark-mode .blog__four-item-image img,
.dark-mode .blog__two-item-content-author-post-image img,
.dark-mode .blog__two-item-image img,
.dark-mode .testimonial__page-item-bottom img,
.dark-mode .post__item-image img,
.dark-mode .blog__details-left-comment-item-comment-image img,
.dark-mode .portfolio__three-item img,
.dark-mode .team__two-item-image > img {
    filter: grayscale(1);
}
.dark-mode .who__area-shape .about__five-shape {
    filter: invert(1);
}
.dark-mode .about__three-left-image-experience p,
.dark-mode input[type="text"],
.dark-mode input[type="email"],
.dark-mode input[type="url"],
.dark-mode input[type="password"],
.dark-mode input[type="search"],
.dark-mode input[type="number"],
.dark-mode input[type="tel"],
.dark-mode input[type="range"],
.dark-mode input[type="date"],
.dark-mode input[type="month"],
.dark-mode input[type="week"],
.dark-mode input[type="time"],
.dark-mode input[type="datetime"],
.dark-mode input[type="datetime-local"],
.dark-mode input[type="color"],
.dark-mode textarea {
    color: var(--text-white);
}
.dark-mode .switch__tab-area {
    border-color: var(--border-color-1);
    box-shadow: 0px 10px 60px rgba(47, 47, 47, 0.4);
}
.dark-mode .switch__tab-area-item-button button {
    opacity: 0.55;
}
.dark-mode .switch__tab-area-item-button button:last-child {
    opacity: 1;
}

/*==========================================================================
* RTL CSS
==========================================================================*/
.rtl-mode {
    direction: rtl;
}
.rtl-mode .top__bar-left ul li,
.rtl-mode .top__bar-three-left ul li,
.rtl-mode .top__bar-four-left ul li,
.rtl-mode .top__bar-two-left ul li {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    margin-left: 25px;
    padding-left: 25px;
    border-left: 1px solid var(--body-color);
}
.rtl-mode .top__bar-left ul li a i,
.rtl-mode .top__bar-three-left ul li a i,
.rtl-mode .top__bar-four-left ul li a i,
.rtl-mode .top__bar-two-left ul li a i {
    margin-right: 0;
    margin-left: 10px;
}
.rtl-mode .top__bar-right-social ul li a,
.rtl-mode .top__bar-three-right-social ul li a,
.rtl-mode .top__bar-four-right-social ul li a,
.rtl-mode .top__bar-two-right-social ul li a {
    padding-right: 0;
    margin-right: 0;
    padding-left: 10px;
    margin-left: 10px;
}
.rtl-mode .top__bar-right-social ul li a span::after,
.rtl-mode .top__bar-three-right-social ul li a span::after,
.rtl-mode .top__bar-four-right-social ul li a span::after,
.rtl-mode .top__bar-two-right-social ul li a span::after {
    right: inherit;
    left: 0;
}
.rtl-mode .top__bar-right-social ul li a:hover span,
.rtl-mode .top__bar-three-right-social ul li a:hover span,
.rtl-mode .top__bar-four-right-social ul li a:hover span,
.rtl-mode .top__bar-two-right-social ul li a:hover span {
    padding-left: 0;
    padding-right: 10px;
}
.rtl-mode .top__bar-right-social ul li:last-child a,
.rtl-mode .top__bar-three-right-social ul li:last-child a,
.rtl-mode .top__bar-four-right-social ul li:last-child a,
.rtl-mode .top__bar-two-right-social ul li:last-child a {
    padding: 0;
    margin: 0;
}
.rtl-mode .top__bar-right-social ul li:last-child a::after,
.rtl-mode .top__bar-three-right-social ul li:last-child a::after,
.rtl-mode .top__bar-four-right-social ul li:last-child a::after,
.rtl-mode .top__bar-two-right-social ul li:last-child a::after {
    display: none;
}
.rtl-mode .top__bar-three {
    padding-right: 12px;
}
.rtl-mode .top__bar-three-right {
    margin-right: 0;
    margin-left: -24px;
}
.rtl-mode .top__bar-two-left ul li {
    border-color: var(--color-20);
}
.rtl-mode .banner__three-image {
    background-position: left;
}
.rtl-mode .counter__area-item {
    text-align: right;
}
.rtl-mode .banner__one-arrow-next,
.rtl-mode .banner__two-arrow-next {
    margin: 30px 0 0 35px;
}
.rtl-mode .page__banner-image,
.rtl-mode .banner__four,
.rtl-mode .skill__area-item-inner,
.rtl-mode .all__sidebar-item-help-shape,
.rtl-mode .banner__four-content,
.rtl-mode .work__area-item::after,
.rtl-mode .banner__two-content,
.rtl-mode .banner__two-bg,
.rtl-mode .banner__one-image {
    transform: scaleX(-1);
}
.rtl-mode .banner__four-content-button ul li {
    margin-right: -35px;
    margin-left: 0;
}
.rtl-mode .banner__four-content-button ul li:first-child {
    margin: 0;
}
.rtl-mode .page__banner-content ul li a,
.rtl-mode .page__banner-content ul li span {
    margin-right: 0;
    margin-left: 15px;
}
.rtl-mode .all__sidebar-item-download ul li a i {
    margin-left: 18px;
    padding-left: 10px;
    border-left: 1px solid var(--border-color-1);
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
}
.rtl-mode .blog__details-left-comment-item-comment-content h6 a i,
.rtl-mode .blog__details-left-meta ul li a i,
.rtl-mode .blog__standard-left-item-content-meta ul li,
.rtl-mode .blog__standard-left-item-content-meta ul li a i,
.rtl-mode .blog__details-left-meta ul li,
.rtl-mode .blog__three-item-meta ul li a i,
.rtl-mode .about__one-right-bottom-list p i,
.rtl-mode .blog__one-item-content-meta ul li a i,
.rtl-mode .team__three-item-content ul li,
.rtl-mode .blog__details-left-share ul li,
.rtl-mode .header__area-menubar-right-sidebar-popup-social ul li {
    margin-right: 0;
    margin-left: 10px;
}
.rtl-mode .all__sidebar-item-solution ul li a {
    padding: 16px 20px;
    padding-right: 0;
}
.rtl-mode .all__sidebar-item-solution ul li a::before {
    content: "\f323";
    left: initial;
    right: 10px;
}
.rtl-mode .all__sidebar-item-solution ul li a:hover {
    padding-right: 40px;
}
.rtl-mode .all__sidebar-item-solution ul li a:hover::before {
    right: 20px;
    left: 0;
}
.rtl-mode .header__area-menubar-left-logo.three {
    padding-left: 0;
    padding-right: 25px;
}
.rtl-mode .header__area-menubar-center-menu ul li:first-child {
    margin-left: 25px;
    margin-right: 0;
}
.rtl-mode .header__area-menubar-center-menu ul li:last-child {
    margin-right: 25px;
    margin-left: 0;
}
.rtl-mode .header__area-menubar-center-menu ul li .sub-menu {
    right: 0;
}
.rtl-mode .header__area-menubar-center-menu ul li .sub-menu li {
    margin: 0;
}
.rtl-mode .header__area-menubar-center-menu ul li .sub-menu li .sub-menu {
    right: 100%;
}
.rtl-mode
    .header__area-menubar-center-menu
    ul
    li
    .sub-menu
    .menu-item-has-children
    > a::before {
    content: "\f104";
    right: inherit;
    left: 0;
}
.rtl-mode .header__area-menubar-right-sidebar {
    padding-left: 0;
    border-left: 0;
    padding-right: 40px;
    border-right: 1px solid var(--border-color-1);
}
.rtl-mode .header__area-menubar-right.three {
    padding-right: 0;
    padding-left: 25px;
}
.rtl-mode .header__area-menubar-right-contact.three {
    padding: 0;
    padding-right: 30px;
    border-left: 0;
    border-right: 1px solid var(--border-color-1);
}
.rtl-mode .menu__bar-popup-right {
    border-right: 1px solid var(--color-10);
}
.rtl-mode .menu__bar-popup-left {
    padding: 30px 30px 0 30px;
}
.rtl-mode .faq__one-left,
.rtl-mode .about__two-left,
.rtl-mode .about__three-left {
    margin-right: 0;
    margin-left: 70px;
}
.rtl-mode .about__five-left-shape {
    right: -70px;
    left: initial;
}
.rtl-mode .testimonial__two-item-inner-avatar,
.rtl-mode .menu__bar-popup-right-search form button,
.rtl-mode .testimonial__two-item-inner-reviews,
.rtl-mode .skill__area-item-count,
.rtl-mode .about__two-left-image-two,
.rtl-mode .benefits__two-left-image-two,
.rtl-mode .about__four-left-image-two,
.rtl-mode .footer__two-widget-subscribe form button,
.rtl-mode .footer__four-widget-subscribe form button,
.rtl-mode .header__area-menubar-right-search-box button,
.rtl-mode .footer__three-widget-company form button,
.rtl-mode .faq__area-item .icon::after,
.rtl-mode .offer__area-left .image-overlay.two,
.rtl-mode .all__sidebar-item-search form button,
.rtl-mode .faq__one-left-image-one,
.rtl-mode .about__three-left-image-experience {
    right: inherit;
    left: 0;
}
.rtl-mode .about__four-right-bottom {
    margin-right: 0;
    margin-left: 50px;
}
.rtl-mode .blog__details-left-comment-item-comment-content h6 a,
.rtl-mode .all__sidebar-item-solution ul li a span {
    float: left;
}
.rtl-mode .blog__details-left-comment-item-comment-image {
    float: right;
}
.rtl-mode .blog__details-left-comment-item-comment-content {
    padding-left: 0;
    padding-right: 30px;
    overflow: hidden;
}
.rtl-mode .benefits__two-shape,
.rtl-mode .services__one-item-content-icon {
    right: inherit;
    left: 25px;
}
.rtl-mode .services__three-items .two .services__three-item-icon::after {
    left: inherit;
    right: -10px;
}
.rtl-mode .work__area .row.work-n .col-xl-3:last-child .work__area-item::after {
    display: block;
}
.rtl-mode
    .work__area
    .row.work-n
    .col-xl-3:first-child
    .work__area-item::after {
    display: none;
}
.rtl-mode .benefits__area-left-image {
    padding-left: 0;
    padding-right: 150px;
}
.rtl-mode .benefits__area-left-image-from {
    left: inherit;
    right: -48px;
}
.rtl-mode .features__two-right {
    right: inherit;
    text-align: left;
    left: 0;
}
.rtl-mode .features__two-left-title p {
    margin: 0;
    margin-left: 33px;
}
.rtl-mode .features__two-left-bottom {
    margin-right: 0;
    margin-left: 108px;
}
.rtl-mode .request__quote-page-item-label,
.rtl-mode .banner__three-shape,
.rtl-mode .contact__three-info-icon,
.rtl-mode .benefits__two-left-image-shape,
.rtl-mode .services__four-item-left p::after,
.rtl-mode .about__four-left-image-shape,
.rtl-mode .pricing__area-item-content-item::before,
.rtl-mode .footer__area-widget-menu ul li a::before,
.rtl-mode .features__two-left-bottom-item-icon::after {
    left: initial;
    right: 0;
}
.rtl-mode .team__two-item-image-icon {
    right: inherit;
    left: 30px;
}
.rtl-mode .portfolio__four-item-inner-content-top span,
.rtl-mode .menu__bar-popup-close,
.rtl-mode .portfolio__three-item-content-icon {
    right: initial;
    left: 30px;
}
.rtl-mode .offer__area-right,
.rtl-mode .about__one-right {
    margin-right: 90px;
    margin-left: 0;
}
.rtl-mode .benefits__two-right-bottom,
.rtl-mode .testimonial__one-title {
    margin-right: 0;
    margin-left: 90px;
}
.rtl-mode .services__four-item-left p {
    padding-right: 70px;
    padding-left: 0;
}
.rtl-mode .about__five-right,
.rtl-mode .benefits__two-right,
.rtl-mode .about__four-right,
.rtl-mode .testimonial__two-left {
    margin-left: 0;
    margin-right: 65px;
}
.rtl-mode .about__five-right .testimonial-pagination,
.rtl-mode .benefits__two-right .testimonial-pagination,
.rtl-mode .about__four-right .testimonial-pagination,
.rtl-mode .testimonial__two-left .testimonial-pagination {
    right: inherit;
    left: -40px;
}
.rtl-mode .team__single-left {
    margin-right: 0;
    margin-left: 30px;
}
.rtl-mode .offer__area-left div:first-child {
    padding-left: 30px;
    border-right: 0;
    padding-right: 0;
    border-left: 24px solid var(--primary-color-2);
}
.rtl-mode .request__quote-page-right .swiper-pagination,
.rtl-mode .about__five-left-image-experience {
    left: initial;
    right: 40px;
}
.rtl-mode .team__single-right-form-select::before,
.rtl-mode .about__four-left-image-shape-one,
.rtl-mode .contact-item span {
    right: initial;
    left: 16px;
}
.rtl-mode .portfolio__one,
.rtl-mode .banner__two,
.rtl-mode .banner__three,
.rtl-mode .banner__one,
.rtl-mode .testimonial__one-right,
.rtl-mode .request__quote-page-right {
    direction: initial;
}
.rtl-mode .request__quote-page-right .swiper-pagination {
    text-align: right;
}
.rtl-mode .text__slider ul li h2,
.rtl-mode .testimonial__one-item,
.rtl-mode .banner__three-content,
.rtl-mode .banner__one-content,
.rtl-mode .banner__one-arrow,
.rtl-mode .banner__two-arrow,
.rtl-mode .portfolio__one-item-content,
.rtl-mode .banner__two-content,
.rtl-mode .project__one-item-content,
.rtl-mode .request__quote-page-right-item h5,
.rtl-mode .request__quote-page-right-item-bottom,
.rtl-mode .request__quote-page-right-item-icon {
    direction: rtl;
}
.rtl-mode .all__sidebar-item-help::before {
    right: initial;
    left: -60px;
}
.rtl-mode .faq__one-left-image-question {
    right: inherit;
    left: 70px;
}
.rtl-mode .pricing__area-item-content-item {
    padding-left: 0;
    padding-right: 35px;
}
.rtl-mode .subscribe__area-form form .btn-two {
    left: 7px;
    right: initial;
}
.rtl-mode .blog__one-item-content-meta ul li a,
.rtl-mode .blog__three-item-meta ul li a {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
    border-left: 1px solid var(--border-color-1);
    margin-left: 20px;
    padding-left: 20px;
}
.rtl-mode .blog__two-item-content-date {
    right: inherit;
    left: 35px;
}
.rtl-mode .blog__one-item-content-meta ul li:last-child a,
.rtl-mode .top__bar-left ul li:last-child,
.rtl-mode .top__bar-three-left ul li:last-child,
.rtl-mode .top__bar-four-left ul li:last-child,
.rtl-mode .top__bar-two-left ul li:last-child,
.rtl-mode .blog__three-item-meta ul li:last-child a {
    margin: 0;
    padding: 0;
    border: 0;
}
.rtl-mode .footer__three-widget-company {
    margin-right: 0;
    margin-left: 90px;
}
.rtl-mode .footer__area-widget-menu ul li a::before {
    content: "\f323";
}
.rtl-mode .footer__area-widget-menu ul li a:hover,
.rtl-mode .footer__area-widget-menu.two ul li a {
    padding-left: 0;
    padding-right: 20px;
}
.rtl-mode .footer__area-widget-menu.two ul li a:hover {
    margin-left: 0;
    margin-right: 7px;
}
.rtl-mode .footer__area-widget-menu.two ul li a::before {
    content: "\f111";
}
.rtl-mode .switch__tab-area-item-buttons button {
    opacity: 0.55;
}
.rtl-mode .switch__tab-area-item-buttons button:last-child {
    opacity: 1;
}
.rtl-mode .t-right {
    text-align: left;
}
.rtl-mode .t-left {
    text-align: right;
}
.rtl-mode .menu__bar {
    padding-left: 0;
    padding-right: 30px;
    border-right: 1px solid var(--border-color-1);
    border-left: 0;
}
.swiper-slide::before {
    width: 40%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: rgb(0, 0, 0);
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
}

.product-list-section {
    width: 100%;
    height: auto;
    padding: 40px 0;
    display: inline-block;
}

.product-list {
    width: 100%;
    display: inline-block;
    margin-top: 30px;
}
.product-list-box {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    /* border: solid 1px #ccc; */
    box-shadow: 0px 0px 30px #ebebeb;
    margin-bottom: 15px;
}
.product-list-box-img {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-bottom: 10px;
    overflow: hidden;
}
.product-list-box:hover .product-list-box-img img {
    transform: scale(1.1);
}
.product-list-box-img img {
    width: 100%;
    transition: 0.2s ease;
}
.product-list-box-cnt {
    width: 100%;
    height: auto;
    display: inline-block;
}
.product-list-box-cnt h4 {
    width: 100%;
    height: auto;
    display: inline-block;
    font-size: 20px;
    color: #242424;
}
.product-list-box-cnt p {
    width: 100%;
    height: auto;
    display: inline-block;
    font-size: 19px;
    color: #242424;
}
.product-detail-sec {
    width: 100%;
    height: auto;
    display: inline-block;
    padding: 40px 0;
}
.product-detail-banner {
    width: 100%;
    height: auto;
    display: inline-block;
    padding: 50px 0;
    background-color: #f4e7f7;
}
.product-detail-banner h3 {
    width: 100%;
    height: auto;
    display: inline-block;
    font-size: 32px;
    font-weight: 100;
    color: #151515;
}

.pd-wrap {
    padding: 40px 0;
    font-family: "Poppins", sans-serif;
}
.heading-section {
    text-align: center;
    margin-bottom: 20px;
}
.sub-heading {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    display: block;
    font-weight: 600;
    color: #2e9ca1;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.heading-section h2 {
    font-size: 32px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 15px;
    font-family: "Poppins", sans-serif;
}
.user-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    min-width: 80px;
    background-size: 100%;
}
.carousel-testimonial .item {
    padding: 30px 10px;
}
.quote {
    position: absolute;
    top: -23px;
    color: #2e9da1;
    font-size: 27px;
}
.name {
    margin-bottom: 0;
    line-height: 14px;
    font-size: 17px;
    font-weight: 500;
}
.position {
    color: #adadad;
    font-size: 14px;
}
.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
    height: 25px;
}
.owl-nav button svg {
    width: 25px;
    height: 25px;
}
.owl-nav button.owl-prev {
    left: 25px;
}
.owl-nav button.owl-next {
    right: 25px;
}
.owl-nav button span {
    font-size: 45px;
}
.product-thumb .item img {
    height: 100px;
}
.product-name {
    font-size: 32px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 14px;
    color: #000;
}
.product-price-discount {
    font-size: 22px;
    font-weight: 400;
    padding: 10px 0;
    clear: both;
}
.product-price-discount span.line-through {
    text-decoration: line-through;
    margin-left: 10px;
    font-size: 14px;
    vertical-align: middle;
    color: #a5a5a5;
}
.display-flex {
    display: flex;
}
.align-center {
    align-items: center;
}
.product-info {
    width: 100%;
}
.reviews-counter {
    font-size: 13px;
}
.reviews-counter span {
    vertical-align: -2px;
}
.rate {
    float: left;
    padding: 0 10px 0 0;
}
.rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
}
.rate:not(:checked) > label {
    float: right;
    width: 35px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 41px;
    color: #ccc;
    margin-bottom: 0;
    line-height: 51px;
}
.rate:not(:checked) > label:before {
    content: "\2605";
}
.rate > input:checked ~ label {
    color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.product-dtl p {
    font-size: 16px;
    line-height: 32px;
    color: #383838;
}
.product-dtl .form-control {
    font-size: 15px;
}
.product-dtl label {
    line-height: 16px;
    font-size: 15px;
}
.form-control:focus {
    outline: none;
    box-shadow: none;
}
.product-count {
    margin-top: 15px;
}
.product-count .qtyminus,
.product-count .qtyplus {
    width: 34px;
    height: 34px;
    background: var(--primary-color-4);
    text-align: center;
    font-size: 19px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
}
.product-count .qtyminus {
    border-radius: 3px 0 0 3px;
}
.product-count .qtyplus {
    border-radius: 0 3px 3px 0;
}
.product-count .qty {
    width: 60px;
    height: 34px;
    text-align: center;
    border-radius: 0;
}
.round-black-btn {
    border-radius: 4px;
    background: var(--primary-color-4);
    color: #fff;
    padding: 7px 45px;
    display: inline-block;
    margin-top: 20px;
    border: solid 2px var(--primary-color-4);
    transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
    background: transparent;
    color: #212529;
    text-decoration: none;
}

.product-info-tabs {
    margin-top: 25px;
}
.product-info-tabs .nav-tabs {
    border-bottom: 2px solid #d8d8d8;
}
.product-info-tabs .nav-tabs .nav-item {
    margin-bottom: 0;
}
.product-info-tabs .nav-tabs .nav-link {
    border: none;
    border-bottom: 2px solid transparent;
    color: #323232;
}
.product-info-tabs .nav-tabs .nav-item .nav-link:hover {
    border: none;
}
.product-info-tabs .nav-tabs .nav-item.show .nav-link,
.product-info-tabs .nav-tabs .nav-link.active,
.product-info-tabs .nav-tabs .nav-link.active:hover {
    border: none;
    border-bottom: 2px solid #d8d8d8;
    font-weight: bold;
}
.product-info-tabs .tab-content .tab-pane {
    padding: 30px 20px;
    font-size: 15px;
    line-height: 24px;
    color: #7a7a7a;
}
.review-form .form-group {
    clear: both;
}
.mb-20 {
    margin-bottom: 20px;
}

.review-form .rate {
    float: none;
    display: inline-block;
}
.review-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 6px;
    text-transform: uppercase;
    color: #000;
}
.review-form .form-control {
    font-size: 14px;
}
.review-form input.form-control {
    height: 40px;
}
.review-form textarea.form-control {
    resize: none;
}
.review-form .round-black-btn {
    text-transform: uppercase;
    cursor: pointer;
}
.video_view_box_sec {
    width: 100%;
    display: inline-block;
    padding: 30px 0;
}
.video_view_box {
    width: 100%;
    display: inline-block;
}
.contact__two-info .contact__two-info-item {
    margin-bottom: 0;
}
.contact__two-info .mb-60 {
    margin-bottom: 20px;
}
#parent .MuiInputBase-input {
    height: 38px !important;
    padding: 0px !important;
}

/* **********replica- register************** */

@media (max-width: 1550px) {
    .rtl-mode
        .header__area-menubar-center-menu
        ul
        li:nth-child(n + 4)
        .sub-menu
        li
        .sub-menu {
        left: 100%;
        right: initial;
    }
}
@media (max-width: 1399px) {
    .rtl-mode .faq__one-left {
        margin-left: 0;
    }
    .banner__one-content h1 {
        font-size: 50px;
        line-height: 55px;
    }
    .banner__one-content {
        padding: 120px 0 200px 0;
    }
}
@media (max-width: 1299px) {
    .rtl-mode .about__two-left {
        margin-left: 0;
    }
    .rtl-mode .about__two-left-image {
        padding-left: 70px;
        padding-right: 0;
    }
}
@media (max-width: 1199px) {
    .rtl-mode .about__three-left {
        margin-left: 0;
        padding-left: 60px;
        padding-right: 0;
    }
    .rtl-mode .services__four-item-left p {
        padding-right: 45px;
    }
    .rtl-mode .about__four-right-bottom,
    .rtl-mode .testimonial__one-title,
    .rtl-mode .footer__three-widget-company {
        margin-left: 0;
    }
    .rtl-mode .features__two-left-bottom {
        margin-left: 60px;
    }
    .rtl-mode .about__four-right,
    .rtl-mode .offer__area-right,
    .rtl-mode .about__five-right,
    .rtl-mode .benefits__two-right,
    .rtl-mode .about__one-right,
    .rtl-mode .testimonial__two-left {
        margin-right: 0;
    }
}
@media (max-width: 991px) {
    #mobilemenu {
        display: none;
    }
    .rtl-mode .team__single-left,
    .rtl-mode .top__bar-three-right,
    .rtl-mode .benefits__two-right-bottom,
    .rtl-mode .features__two-left-bottom {
        margin-left: 0;
    }
    .rtl-mode .about__two-left-image {
        padding-left: 0;
    }
    .rtl-mode .services__four-item-left p,
    .rtl-mode .top__bar-three {
        padding: 0;
    }
    #mobilemenu {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        top: 100px;
        left: -300px;
        opacity: 0;
        transition: 0.2s ease-in-out;
    }
    .header__area-menubar-center-menu ul li {
        width: 84%;
        padding: 20px 0;
        border-top: 1px #353535 solid;
        position: relative;
    }
    .header__area-menubar-center-menu ul li:first-child {
        margin-left: 20px;
        border: 0;
    }
    .header__area-menubar-center-menu ul li a {
        font-size: 20px;
        color: #fff;
    }
    .header__area-menubar-center-menu ul li::after {
        bottom: 0;
    }
    .menu_open {
        display: block !important;
        opacity: 1 !important;
        visibility: visible;
        left: 0 !important;
    }
}
@media (max-width: 667px) {
    .rtl-mode .menu__bar-popup-close,
    .rtl-mode .portfolio__three-item-content-icon {
        left: 20px;
    }
    .rtl-mode .menu__bar-popup-left {
        padding: 30px 20px 0 20px;
    }
    .about__two-shape-two {
        width: 100%;
    }
    .image-overlay img {
        width: 100%;
    }
    .main-content-regsiter {
        margin: 0 !important;
    }
}
@media (max-width: 614px) {
    .rtl-mode .top__bar-left ul li,
    .rtl-mode .top__bar-three-left ul li,
    .rtl-mode .top__bar-two-left ul li,
    .rtl-mode .top__bar-four-left ul li {
        margin-left: 0;
        border: 0;
        padding-left: 0;
    }
}
@media (max-width: 575px) {
    .rtl-mode .portfolio__three-item-content-icon {
        left: 22px;
    }
    .rtl-mode .benefits__area-left-image {
        padding-right: 30px;
    }
}
@media (max-width: 460px) {
    .rtl-mode .blog__three-item-meta ul li a {
        margin-left: 10px;
        padding-left: 10px;
    }
    .rtl-mode .menu__barparent {
        padding-right: 0;
        #mobilemenu {
            position: fixed;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            top: 100px;
            left: -300px;
            opacity: 0;
            transition: 0.2s ease-in-out;
        }
        .header__area-menubar-center-menu ul li {
            width: 84%;
            padding: 20px 0;
            border-top: 1px #353535 solid;
            position: relative;
        }
        .header__area-menubar-center-menu ul li:first-child {
            margin-left: 20px;
            border: 0;
        }
        .header__area-menubar-center-menu ul li a {
            font-size: 20px;
            color: #fff;
        }
        .header__area-menubar-center-menu ul li::after {
            bottom: 0;
        }
        .menu_open {
            display: block !important;
            opacity: 1 !important;
            visibility: visible;
            left: 0 !important;
        }
    }
    .rtl-mode .header__area-menubar-left-logo.three {
        padding: 25px 12px 15px 0;
    }
}
@media (max-width: 359px) {
    .rtl-mode .blog__three-item-meta ul li a {
        margin-left: 5px;
        padding-left: 8px;
    }
}
